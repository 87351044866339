import { FunctionalComponent, h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import CloudIcon from '../cloudIcon';
import Delete from '../delete';
import Download from '../download';
import { formatTime, buildClasses } from '../../../../utils';
import { audioStorage } from '../../../../utils/firebase';
import style from './style.scss';

interface Props {
    file: {
        thumbnailUrl: string;
        duration: number;
        blobName: string;
        displayName: string;
        size: number;
    };
    onDeleteConfirm: (
        blobName: string,
        displayName: string,
        fileType: FileType,
        onDeleteSuccess: () => void,
    ) => void;
    onDownloadStarted: () => void;
    onDownloadCompleted: () => void;
    onDownloadError: () => void;
    downloadInProgress: boolean;
}

const AudioPreview: FunctionalComponent<Props> = ({
    file: { thumbnailUrl, duration, blobName, displayName, size } = {
        thumbnailUrl: '',
        duration: 0,
        blobName: '',
        displayName: '',
        size: 0,
    },
    onDeleteConfirm,
    downloadInProgress,
    onDownloadStarted,
    onDownloadCompleted,
    onDownloadError,
}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const videoSourceRef = useRef<HTMLSourceElement>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [progress, setProgress] = useState<number>(0);
    const [canPlay, setCanPlay] = useState<boolean>(false);

    const itemClasses = buildClasses({
        [style.item]: true,
    });

    useEffect(() => {
        if (canPlay) {
            // This means the user tried to play the file but it needed to load.
            // It has now loaded enough to play through.
            // console.log('go play now');
            playAudioFile();
        }
    }, [canPlay]);

    useEffect(() => {
        if (!isPlaying) {
            if (videoRef.current) {
                // console.log('trying to pause');
                videoRef.current.pause();
            }
        }
    }, [isPlaying]);

    async function getAudioFile() {
        try {
            const url = await getDownloadURL(
                storageRef(audioStorage, blobName),
            );
            return url;
        } catch (e) {
            console.log('error getting audio file', e);
        }
    }

    async function loadAudioFile() {
        if (canPlay) {
            playAudioFile();
        } else {
            if (videoSourceRef.current) {
                videoSourceRef.current.src = (await getAudioFile()) || '';
                if (videoRef.current) {
                    videoRef.current.load();
                }
            }
        }
    }

    async function playAudioFile() {
        if (!videoSourceRef.current || !videoRef.current) {
            return;
        }

        if (!canPlay) {
            loadAudioFile();
        }

        await videoRef.current.play();
    }

    function updateTime() {
        if (!videoRef.current) {
            return;
        }

        if (videoRef.current.currentTime === 0) {
            setCurrentTime(0);
            setProgress(0);
            return;
        }

        setCurrentTime(Math.round(videoRef.current.currentTime));
        setProgress(
            (videoRef.current.currentTime / videoRef.current.duration) * 100,
        );

        if (videoRef.current.currentTime === videoRef.current.duration) {
            setIsPlaying(false);
            videoRef.current.load();
        }
    }

    return (
        <div class={itemClasses} ref={containerRef} preload="auto">
            <div class={style['player-container']}>
                <video
                    poster={thumbnailUrl}
                    ref={videoRef}
                    onTimeUpdate={updateTime}
                    onCanPlayThrough={() => {
                        setCanPlay(true);
                    }}
                    onPlay={() => {
                        setIsPlaying(true);
                    }}
                >
                    <source type="audio/mp3" ref={videoSourceRef} />
                </video>
            </div>
            <div class={style['audio-controls']}>
                <div class={style['control-buttons']}>
                    {!isPlaying && (
                        <button
                            class={style['control-button']}
                            type="button"
                            onClick={loadAudioFile}
                        >
                            <svg
                                width="17"
                                height="20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.572 8.69 2.97.302C1.895-.414.44.365.44 1.651v16.754c0 1.306 1.454 2.065 2.529 1.348l12.602-8.387c.948-.611.948-2.044 0-2.676Z"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                />
                            </svg>
                            <span class={style['button-text']}>Play</span>
                        </button>
                    )}
                    {isPlaying && (
                        <button
                            class={style['control-button']}
                            type="button"
                            onClick={() => {
                                setIsPlaying(false);
                            }}
                        >
                            <svg
                                width="18"
                                height="20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                    d="M6.15 0H1.46a.821.821 0 0 0-.82.82v18.36c0 .442.357.82.82.82H6.13c.442 0 .82-.357.82-.82V.82A.804.804 0 0 0 6.15 0ZM16.897 0h-4.69a.821.821 0 0 0-.82.82v18.36c0 .442.357.82.82.82h4.669c.441 0 .82-.357.82-.82V.82a.804.804 0 0 0-.8-.82Z"
                                />
                            </svg>
                            <span class={style['button-text']}>Pause</span>
                        </button>
                    )}
                </div>
                <div class={style.track}>
                    <span class={style['full-length']}></span>
                    <span
                        class={style.played}
                        style={{ width: `${progress}%` }}
                    ></span>
                    <span
                        class={style.playhead}
                        style={{ left: `calc(${progress}% - 3px)` }}
                    ></span>
                </div>
                <div class={style.timer}>
                    {formatTime(currentTime)}/{formatTime(duration)}
                </div>
            </div>
            <div class={style['audio-actions']}>
                <p class={style['item-title']}>{displayName}</p>
                <div class={style['audio-buttons']}>
                    <Download
                        ui="both"
                        blobName={blobName}
                        align="left"
                        displayName={displayName}
                        onDownloadStarted={onDownloadStarted}
                        onDownloadCompleted={onDownloadCompleted}
                        onDownloadError={onDownloadError}
                        size={size}
                        disabled={downloadInProgress}
                        environment="dark"
                        type="action"
                        display="standard"
                        fileType="audio"
                    />
                    <Delete
                        ui="both"
                        blobName={blobName}
                        align="left"
                        action={onDeleteConfirm!}
                        displayName={displayName}
                        onDeleteSuccess={() => {}}
                        fileType="audio"
                        environment="dark"
                        display="standard"
                        type="warning"
                    />
                </div>
            </div>
            <CloudIcon className={style['uploaded-icon']} />
        </div>
    );
};

export default AudioPreview;
