// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

// constants, enums, types
import { RECORDING_STATE } from '../../enums';

// utils
import { buildClasses, formatTime } from '../../../../utils';

// components
import { RecordAppContext } from '../../index';

// assets

//styles
import style from './style.scss';

interface Props {}

const RecordButton: FunctionalComponent<Props> = ({}: Props) => {
	const {
		isCleanState,
		recordingState,
		recordingTimeInSeconds,
		handleRecordingButtonClick,
	} = useContext(RecordAppContext);

	const buttonClasses = buildClasses({
		[style['record-button']]: true,
		[style['not-started']]: recordingState === RECORDING_STATE.NOT_STARTED,
		[style.recording]: recordingState === RECORDING_STATE.RECORDING,
		[style.paused]: recordingState === RECORDING_STATE.PAUSED,
	});

	const normalButtonText = isCleanState
		? 'Record Intro'
		: recordingState === RECORDING_STATE.NOT_STARTED
		? 'Start Recording'
		: recordingState === RECORDING_STATE.RECORDING
		? `${formatTime(recordingTimeInSeconds)} Recording`
		: recordingState === RECORDING_STATE.PAUSED
		? 'Resume Recording'
		: 'Done';
	const hoverButtonText = isCleanState
		? 'Record Intro'
		: recordingState === RECORDING_STATE.NOT_STARTED
		? 'Start Recording'
		: recordingState === RECORDING_STATE.RECORDING
		? 'Stop Recording'
		: recordingState === RECORDING_STATE.PAUSED
		? 'Resume Recording'
		: 'Done';

	if (recordingState === RECORDING_STATE.DONE) {
		return null;
	}

	return (
		<button
			class={buttonClasses}
			type="button"
			onClick={handleRecordingButtonClick}
		>
			<div class={style['record-icon']}>
				<span class={style['record-icon-center']}></span>
			</div>
			<span class={`${style['button-text']} ${style['normal-text']}`}>
				{normalButtonText}
			</span>
			<span class={`${style['button-text']} ${style['hover-text']}`}>
				{hoverButtonText}
			</span>
		</button>
	);
};

export default RecordButton;
