import { FunctionalComponent, h, Fragment } from 'preact';
import { useRef } from 'preact/hooks';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import { formatBytes } from '../../../../utils';
import {
    audioStorage,
    cloudVideoStorage,
    imageStorage,
} from '../../../../utils/firebase';
import ActionButton, {
    ButtonUI,
    ButtonDisplay,
    ButtonAlign,
    ButtonEnvironment,
    ButtonType,
    ButtonSize,
} from '../actionButton';
import style from './style.scss';

interface Props {
    ui: ButtonUI;
    display: ButtonDisplay;
    blobName: string;
    displayName: string;
    align?: ButtonAlign;
    size?: number;
    onDownloadStarted: () => void;
    onDownloadCompleted: () => void;
    onDownloadError: () => void;
    disabled: boolean;
    environment?: ButtonEnvironment;
    type?: ButtonType;
    fileType: FileType;
    buttonSize?: ButtonSize;
}

const Download: FunctionalComponent<Props> = ({
    display,
    blobName,
    displayName,
    onDownloadStarted,
    onDownloadCompleted,
    onDownloadError,
    size,
    align = 'center',
    ui,
    disabled,
    environment = 'light',
    type = 'normal',
    fileType,
    buttonSize = 'small',
}: Props) => {
    const downloadEl = useRef<HTMLAnchorElement>(null);
    async function getUrl(event: Event) {
        let fileStorageRef;

        switch (fileType) {
            case 'audio':
                fileStorageRef = storageRef(audioStorage, blobName);
                break;
            case 'video':
                fileStorageRef = storageRef(cloudVideoStorage, blobName);
                break;
            case 'image':
                fileStorageRef = storageRef(imageStorage, blobName);
                break;
        }

        event.stopPropagation();
        onDownloadStarted && onDownloadStarted();
        try {
            if (!fileStorageRef) {
                throw Error();
            }
            const url = await getDownloadURL(fileStorageRef);
            try {
                const res = await fetch(url, {
                    mode: 'cors',
                });
                const blob = await res.blob();
                downloadBlob(blob);
            } catch (e) {
                console.log(e);
                throw new Error('Error downloading');
            }
        } catch (e) {
            // onDownloadCompleted && onDownloadCompleted();
            // Show error toast
            onDownloadError && onDownloadError();
            console.log({ e });
        }
    }

    async function downloadBlob(blob: Blob) {
        if (!downloadEl.current) {
            throw new Error();
        }
        downloadEl.current.href = URL.createObjectURL(blob);
        downloadEl.current.download = displayName;
        downloadEl.current.click();
        onDownloadCompleted && onDownloadCompleted();
    }

    return (
        <Fragment>
            <ActionButton
                ui={ui}
                display={display}
                align={align}
                text={`Download${
                    size && ` (${formatBytes({ bytes: size, decimals: 2 })})`
                }`}
                icon={
                    <svg
                        width="20"
                        height="17"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g fill="#262626" fill-rule="evenodd">
                            <path d="M7 0h2v12H7zM0 15h16v2H0z" />
                            <path d="M8 13.657 2.343 8l1.414-1.414L8 10.828l4.243-4.242L13.657 8 8 13.657Z" />
                        </g>
                    </svg>
                }
                iconHasFill={true}
                action={getUrl}
                disabled={disabled}
                environment={environment}
                type={type}
                size={buttonSize}
            />
            <a
                ref={downloadEl}
                class={style['download-link']}
                onClick={event => {
                    event.stopPropagation();
                }}
                download
            >
                Download File
            </a>
        </Fragment>
    );
};

export default Download;
