import {
    ref as storageRef,
    uploadBytesResumable,
    UploadTaskSnapshot,
    UploadTask,
} from 'firebase/storage';
// import { v4 as uuid } from 'uuid';
import { nanoid } from 'nanoid';
import { videoStorage } from './firebase';

export interface UploadFile {
    file: File;
    isUploading: boolean;
    blobName: string;
    // bytesTransferred: number;
    uploadTask: any;
    startTime: Date;
}

interface UploadOptions {
    uploadFile: File;
}

// TODO: is this the right spot for this? feels weird to export it and use it in from a prop. Oh well
export function handleUploadProgress(snapshot: UploadTaskSnapshot) {
    return snapshot.bytesTransferred;
}

export async function handleUploadSuccess(uploadTask: UploadTask) {
    // console.log('done');
    // const url = await getDownloadURL(uploadTask.snapshot.ref);
    // console.log({ url });
}

export function uploadFile({ uploadFile }: UploadOptions): UploadFile {
    const date = new Date();
    const fileName = nanoid();
    const fileStorageRef = storageRef(videoStorage, fileName);

    const uploadTask = uploadBytesResumable(fileStorageRef, uploadFile, {
        customMetadata: {
            displayName: uploadFile.name,
            creationDate: date.toISOString(),
        },
    });
    // const uploadTask = () => {};
    const file: UploadFile = {
        file: uploadFile,
        blobName: fileName,
        startTime: new Date(),
        isUploading: true,
        uploadTask,
    };

    return file;
}
