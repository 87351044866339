// from packages
import { onValue, ref } from 'firebase/database';
import { FunctionalComponent, h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';

// constants, enums, types
import { URL_BASE } from '../../constants';
import { WEB_REACTS_EVENTS } from '../../enums';

// utils
import { formatBytes, formatTime } from '../../../../utils';
import { db } from '../../../../utils/firebase';
import { logWebReactsEvent } from '../../../../utils/logger';
import { uploadFile } from '../../../../utils/upload';

// components
import Download from '../download';
import { RecordAppContext } from '../../index';
import Button from '../../../common/button';
import ProgressBar from '../../../common/progressBar';

// assets

//styles
import style from './style.scss';

interface Props {
    recordedChunks: (Blob | null)[];
    handleRestartVideo: () => void;
}

interface Snapshot {
    [key: string]: any;
}

const EXPORT_HEADING = 'Exporting your video';
const DOWNLOAD_HEADING = 'Video ready';
// const RENDERING_HEADING = 'Rendering your video'; // TODO: Do we need two headings?
const EXPORT_SUBHEAD = 'Stay on this tab while the video finishes rendering';
const DOWNLOAD_SUBHEAD = 'Where would you like to save your video?';

const Exporter: FunctionalComponent<Props> = ({
    // handleVideoReadyForDownload,
    recordedChunks,
    handleRestartVideo,
}: Props) => {
    const { recordingTimeInSeconds, userId, videoSessionId } =
        useContext(RecordAppContext);
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [processing, setProcessing] = useState<boolean>(false);
    const [processingProgress, setProcessingProgress] = useState<number>(0);
    const [videoId, setVideoId] = useState<string>('');
    const [videoUrl, setVideoUrl] = useState<string>('');
    const filePath = videoId ? `weacts/${videoId}` : '';
    const userVideoRef = filePath ? ref(db, filePath) : null;
    const overallProgress = getOverallProgress();
    const heading = videoUrl ? DOWNLOAD_HEADING : EXPORT_HEADING;
    const subhead = videoUrl ? DOWNLOAD_SUBHEAD : EXPORT_SUBHEAD;

    useEffect(() => {
        if (recordedChunks.length > 0) {
            cloudProcessVideo();
        }
    }, [recordedChunks]);

    useEffect(() => {
        if (userVideoRef && !processing) {
            lookUpVideoStatus();
        }
    }, [userVideoRef]);

    async function lookUpVideoStatus() {
        if (!userVideoRef) {
            return;
        }
        setProcessing(true);
        const unsubscribe = onValue(userVideoRef, snapshot => {
            const { status, progress } = snapshot.val();
            progress && setProcessingProgress(progress);
            if (status === 'done') {
                getVideoUrl();
                unsubscribe();
            } else if (status === 'error') {
                unsubscribe();
                // setErrorError(true);
            }
        });
    }

    async function getVideoUrl() {
        try {
            const url = `${URL_BASE}/retrievewebreact`;
            const res = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                body: `bucket_name=rodeo_web_experiments_storage&blob_name=${videoId}`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            // console.log({ res });
            if (res.ok) {
                const { video_url } = await res.json();
                if (video_url) {
                    setVideoUrl(video_url);
                }
            }
        } catch (e) {
            console.log('error getting the url', e);
            logWebReactsEvent({
                type: WEB_REACTS_EVENTS.ERROR,
                userId,
                data: `Video URL error: ${JSON.stringify(e)}`,
                videoSessionId,
            });
        }
    }

    function cloudProcessVideo() {
        if (!recordedChunks) {
            return;
        }
        const now = new Date();
        const fileName = `video-${now.getHours()}:${now.getMinutes()}.webm`;
        const file = new File(recordedChunks as Blob[], fileName, {
            type: 'video/x-matroska',
        });
        const uploadedFile = uploadFile({
            uploadFile: file,
        });
        const handleUploadProgress = (snapshot: Snapshot) => {
            // console.log({ snapshot });
            const { bytesTransferred, totalBytes } = snapshot;
            setUploadProgress((bytesTransferred / totalBytes) * 100);
        };
        const handleUploadError = (error: Error) => {
            console.log(error);
            logWebReactsEvent({
                type: WEB_REACTS_EVENTS.ERROR,
                userId,
                data: `Video upload error: ${JSON.stringify(error)}`,
                videoSessionId,
            });
            throw new Error();
        };
        const handleUploadSuccess = async () => {
            if (!uploadedFile) {
                handleUploadError(new Error('No uploaded file'));
            }
            const {
                uploadTask: {
                    snapshot: {
                        ref: { bucket, fullPath, name },
                    },
                },
            } = uploadedFile;
            try {
                const res = await fetch(`${URL_BASE}/encodewebreact`, {
                    method: 'POST',
                    body: `bucket_name=${bucket}&blob_name=${fullPath}&duration=${recordingTimeInSeconds}`,
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });
                // console.log({ res });
                if (res.ok) {
                    setVideoId(name);
                } else {
                    logWebReactsEvent({
                        type: WEB_REACTS_EVENTS.ERROR,
                        userId,
                        data: `Video upload error: ${JSON.stringify(res)}`,
                        videoSessionId,
                    });
                    throw new Error(
                        `code: ${res.status}, text: ${res.statusText}`,
                    );
                }
            } catch (e: any) {
                handleUploadError(e);
            }
        };
        uploadedFile.uploadTask.on(
            'state_change',
            handleUploadProgress,
            handleUploadError,
            handleUploadSuccess,
        );
    }

    function getOverallProgress() {
        if (uploadProgress > 99 && processingProgress > 99) {
            return 100;
        }
        const totalProgress = (uploadProgress + processingProgress) / 2;
        if (totalProgress > 99) {
            return 100;
        }
        return Math.min(100, totalProgress);
    }

    return (
        <div class={style['exporter-overlay']}>
            <div class={style['exporter-content']}>
                <h2 class={style['exporter-heading']}>{heading}</h2>
                <p class={style['exporter-subhead']}>{subhead}</p>
                {videoUrl && [
                    <Download url={videoUrl} />,
                    <Button
                        buttonColor="transparent"
                        buttonStyle="text"
                        handleClick={handleRestartVideo}
                        label="Restart"
                        className={style['restart-button']}
                    />,
                ]}
                {!videoUrl && (
                    <div class={style['progress-bar-container']}>
                        <ProgressBar
                            percentComplete={overallProgress}
                            barStyle="holo"
                        />
                    </div>
                )}
            </div>
            <div class={style['exporter-meta']}>
                <span class={style['exporter-meta-property']}>
                    Duration:{' '}
                    <span class={style['exporter-meta-value']}>
                        {formatTime(recordingTimeInSeconds)}
                    </span>
                </span>
                <span class={style['exporter-meta-property']}>
                    Approx Size{' '}
                    <span class={style['exporter-meta-value']}>
                        {formatBytes({
                            bytes: recordedChunks.reduce((a, b) => {
                                if (!b) {
                                    return a;
                                }
                                return a + b.size;
                            }, 0),
                            decimals: 0,
                        })}
                    </span>
                </span>
            </div>
        </div>
    );
};

export default Exporter;
