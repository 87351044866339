import { FunctionalComponent, h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import { timeDiffString } from '../../../../utils';
import { BroadcastContentDetails } from '../../../../utils/youtube';
import ActionButton from '../actionButton';
import style from './style.scss';

export interface ScheduledBroadcastDetails {
    channelThumbnailUrl: string;
    date: string;
    description: string;
    id: string;
    privacyStatus: PrivacyStatus;
    title: string;
    username: string;
    contentDetails: BroadcastContentDetails;
}

interface Props {
    scheduledBroadcastDetails: ScheduledBroadcastDetails;
    onAction: () => void;
}

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const ScheduledBroadcast: FunctionalComponent<Props> = ({
    scheduledBroadcastDetails,
    onAction,
}: Props) => {
    const [minimized, setMinimized] = useState<boolean>(false);
    const [headerHeight, setHeaderHeight] = useState<number | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const didMount = useRef<boolean>(false);

    useEffect(() => {
        if (didMount.current) {
        } else {
            didMount.current = true;
            if (headerRef.current) {
                setHeaderHeight(
                    headerRef.current.getBoundingClientRect().height,
                );
            }
        }
    });

    const broadcastDate = new Date(scheduledBroadcastDetails.date);
    const broadcastTime = broadcastDate.toLocaleTimeString('default', {
        hour: 'numeric',
        minute: '2-digit',
    });

    return (
        <div
            class={style['scheduled-broadcast-container']}
            style={{ height: minimized ? headerHeight : 'auto' }}
            ref={containerRef}
        >
            <div class={style['scheduled-broadcast-header']} ref={headerRef}>
                Scheduled Broadcast
                <h2>{scheduledBroadcastDetails.title}</h2>
                <button
                    type="button"
                    class={minimized ? style.minimized : ''}
                    onClick={() => {
                        setMinimized(!minimized);
                    }}
                >
                    <span class={style['button-text']}>
                        {minimized ? 'show' : 'hide'}
                    </span>
                </button>
            </div>
            <div class={style['scheduled-broadcast-preview-content']}>
                <img
                    src={scheduledBroadcastDetails.channelThumbnailUrl}
                    alt="Scheduled broadcast preview image"
                />
                <div class={style['scheduled-broadcast-details']}>
                    <span>
                        Live {timeDiffString(broadcastDate, new Date())}
                    </span>
                    <span>{`${
                        months[broadcastDate.getMonth()]
                    } ${broadcastDate.getDate()} at ${broadcastTime}`}</span>
                </div>
            </div>
            <div class={style['scheduled-broadcast-action']}>
                <ActionButton
                    ui="text"
                    display="standard"
                    type="action"
                    environment="dark"
                    action={onAction}
                    text="Add video"
                />
            </div>
        </div>
    );
};

export default ScheduledBroadcast;
