// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

// constants, enums, types

// utils

// components
// import ReactOptionDisplay from '../reactOptionDisplay';
import { RecordAppContext } from '../../index';
import AddComputerMediaButton from '../addComputerMediaButton';
import Button from '../../../common/button';
import PopUpPanel from '../../../common/popupPanel';

// assets
import ShareScreenIcon from '../../../../assets/images/share-screen-icon.svg';

//styles
import style from './style.scss';

interface Props {}

const AddContentPanel: FunctionalComponent<Props> = ({}: Props) => {
	const { handleGetScreen } = useContext(RecordAppContext);
	return (
		<PopUpPanel title="" className={style['add-content-wrapper']}>
			<ul className={style['add-content-list']}>
				<li className={style['add-content-option']}>
					<AddComputerMediaButton inline={true} />
				</li>
				<li className={style['add-content-option']}>
					<Button
						label="Share screen"
						handleClick={handleGetScreen}
						icon={<ShareScreenIcon />}
						iconPosition="left"
						buttonStyle="rectangle"
					/>
				</li>
			</ul>
		</PopUpPanel>
	);
};

export default AddContentPanel;
