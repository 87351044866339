// @ts-ignore
const giflerSrc = 'https://cdn.jsdelivr.net/npm/gifler@0.1.0/gifler.min.js';
// @ts-ignore
export const loadGiflerScripts = (cb: any) => {
	(function () {
		const id = 'gifler-js';
		const src = giflerSrc;
		const firstJs = document.getElementsByTagName('script')[0];
		if (!firstJs) {
			return;
		}

		if (document.getElementById(id)) {
			return;
		}
		const js = document.createElement('script');
		js.id = id;
		js.src = src;
		js.onload = cb;
		// @ts-ignore
		firstJs.parentNode.insertBefore(js, firstJs);
	})();
};
