export const CANVAS_BLUR_RADIUS = 18;
export const PIP_POS_PADDING = 81;
export const DRAG_DISTANCE_THRESHOLD = 30;
export const PIP_CIRCLE_SIZE = 375;
export const VIDEO_SKIP_AMOUNT = 15;
export const FREEZE_TWEEN_TIME = 250;
export const ITEM_FOCUS_TIMEOUT = 4000;
export const DESIRED_VIDEO_BITRATE = 14 * 1000000; // 14mbps
export const DESIRED_AUDIO_BITRATE = 128 * 1000; // 128kbps
export const URL_BASE = IS_LOCAL
	? 'http://0.0.0.0:69'
	: 'https://cloud.rodeo.live';
export const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const CANVAS_CLICK_MS_THRESHOLD = 250;
export const NOOP = () => {};
export const VIDEO_SESSION_ID_LENGTH = 8;
