// from packages
import { FunctionalComponent, h } from 'preact';
import { forwardRef, useImperativeHandle } from 'preact/compat';
import { useEffect, useRef, useState } from 'preact/hooks';

//styles
import style from './style.scss';

interface Props {
    onCanPlay: ({ videoEl }: { videoEl: HTMLVideoElement }) => void;
    onTimeUpdate: ({
        currentTime,
        duration,
    }: {
        currentTime: number;
        duration: number;
    }) => void;
    onVideoEnded: () => void;
    src: string;
}

const PreviewVideo: FunctionalComponent<Props> = (
    { onCanPlay, onTimeUpdate, onVideoEnded, src }: Props,
    ref: any,
) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (src && videoRef.current) {
            videoRef.current.src = src;
            videoRef.current.addEventListener(
                'canplaythrough',
                handleCanPlayThrough,
            );
            videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
            videoRef.current.load();
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener(
                    'canplaythrough',
                    handleCanPlayThrough,
                );
                videoRef.current.removeEventListener(
                    'timeupdate',
                    handleTimeUpdate,
                );
            }
        };
    }, [src]);

    useImperativeHandle(
        ref,
        () => {
            return { togglePlay, setCurrentTime };
        },
        [loaded, src],
    );

    async function togglePlay() {
        if (videoRef.current && loaded) {
            if (videoRef.current.paused) {
                try {
                    await videoRef.current!.play();
                } catch (e) {
                    console.log('error playing', e);
                }
            } else {
                videoRef.current.pause();
            }
        }
    }

    function setCurrentTime(time: number) {
        if (!videoRef.current) {
            return;
        }

        videoRef.current.currentTime = time;
    }

    function handleTimeUpdate() {
        if (!videoRef.current) {
            return;
        }
        const { currentTime, duration } = videoRef.current;
        onTimeUpdate({
            duration: isNaN(duration) ? Infinity : duration,
            currentTime,
        });
        if (duration === currentTime) {
            onVideoEnded();
        }
    }

    function handleCanPlayThrough() {
        if (!videoRef.current) {
            return;
        }
        setLoaded(true);
        onCanPlay({ videoEl: videoRef.current });
    }

    return <video ref={videoRef} class={style['preview-video']} />;
};

export default forwardRef(PreviewVideo);
