export enum PLAYLIST_ACTIONS {
	ADD_FILES = 'ADD_FILES',
	ADD_SCREEN_STREAM = 'ADD_SCREEN_STREAM',
	ADD_CLOUD_ITEMS = 'ADD_CLOUD_ITEMS',
	REMOVE = 'REMOVE',
	MODIFY = 'MODIFY',
	CLEAR = 'CLEAR',
}

export enum PLAYLIST_ITEM_FILE_TYPE {
	FILE = 'FILE',
	SCREEN_STREAM = 'SCREEN_STREAM',
	RODEO_CLOUD_FILE = 'RODEO_CLOUD_FILE',
}

export enum CONTENT_LAYOUT {
	CIRCLE = 'CIRCLE',
	TOP = 'TOP',
	BOTTOM = 'BOTTOM',
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
	SOURCE = 'SOURCE',
	CAMERA = 'CAMERA',
}

export enum HOVERING_AREA {
	PIP = 'PIP',
	SOURCE = 'SOURCE',
	NONE = 'NONE',
}

export enum PIP_POS {
	TOP_LEFT,
	TOP_RIGHT,
	BOTTOM_LEFT,
	BOTTOM_RIGHT,
	// TOP_MIDDLE,
	// BOTTOM_MIDDLE,
}

export enum PIP_SHAPE {
	CIRCLE = 'CIRCLE', // both
	PORTRAIT = 'PORTRAIT', // ???
	LANDSCAPE = 'LANDSCAPE', // only in portrait
	PORTRAITISH = 'PORTRAITISH', // only in portrait
	LANDSCAPEISH = 'LANDSCAPEISH', // only in landscape
}

export enum STAGE_ORIENTATION {
	LANDSCAPE = 'LANDSCAPE',
	PORTRAIT = 'PORTRAIT',
}

export enum RECORDED_CHUNK_ACTIONS {
	ADD = 'ADD',
	RESET = 'RESET',
}

export enum RECORDING_STATE {
	NOT_STARTED = 'NOT STARTED',
	RECORDING = 'RECORDING',
	PAUSED = 'PAUSED',
	PREVIEW = 'PREVIEW',
	DONE = 'DONE',
}

export enum SOURCE_CONTENT_TYPE {
	VIDEO = 'VIDEO',
	IMAGE = 'IMAGE',
	HEIC = 'HEIC',
	GIF = 'GIF',
	AUDIO = 'AUDIO',
	SCREEN = 'SCREEN',
}

export enum WEB_REACTS_EVENTS {
	ADDED_MEDIA = 'web_reacts_added_media_to_stage',
	DOWNLOADED_RECORDED_VIDEO = 'web_reacts_downloaded_recorded_video',
	ENDED_RECORDING = 'web_reacts_ended_recording',
	ERROR = 'web_reacts_error',
	PAUSED_RECORDING = 'web_reacts_paused_recording',
	SAVED_RECORDED_VIDEO = 'web_reacts_saved_recorded_video',
	STARTED_RECORDING = 'web_reacts_started_recording',
}
