// from packages
import { FunctionalComponent, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useContext, useRef, useState } from 'preact/hooks';
import TWEEN from '@tweenjs/tween.js';

// constants, enums, types
import { CANVAS_CLICK_MS_THRESHOLD } from '../../constants';
import {
	CONTENT_LAYOUT,
	HOVERING_AREA,
	PIP_POS,
	RECORDING_STATE,
	STAGE_ORIENTATION,
} from '../../enums';
import { Coords } from '../../types';

// utils
import { buildClasses } from '../../../../utils';

// components
import CompCanvas from '../compCanvas';
import MediaControls from '../mediaControls';
import RecordButton from '../recordButton';
import UserCameraDropZones from '../userCameraDropZones';
import { RecordAppContext } from '../../index';
import Button from '../../../common/button';

// assets
import ChangeToLandscapeIcon from '../../../../assets/images/change-to-landscape-icon.svg';
import ChangeToPortraitIcon from '../../../../assets/images/change-to-portrait-icon.svg';
import FitFillIcon from '../../../../assets/images/fit-fill-icon.svg';

//styles
import style from './style.scss';

interface Props {
	toggleClipsPlaying: () => void;
	handleLayoutsButtonClick: () => void;
	handlePipShapeChange?: () => void;
	handleToggleOrientation: () => void;
	userCamera?: HTMLVideoElement;
	userCameraLoaded: boolean;
	setFlip: (fn: () => void) => void;
}

const Stage: FunctionalComponent<Props> = (
	{
		handleLayoutsButtonClick,
		handlePipShapeChange,
		handleToggleOrientation,
		setFlip,
		toggleClipsPlaying,
		userCamera,
		userCameraLoaded,
	}: Props,
	ref: any,
) => {
	const {
		activeItem,
		addingFilesFromComputer,
		contentLayout,
		focusFlipped,
		handleFitFillButtonClick,
		handleUserCameraDragEnd,
		isCleanState,
		orientation,
		pipPos,
		recordingCursor,
		// handleLayoutsButtonClick,

		recordingState,
	} = useContext(RecordAppContext);

	const stageWrapperRef = useRef<HTMLDivElement>(null);
	const [isMovingPip, setIsMovingPip] = useState<boolean>(false);
	const [dragPos, setDragPos] = useState<Coords>({ x: 0, y: 0 });
	const [newContentLayout, setNewContentLayout] =
		useState<CONTENT_LAYOUT>(contentLayout);
	const [newPipPos, setNewPipPos] = useState<PIP_POS>(pipPos);
	// @ts-ignore
	const [mouseDownTime, setMouseDownTime] = useState<number>(0);
	const [hoveringArea, setHoveringArea] = useState<HOVERING_AREA>(
		HOVERING_AREA.NONE,
	);
	// @ts-ignore
	const [mouseDown, setMouseDown] = useState<boolean>(false);
	const mouseDownTimer = useRef<number>(0);

	// useEffect(() => {
	// 	if (stageWrapperRef.current) {
	// 		stageWrapperRef.current.addEventListener(
	// 			'click',
	// 			handleStageWrapperClick,
	// 		);
	// 	}
	// 	return () => {
	// 		stageWrapperRef.current &&
	// 			stageWrapperRef.current.removeEventListener(
	// 				'click',
	// 				handleStageWrapperClick,
	// 			);
	// 	};
	// }, [stageWrapperRef]);

	// function handleStageWrapperClick(e: Event) {
	// 	e.stopPropagation();
	// }

	// useEffect(() => {});

	function handleCanvasMouseDown({
		event,
		x,
		y,
	}: {
		event: MouseEvent;
		x: number;
		y: number;
	}) {
		if (
			!activeItem ||
			contentLayout === CONTENT_LAYOUT.CAMERA ||
			contentLayout === CONTENT_LAYOUT.SOURCE
		) {
			return;
		}
		setMouseDown(true);
		setDragPos({
			x,
			y,
		});
		setMouseDownTime(event.timeStamp);
		// @ts-ignore
		mouseDownTimer.current = setTimeout(() => {
			setIsMovingPip(true);
		}, CANVAS_CLICK_MS_THRESHOLD);
	}

	function handleCanvasMouseOver({
		event,
		area,
	}: {
		event: MouseEvent;
		area: HOVERING_AREA;
	}) {
		if (isMovingPip) {
			setDragPos({
				x: event.offsetX,
				y: event.offsetY,
			});
		} else {
			if (activeItem) {
				setHoveringArea(area);
			}
		}

		return;
	}

	function handleCanvasMouseUp(e: MouseEvent) {
		// let layout = contentLayout;
		// let nextPipPos = pipPos;
		clearTimeout(mouseDownTimer.current);
		// if (e.timeStamp - mouseDownTime < CANVAS_CLICK_MS_THRESHOLD) {
		if (isMovingPip) {
			// layout = newContentLayout;

			// nextPipPos = newPipPos;
			handleUserCameraDragEnd({
				layout: newContentLayout,
				pipPos: newPipPos,
			});
		} else {
			if (contentLayout === CONTENT_LAYOUT.CIRCLE) {
				handlePipShapeChange && handlePipShapeChange();
			}
		}
		setIsMovingPip(false);
		setMouseDown(false);
	}

	function handleSetNewContentLayout({ layout }: { layout: CONTENT_LAYOUT }) {
		setNewContentLayout(layout);
	}

	function handleSetNewPipPos({ pipPos }: { pipPos: PIP_POS }) {
		setNewPipPos(pipPos);
	}

	const recordButtonContainerClasses = buildClasses({
		[style['record-button-container']]: true,
		[style['z-plane-item']]: true,
		[style.centered]: true,
	});

	const mainStageClasses = buildClasses({
		[style['stage-wrapper']]: true,
		[style['adding-files-from-computer']]: addingFilesFromComputer,
		[style['special-cursor']]: recordingCursor,
	});

	const controlsContainerClasses = buildClasses({
		[style['z-plane-item']]: true,
		[style['controls-container']]: true,
		[style[contentLayout.toLowerCase()]]: !focusFlipped,
		[style.flipped]: focusFlipped,
	});

	return (
		<div
			class={mainStageClasses}
			onDragOver={e => {
				e.stopPropagation();
				e.preventDefault();
				return false;
			}}
			onDrop={e => {
				e.preventDefault();
			}}
			onMouseLeave={() => {
				setHoveringArea(HOVERING_AREA.NONE);
			}}
		>
			{isCleanState && (
				<div class={recordButtonContainerClasses} ref={stageWrapperRef}>
					{!addingFilesFromComputer && !isMovingPip && (
						<RecordButton />
					)}
				</div>
			)}
			{recordingState === RECORDING_STATE.NOT_STARTED &&
				!addingFilesFromComputer &&
				!isMovingPip && (
					<Button
						handleClick={handleToggleOrientation}
						label="Aspect Ratio"
						hideLabel={true}
						buttonStyle="icon"
						icon={
							orientation === STAGE_ORIENTATION.PORTRAIT ? (
								<ChangeToLandscapeIcon />
							) : (
								<ChangeToPortraitIcon />
							)
						}
						className={`${style['aspect-ratio-button']} ${style['z-plane-item']}`}
					/>
				)}
			{hoveringArea === HOVERING_AREA.SOURCE &&
				activeItem &&
				recordingState !== RECORDING_STATE.PAUSED &&
				recordingState !== RECORDING_STATE.DONE &&
				!addingFilesFromComputer &&
				!isMovingPip && (
					<div class={controlsContainerClasses}>
						<MediaControls />
					</div>
				)}
			{activeItem &&
				recordingState !== RECORDING_STATE.PAUSED &&
				recordingState !== RECORDING_STATE.DONE &&
				!addingFilesFromComputer &&
				!isMovingPip && [
					<div class={style['layout-buttons-container']}>
						<Button
							handleClick={handleLayoutsButtonClick}
							label="LAYOUTS"
							buttonStyle="pill"
							buttonColor="transparent"
							className={style['layouts-button']}
						/>
					</div>,
					<Button
						handleClick={handleFitFillButtonClick}
						label="Fit or Fill"
						hideLabel={true}
						buttonStyle="icon"
						icon={<FitFillIcon />}
						className={`${style['fit-fill-button']} ${
							style['z-plane-item']
						} ${style[contentLayout.toLowerCase()]}`}
					/>,
				]}
			{isMovingPip && (
				<UserCameraDropZones
					dragPos={dragPos}
					handleSetNewContentLayout={handleSetNewContentLayout}
					handleSetNewPipPos={handleSetNewPipPos}
				/>
			)}
			<CompCanvas
				contentLayout={contentLayout}
				dimmed={
					recordingState === RECORDING_STATE.PAUSED ||
					recordingState === RECORDING_STATE.DONE
				}
				dragPos={dragPos}
				isMainDisplayCanvas={true}
				isDraggingPip={isMovingPip}
				onMouseDown={handleCanvasMouseDown}
				onMouseOver={handleCanvasMouseOver}
				onMouseUp={handleCanvasMouseUp}
				onPipClick={handlePipShapeChange}
				ref={ref}
				showCursor={recordingCursor}
				tween={TWEEN}
				setFlip={setFlip}
			/>
		</div>
	);
};

export default forwardRef(Stage);
