// from packages
import { FunctionalComponent, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useContext, useEffect, useRef, useState } from 'preact/hooks';

// constants, enums, types
import { PIP_CIRCLE_SIZE } from '../../constants';
import { CONTENT_LAYOUT, PIP_SHAPE } from '../../enums';
import { Coords } from '../../types';

// utils
import { buildClasses } from '../../../../utils';
import { rectContains } from '../../../../utils/canvas';

// components
import { RecordAppContext } from '../../index';

// assets

//styles
import style from './style.scss';

interface Props {
    dragPos: Coords;
    handleZoneActive: () => void;
    position: { top: string; right?: string; bottom?: string; left: string };
    showZone: boolean;
}

const DROP_ZONE_BUFFER = 30;

const UserCameraDropZone: FunctionalComponent<Props> = ({
    dragPos,
    handleZoneActive,
    position: { top, right = 'auto', bottom = 'auto', left },
    showZone,
}: Props) => {
    const { contentLayout, pipShape, stageScale } =
        useContext(RecordAppContext);
    const containerRef = useRef<HTMLDivElement>(null);
    const [dropAreaActive, setDropAreaActive] = useState<boolean>(false); // Maybe put this in the parent?

    useEffect(() => {
        if (containerRef.current && dragPos) {
            const elRect = containerRef.current.getBoundingClientRect();
            const parentRect = (
                containerRef.current.parentNode as HTMLDivElement
            )?.getBoundingClientRect();
            const elTop = elRect.top - parentRect.top;
            const elLeft = elRect.left - parentRect.left;
            const isInDropZone = rectContains({
                x: elLeft - DROP_ZONE_BUFFER,
                y: elTop - DROP_ZONE_BUFFER,
                w: elRect.width + DROP_ZONE_BUFFER * 2,
                h: elRect.height + DROP_ZONE_BUFFER * 2,
                pX: dragPos.x,
                pY: dragPos.y,
            });
            setDropAreaActive(isInDropZone);
            if (isInDropZone) {
                handleZoneActive();
            }
        }
    }, [dragPos]);

    const dropZoneClasses = buildClasses({
        [style['user-camera-drop-zone']]: true,
        [style[`shape-${pipShape.toLowerCase()}`]]: true,
        [style[`layout-${contentLayout.toLowerCase()}`]]: true,
        [style.active]: dropAreaActive,
        [style['show-zone']]: showZone,
    });

    const baseStyles = { top, right, bottom, left };
    let zoneInlineStyle: {
        top?: string;
        right?: string;
        bottom?: string;
        left?: string;
        width?: string;
        height?: string;
    } = baseStyles;

    if (contentLayout === CONTENT_LAYOUT.CIRCLE) {
        switch (pipShape) {
            case PIP_SHAPE.CIRCLE:
                zoneInlineStyle = {
                    ...baseStyles,
                    width: `${PIP_CIRCLE_SIZE * stageScale}px`,
                    height: `${PIP_CIRCLE_SIZE * stageScale}px`,
                };
                break;
            case PIP_SHAPE.PORTRAITISH:
                zoneInlineStyle = {
                    ...baseStyles,
                    height: `${PIP_CIRCLE_SIZE * 2 * stageScale}px`,
                };
                break;
            case PIP_SHAPE.LANDSCAPEISH:
                zoneInlineStyle = {
                    ...baseStyles,
                    height: `${PIP_CIRCLE_SIZE * stageScale}px`,
                };
                break;
        }
    }

    return (
        <div
            ref={containerRef}
            class={dropZoneClasses}
            style={zoneInlineStyle}
        ></div>
    );
};

export default forwardRef(UserCameraDropZone);
