import { FunctionalComponent, h, JSX } from 'preact';
import { buildClasses } from '../../../../utils';
import style from './style.scss';

export type ButtonUI = 'icon' | 'text' | 'both';
export type ButtonDisplay = 'pill' | 'transparent' | 'standard';
export type ButtonType = 'normal' | 'action' | 'warning';
export type ButtonAlign = 'left' | 'center' | 'right';
export type ButtonEnvironment = 'dark' | 'light'; // TODO: I don't know if this is the best approach
export type ButtonSize = 'small' | 'large' | 'full';

interface Props {
    ui: ButtonUI;
    display?: ButtonDisplay;
    type?: ButtonType;
    action: (event: MouseEvent) => void;
    align?: ButtonAlign;
    icon?: JSX.Element;
    iconHasFill?: boolean;
    environment?: ButtonEnvironment;
    text: string;
    disabled?: boolean;
    size?: ButtonSize;
}

const ActionButton: FunctionalComponent<Props> = ({
    ui,
    type = 'normal',
    align = 'center',
    action,
    icon,
    iconHasFill = false,
    text,
    display,
    disabled = false,
    size = 'small',
    environment = 'light',
}: Props) => {
    const classes = buildClasses({
        [style['action-button']]: true,
        [style['icon-button']]: ui === 'icon',
        [style['icon-fill']]: iconHasFill,
        [style['icon-text-button']]: ui === 'both',
        [style['align-center']]: align === 'center',
        [style['align-right']]: align === 'right',
        [style['align-left']]: align === 'left',
        [style['display-transparent']]: display === 'transparent',
        [style['display-standard']]: display === 'standard',
        [style['display-pill']]: display === 'pill',
        [style['type-warning']]: type === 'warning',
        [style['type-normal']]: type === 'normal',
        [style['type-action']]: type === 'action',
        [style['light-text']]: environment === 'dark',
        [style['dark-text']]: environment === 'light',
        [style['small-width']]: size === 'small',
        [style['large-width']]: size === 'large',
        [style['full-width']]: size === 'full',
    });

    return (
        <button
            type="button"
            class={classes}
            onClick={action}
            disabled={disabled}
        >
            {icon && icon}
            <span class={style['button-text']}>{text}</span>
        </button>
    );
};

export default ActionButton;
