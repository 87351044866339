import { FunctionalComponent, h } from 'preact';
import ActionButton, {
    ButtonUI,
    ButtonDisplay,
    ButtonAlign,
    ButtonEnvironment,
    ButtonType,
    ButtonSize,
} from '../actionButton';

interface Props {
    ui: ButtonUI;
    display: ButtonDisplay;
    blobName: string;
    onDeleteSuccess: () => void;
    align?: ButtonAlign;
    action: (
        blobName: string,
        displayName: string,
        fileType: FileType,
        onDeleteSuccess: () => void,
    ) => void;
    displayName: string;
    fileType: FileType;
    environment?: ButtonEnvironment;
    type?: ButtonType;
    buttonSize?: ButtonSize;
}

// TODO: is this the right spot for this? feels weird to export it and use it in from a prop. Oh well
export async function deleteFile(
    blobName: string,
    fileType: 'video' | 'audio' | 'image',
    onDeleteSuccess: () => void,
) {
    const bucket =
        fileType === 'video'
            ? 'stations_cloud_pal'
            : fileType === 'audio'
            ? 'rodeo_cloud_audio'
            : 'rodeo_cloud_image';
    try {
        const res = await fetch('https://cloud.rodeo.live/delete', {
            method: 'POST',
            body: `bucket_name=${bucket}&blob_name=${blobName}`,
            mode: 'cors',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        });
        if (res.ok) {
            onDeleteSuccess();
        }
    } catch (e) {
        console.log('error deleting', e);
    }
}

const Delete: FunctionalComponent<Props> = ({
    ui,
    blobName,
    onDeleteSuccess,
    align = 'center',
    display,
    action,
    displayName,
    environment = 'light',
    type = 'normal',
    fileType,
    buttonSize = 'small',
}: Props) => {
    return (
        <ActionButton
            ui={ui}
            display={display}
            align={align}
            environment={environment}
            type={type}
            text="Delete"
            size={buttonSize}
            icon={
                <svg width="29" height="17" xmlns="http://www.w3.org/2000/svg">
                    <g
                        transform="translate(.5)"
                        stroke="#262626"
                        stroke-width="2"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <path d="m13.926 4-.79 11.071a.997.997 0 0 1-.998.929H4.862a.997.997 0 0 1-.997-.929L3.074 4h10.852Z" />
                        <rect x="5" y="1" width="7" height="3" rx="1.5" />
                        <path d="M1 4h15v1H1z" />
                    </g>
                </svg>
            }
            action={() => {
                action(blobName, displayName, fileType, onDeleteSuccess);
            }}
        />
    );
};

export default Delete;
