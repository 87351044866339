// from packages
import { Fragment, FunctionalComponent, h } from 'preact';

// constants, enums, types

// utils
import { buildClasses, timeString } from '../../../../utils';

// components
import ProgressBar from '../../../common/progressBar';

// assets
import SuccessBadgeIcon from '../../../../assets/images/success-badge-icon.svg';

//styles
import style from './style.scss';

interface Props {
    doneText: string;
    estimatedTimeRemaining: number;
    percentComplete: number;
    status: string;
}

const ProgressPill: FunctionalComponent<Props> = ({
    doneText,
    estimatedTimeRemaining,
    percentComplete,
    status,
}: Props) => {
    const isDone = percentComplete >= 100;

    const pillClasses = buildClasses({
        [style['progress-pill']]: true,
        [style.done]: isDone,
    });

    return (
        <div class={pillClasses}>
            {!isDone && (
                <Fragment>
                    <span class={style['progress-pill-status-text']}>
                        {status}
                    </span>
                    <div class={style['progress-bar-container']}>
                        <ProgressBar
                            percentComplete={percentComplete}
                            barStyle="basic"
                        />
                    </div>
                    <span class={style['progress-pill-time-remaining']}>
                        {timeString(estimatedTimeRemaining)} left
                    </span>
                </Fragment>
            )}
            {isDone && (
                <Fragment>
                    <SuccessBadgeIcon />
                    <span class={style['progress-pill-status-text']}>
                        {doneText}
                    </span>
                </Fragment>
            )}
        </div>
    );
};

export default ProgressPill;
