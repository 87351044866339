// from packages
import { FunctionalComponent, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useEffect } from 'preact/hooks';

// constants, enums, types

// utils
import { getMedia } from '../../../../utils/mediaDevices';

// components

// assets

//styles
import style from './style.scss';

interface Props {
	audioSourceId?: string;
	onUserCameraLoaded: () => void;
	videoSourceId?: string;
	hasPermissions: boolean;
}

const UserCamera: FunctionalComponent<Props> = (
	{ audioSourceId, onUserCameraLoaded, videoSourceId, hasPermissions }: Props,
	ref: any,
) => {
	useEffect(() => {
		if (ref.current && hasPermissions && videoSourceId) {
			loadUserCamera();
		}
	}, [ref, hasPermissions, videoSourceId]);

	async function loadUserCamera() {
		try {
			const mediaStream = await getMedia({
				audioSourceId,
				videoSourceId,
			});
			ref.current.srcObject = mediaStream;
		} catch (e) {
			console.log('error getting the user camera', e);
		}
	}
	return (
		<video
			ref={ref}
			onLoadedData={onUserCameraLoaded}
			muted
			autoPlay
			playsInline
			controls={false}
			class={style.camera}
		/>
	);
};

export default forwardRef(UserCamera);
