// packages
import { FunctionalComponent, h } from 'preact';
import { useContext, useEffect, useRef, useState } from 'preact/hooks';

// constants, enums, types
import { CONTENT_LAYOUT, STAGE_ORIENTATION } from '../../enums';

// utils
import { buildClasses } from '../../../../utils';

// components
import CompCanvas from '../compCanvas';
import { RecordAppContext } from '../../index';

// styles
import style from './style.scss';

interface Props {
	isOpen: boolean;
	handleSelectLayout: ({ layout }: { layout: CONTENT_LAYOUT }) => void;
}

const LayoutPicker: FunctionalComponent<Props> = ({
	isOpen,
	handleSelectLayout,
}) => {
	const splitCanvasRef = useRef<HTMLCanvasElement>();
	const pipCanvasRef = useRef<HTMLCanvasElement>();
	const sourceCanvasRef = useRef<HTMLCanvasElement>();
	const cameraCanvasRef = useRef<HTMLCanvasElement>();
	const { contentLayout, handleLayoutsButtonClick, orientation } =
		useContext(RecordAppContext);
	const [zoomed, setZoomed] = useState<boolean>(false);
	const [anchors, setAnchors] = useState<{ top: string; left: string }>({
		top: '0px',
		left: '0px',
	});

	useEffect(() => {
		// if (!isOpen) {
		setZoomed(!isOpen);
		// }
	}, [isOpen]);

	function isActiveSplitLayout() {
		if (orientation === STAGE_ORIENTATION.LANDSCAPE) {
			return (
				contentLayout === CONTENT_LAYOUT.LEFT ||
				contentLayout === CONTENT_LAYOUT.RIGHT
			);
		} else {
			return (
				contentLayout === CONTENT_LAYOUT.TOP ||
				contentLayout === CONTENT_LAYOUT.BOTTOM
			);
		}
	}

	const gridClasses = buildClasses({
		[style['layout-grid']]: true,
		[style['zoom-in']]: zoomed,
		[style.active]: isOpen,
	});

	if (!isOpen) {
		return null;
	}

	return (
		<div
			class={gridClasses}
			style={{
				'--anchorTop': anchors.top,
				'--anchorLeft': anchors.left,
			}}
			onAnimationEnd={() => {
				if (zoomed) {
					handleLayoutsButtonClick();
				}
			}}
		>
			<div
				class={`${style['layout-option']} ${
					isActiveSplitLayout() ? style['active-layout'] : ''
				}`}
				onClick={() => {
					// set top and left
					setAnchors({ top: '-8px', left: '-8px' });
					// zoom in
					setZoomed(true);
					handleSelectLayout({
						layout:
							orientation === STAGE_ORIENTATION.LANDSCAPE
								? CONTENT_LAYOUT.LEFT
								: CONTENT_LAYOUT.TOP,
					});
				}}
			>
				<CompCanvas
					ref={splitCanvasRef}
					contentLayout={
						orientation === STAGE_ORIENTATION.LANDSCAPE
							? contentLayout === CONTENT_LAYOUT.RIGHT
								? CONTENT_LAYOUT.RIGHT
								: CONTENT_LAYOUT.LEFT
							: contentLayout === CONTENT_LAYOUT.BOTTOM
							? CONTENT_LAYOUT.BOTTOM
							: CONTENT_LAYOUT.TOP
					}
					isMainDisplayCanvas={false}
				/>
			</div>
			<div
				class={`${style['layout-option']} ${
					contentLayout === CONTENT_LAYOUT.CIRCLE
						? style['active-layout']
						: ''
				}`}
				onClick={() => {
					// set top and left
					setAnchors({ top: '-8px', left: '-100%' });
					// zoom in
					setZoomed(true);
					handleSelectLayout({ layout: CONTENT_LAYOUT.CIRCLE });
				}}
			>
				<CompCanvas
					ref={pipCanvasRef}
					contentLayout={CONTENT_LAYOUT.CIRCLE}
					isMainDisplayCanvas={false}
				/>
			</div>
			<div
				class={`${style['layout-option']} ${
					contentLayout === CONTENT_LAYOUT.SOURCE
						? style['active-layout']
						: ''
				}`}
				onClick={() => {
					// set top and left
					setAnchors({ top: '-100%', left: '0' });
					// zoom in
					setZoomed(true);
					handleSelectLayout({ layout: CONTENT_LAYOUT.SOURCE });
				}}
			>
				<CompCanvas
					ref={sourceCanvasRef}
					contentLayout={CONTENT_LAYOUT.SOURCE}
					isMainDisplayCanvas={false}
				/>
			</div>
			<div
				class={`${style['layout-option']} ${
					contentLayout === CONTENT_LAYOUT.CAMERA
						? style['active-layout']
						: ''
				}`}
				onClick={() => {
					// set top and left
					setAnchors({ top: '-100%', left: '-100%' });
					// zoom in
					setZoomed(true);
					handleSelectLayout({ layout: CONTENT_LAYOUT.CAMERA });
				}}
			>
				<CompCanvas
					ref={cameraCanvasRef}
					contentLayout={CONTENT_LAYOUT.CAMERA}
					isMainDisplayCanvas={false}
				/>
			</div>
		</div>
	);
};

export default LayoutPicker;
