// from packages
import { FunctionalComponent, h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';

// constants, enums, types
// import { RECORDING_STATE } from '../../enums';

// utils
// import { buildClasses, formatTime } from '../../../../utils';

// components
// import { RecordAppContext } from '../../index';

// assets

//styles
// import style from './style.scss';

interface Props {
	src: string;
	time: number;
}

const VideoThumb: FunctionalComponent<Props> = ({ src, time }: Props) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	useEffect(() => {
		if (videoRef.current) {
			videoRef.current.addEventListener('loadeddata', goToTime);
			videoRef.current.load();
			// videoRef.current.currentTime = time;
		}
	}, [videoRef]);

	function goToTime() {
		if (!videoRef.current) {
			return;
		}

		videoRef.current.currentTime = time;
	}

	// function getThumb() {
	// 	const imageCapture = new ImageCapture(
	//         //         activeItemDisplay.srcObject.getVideoTracks()[0],
	//         //     );
	//         //     console.log({
	//         //         imageCapture,
	//         //         track: activeItemDisplay.srcObject.getVideoTracks()[0],
	//         //     });
	//         //     // // const canvas = document.getElementById('canvas');
	//         //     // // const offscreen = canvas.transferControlToOffscreen();

	//         //     // // worker.postMessage({ offscreen }, [offscreen]);

	//         //     // // const draw = () => {
	//         //     imageCapture.grabFrame().then(imageBitmap => {
	// }

	return (
		<div>
			<video ref={videoRef} src={src} />
		</div>
	);
};

export default VideoThumb;
