import { PLAYLIST_ITEM_FILE_TYPE } from '../components/Record/enums';
import { FileType, PlaylistItemFile } from '../components/Record/types';

export function buildClasses(classObj: { [key: string]: boolean }) {
	return Object.keys(classObj)
		.reduce((a: string[], b: string) => {
			if (classObj[b]) {
				a.push(b);
			}
			return a;
		}, [])
		.join(' ');
}

export function formatTime(t: number) {
	const time = t === Infinity ? 0 : t;
	let formatted = new Date(time * 1000).toISOString().substr(11, 8);

	if (formatted[0] === '0' && formatted[1] === '0') {
		formatted = formatted.substr(3);
	}

	if (formatted[0] === '0') {
		return formatted.substr(1);
	}

	return formatted;
}

export function formatDateNum(num: number) {
	if (num < 10) {
		return `0${num}`;
	}

	return num;
}

export function formatBytes({
	bytes,
	decimals = 2,
}: {
	bytes: number;
	decimals: number;
}) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const deviceCanUseMouse = matchMedia('(pointer: fine)').matches;

export const timeDiffString = function (date1: Date, date2: Date) {
	if (date1 < date2) {
		return 'now';
	}

	const diff = Math.abs(date1.getTime() - date2.getTime());
	const seconds = Math.round(diff / 1000);
	const minutes = Math.round(seconds / 60);
	const hours = Math.round(minutes / 60);
	const days = Math.round(hours / 24);

	if (days > 2) {
		return `in ${days} days`;
	}

	if (hours > 0) {
		return `in ${hours} ${hours > 1 ? 'hours' : 'hour'}`;
	}

	if (minutes > 0) {
		return `in ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
	}

	return 'in less than 1 minute';
};

export const timeString = function (time: number) {
	if (time < 60) {
		return `${time}s`;
	}
	let hours;
	let minutes;
	let seconds;

	if (time < 60 * 60) {
		minutes = Math.floor(time / 60);
		seconds = time - minutes * 60;
		return `${minutes}m ${formatDateNum(seconds)}s`;
	}

	hours = Math.floor(time / 60 / 60);
	minutes = Math.floor((time - hours * 60 * 60) / 60);
	seconds = time - hours * 60 * 60 - minutes * 60;

	return `${hours}h ${formatDateNum(minutes)}m ${formatDateNum(seconds)}s`;
};

export function getBrowserInfo() {
	const header = [
		navigator.platform,
		navigator.userAgent,
		navigator.appVersion,
		navigator.vendor,
		// @ts-ignore
		window.opera,
	];
	const dataos = [
		{ name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
		{ name: 'Windows', value: 'Win', version: 'NT' },
		{ name: 'iPhone', value: 'iPhone', version: 'OS' },
		{ name: 'iPad', value: 'iPad', version: 'OS' },
		{ name: 'Kindle', value: 'Silk', version: 'Silk' },
		{ name: 'Android', value: 'Android', version: 'Android' },
		{ name: 'PlayBook', value: 'PlayBook', version: 'OS' },
		{ name: 'BlackBerry', value: 'BlackBerry', version: '/' },
		{ name: 'Macintosh', value: 'Mac', version: 'OS X' },
		{ name: 'Linux', value: 'Linux', version: 'rv' },
		{ name: 'Palm', value: 'Palm', version: 'PalmOS' },
	];
	const databrowser = [
		{ name: 'Chrome', value: 'Chrome', version: 'Chrome' },
		{ name: 'Firefox', value: 'Firefox', version: 'Firefox' },
		{ name: 'Safari', value: 'Safari', version: 'Version' },
		{ name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
		{ name: 'Opera', value: 'Opera', version: 'Opera' },
		{ name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
		{ name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
	];
	const agent = header.join(' ');

	const matchItem = function (
		string: string,
		data: { name: string; value: string; version: string }[],
	) {
		var i = 0,
			j = 0,
			regex,
			regexv,
			match,
			matches,
			version;

		for (i = 0; i < data.length; i += 1) {
			regex = new RegExp(data[i].value, 'i');
			match = regex.test(string);
			if (match) {
				regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
				matches = string.match(regexv);
				version = '';
				if (matches) {
					if (matches[1]) {
						matches = matches[1];
					}
				}
				if (matches) {
					// @ts-ignore
					matches = matches.split(/[._]+/);
					for (j = 0; j < matches.length; j += 1) {
						if (j === 0) {
							version += matches[j] + '.';
						} else {
							version += matches[j];
						}
					}
				} else {
					version = '0';
				}
				return {
					name: data[i].name,
					version: parseFloat(version),
				};
			}
		}
		return { name: 'unknown', version: 0 };
	};
	const os = matchItem(agent, dataos);
	const browser = matchItem(agent, databrowser);
	return { os: os, browser: browser };
}

export const isVideoFile = (file: File) => {
	return file.type?.match('video.*');
};

export const isAudioFile = (file: File) => {
	return file.type?.match('audio.*');
};

export const isImageFile = (file: File) => {
	return file.type?.match('image.*');
};

export const isGifFile = (file: File) => {
	return file.type.match('image.gif');
};

export const isHeicFile = (file: File) => {
	return file.type.match('image.heic');
};

export const activeItemIsVideo = ({
	activeItem,
	activeItemDisplay,
}: {
	activeItem: PlaylistItemFile;
	activeItemDisplay?: HTMLVideoElement | HTMLImageElement;
}) => {
	return (
		activeItem.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
		isVideoFile((activeItem.item as FileType).file) &&
		activeItemDisplay &&
		'play' in activeItemDisplay
	);
};

export const acceptedFileTypes = [
	// 'video/mpg',
	// 'video/mp2',
	// 'video/mpeg',
	// 'video/mpe',
	// 'video/mpv',
	// 'video/ogg',
	// 'video/m4p',
	// 'video/wmv',
	// 'video/mov',
	// 'video/qt',
	// 'video/avchd',
	'video/quicktime',
	'video/mov',
	'.mov',
	'video/mp4',
	'video/m4v',
	'video/avi',
	'audio/*',
	'image/*',
	'.heic',
];

// Filetypes specifcally supported in web reacts
export const acceptedReactFileTypes = [
	'video/quicktime',
	'video/mov',
	'.mov',
	'video/mp4',
	'video/m4v',
	'video/avi',
	'image/jpg',
	'image/jpeg',
	'image/png',
	'image/gif',
	'image/bmp',
];
