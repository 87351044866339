// from packages
import { FunctionalComponent, h } from 'preact';

// constants, enums, types

// utils

// components
import Toggle from '../../../common/toggle';

// assets

//styles
import style from './style.scss';

interface Props {
	title: string;
	description: string;
	onToggle: () => void;
	isOn: boolean;
}

const ReactOptionDisplay: FunctionalComponent<Props> = ({
	title,
	description,
	onToggle,
	isOn,
}: Props) => {
	return (
		<div class={style.option}>
			<div class={style['option-title']}>{title}</div>
			<div class={style['option-body']}>
				<p class={style['option-description']}>{description}</p>
				<Toggle
					handleClick={onToggle}
					isActive={isOn}
					label={title}
					labelPosition="hidden"
				/>
			</div>
		</div>
	);
};

export default ReactOptionDisplay;
