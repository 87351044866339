// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

// constants, enums, types

// utils

// components
import ReactOptionDisplay from '../reactOptionDisplay';
import { RecordAppContext } from '../../index';
import PopUpPanel from '../../../common/popupPanel';

// assets

//styles
import style from './style.scss';

interface Props {}

const ReactOptionsPanel: FunctionalComponent<Props> = ({}: Props) => {
	const {
		autoNextActive,
		focusFlipActive,
		handleToggleAutoNext,
		handleToggleFocusFlip,
		handleToggleRecordCursor,
		recordingCursor,
	} = useContext(RecordAppContext);
	return (
		<PopUpPanel
			title="React Options"
			className={style['react-options-list']}
		>
			<ul>
				<li>
					<ReactOptionDisplay
						title="Record cursor"
						description="Includes your cursor to show viewers where to look"
						onToggle={handleToggleRecordCursor}
						isOn={recordingCursor}
					/>
				</li>
				<li>
					<ReactOptionDisplay
						title="Focus Flip"
						description="Switches back to camera when pausing a Reaction video"
						onToggle={handleToggleFocusFlip}
						isOn={focusFlipActive}
					/>
				</li>
				<li>
					<ReactOptionDisplay
						title="Auto Next"
						description="Advances the playlist once a Reaction video ends"
						onToggle={handleToggleAutoNext}
						isOn={autoNextActive}
					/>
				</li>
			</ul>
		</PopUpPanel>
	);
};
{
	/*				
				<li>
					<ReactOptionDisplay
						title="Auto Mix Audio"
						description="Lowers the volume of the Reaction video while you speak"
						onToggle={() => {}}
						isOn={true}
					/>
				</li>*/
}

export default ReactOptionsPanel;
