// from packages
import { FunctionalComponent, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useContext, useEffect, useRef, useState } from 'preact/hooks';
import TWEEN from '@tweenjs/tween.js';

// constants, enums, types
import { FREEZE_TWEEN_TIME } from '../../constants';
import {
    CONTENT_LAYOUT,
    HOVERING_AREA,
    PIP_POS,
    PIP_SHAPE,
    PLAYLIST_ITEM_FILE_TYPE,
    STAGE_ORIENTATION,
} from '../../enums';
import { CanvasDrawProps, Coords, FileType } from '../../types';

// utils
import { buildClasses, isGifFile } from '../../../../utils';
import {
    getBackgroundDrawProps,
    getLayoutDrawProps,
    getSourceAspectRatio,
} from '../../../../utils/canvas';

// components
import Canvas from '../canvas';
import { RecordAppContext } from '../../index';

// assets

//styles
import style from './style.scss';

export interface CanvasClickEvent {
    ctx: CanvasRenderingContext2D | null;
    event: MouseEvent;
    isPipClick?: boolean;
}

export interface CanvasMouseOver extends CanvasClickEvent {
    offsetX: number;
    offsetY: number;
}

interface DrawProps {
    ctx: CanvasRenderingContext2D;
    drawProps?: CanvasDrawProps;
    source?: HTMLImageElement | HTMLVideoElement;
}

interface Props {
    contentLayout: CONTENT_LAYOUT;
    dimmed?: boolean;
    dragPos?: Coords;
    isDraggingPip?: boolean;
    isMainDisplayCanvas: boolean;
    onClick?: ({ ctx, event }: CanvasClickEvent) => void;
    onMouseDown?: ({
        event,
        x,
        y,
    }: {
        event: MouseEvent;
        x: number;
        y: number;
    }) => void;
    onMouseLeave?: () => void;
    onMouseOver?: ({
        event,
        area,
    }: {
        event: MouseEvent;
        area: HOVERING_AREA;
    }) => void;
    onMouseUp?: (e: MouseEvent) => void;
    onPipClick?: () => void;
    showCursor?: boolean;
    tween?: typeof TWEEN;
    setFlip?: (fn: () => void) => void;
}

interface PipLayerPath {
    path: Path2D;
    x: number;
    y: number;
}

const CompCanvas: FunctionalComponent<Props> = (
    {
        contentLayout,
        dimmed = false,
        dragPos = { x: 0, y: 0 },
        isDraggingPip = false,
        isMainDisplayCanvas = false,
        onClick,
        onMouseDown,
        onMouseLeave,
        onMouseOver,
        onMouseUp,
        onPipClick,
        showCursor,
        tween,
        setFlip,
    }: Props,
    ref: any,
) => {
    const {
        activeItem,
        activeItemDisplay,
        focusFlipActive,
        focusFlipAnimationDone,
        focusFlipped,
        isFit,
        isInverted,
        orientation,
        pipPos,
        pipShape,
        sourceLoaded,
        stageScale,
        userCamera,
    } = useContext(RecordAppContext);
    const [cursorIsShowing, setCursorIsShowing] = useState<boolean>(false);
    const [cursorPos, setCursorPos] = useState<Coords>({ x: 0, y: 0 });
    const [downPos, setDownPos] = useState<Coords>({ x: 0, y: 0 });
    const canvasWidth =
        orientation === STAGE_ORIENTATION.PORTRAIT ? 1080 : 1920;
    const canvasHeight =
        orientation === STAGE_ORIENTATION.PORTRAIT ? 1920 : 1080;
    const bothViewsActive =
        contentLayout !== CONTENT_LAYOUT.SOURCE &&
        contentLayout !== CONTENT_LAYOUT.CAMERA;
    const isGif =
        activeItem &&
        activeItem.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
        isGifFile((activeItem.item as FileType).file);

    const baseUserCameraLayerDrawProps =
        userCamera &&
        getLayoutDrawProps({
            canvasWidth,
            canvasHeight,
            source: userCamera,
            isUserCamera: true,
            contentLayout: sourceLoaded ? contentLayout : CONTENT_LAYOUT.CAMERA,
            pipPos,
            pipShape,
            isFit,
        });
    const pipLayerPath = useRef<PipLayerPath>({
        path: new Path2D(),
        x: 0,
        y: 0,
    });
    const standardCursorIcon = new Image();
    const baseSourceLayerDrawProps =
        activeItemDisplay &&
        getLayoutDrawProps({
            canvasWidth,
            canvasHeight,
            source: activeItemDisplay,
            isUserCamera: false,
            contentLayout,
            pipPos,
            pipShape,
            isFit,
        });
    const sourceBackgroundLayerDrawProps =
        activeItemDisplay &&
        getBackgroundDrawProps({
            canvasWidth,
            canvasHeight,
            source: activeItemDisplay,
            isUserCamera: false,
            contentLayout,
            pipPos,
            pipShape,
            isFit: false,
        });
    const userCameraLayerBackgroundDrawProps =
        userCamera &&
        getBackgroundDrawProps({
            canvasWidth,
            canvasHeight,
            source: userCamera,
            isUserCamera: false,
            contentLayout,
            pipPos,
            pipShape,
            isFit: false,
        });
    const invertedUserCameraLayerDrawProps =
        userCamera &&
        getLayoutDrawProps({
            canvasWidth,
            canvasHeight,
            source: userCamera,
            isUserCamera: false,
            contentLayout,
            pipPos,
            pipShape,
            isFit,
        });
    const invertedSourceLayerDrawProps =
        activeItemDisplay &&
        getLayoutDrawProps({
            canvasWidth,
            canvasHeight,
            source: activeItemDisplay,
            isUserCamera: true,
            contentLayout,
            pipPos,
            pipShape,
            isFit,
        });
    const [userCameraLayerDrawProps, setUserCameraLayerDrawProps] = useState<
        CanvasDrawProps | undefined
    >(baseUserCameraLayerDrawProps);
    const [sourceLayerDrawProps, setSourceLayerDrawProps] = useState<
        CanvasDrawProps | undefined
    >(baseSourceLayerDrawProps);
    const [flipped, setFlipped] = useState<boolean>(false);

    standardCursorIcon.src =
        '../../../../assets/images/standard-cursor-icon.svg';

    useEffect(() => {
        setUserCameraLayerDrawProps(baseUserCameraLayerDrawProps);
        setSourceLayerDrawProps(baseSourceLayerDrawProps);
        setFlipped(false);
    }, [
        activeItem,
        activeItemDisplay,
        contentLayout,
        isFit,
        isInverted,
        sourceLoaded,
        orientation,
        pipPos,
        pipShape,
    ]);

    useEffect(() => {
        if (
            setFlip &&
            activeItemDisplay &&
            userCamera &&
            isMainDisplayCanvas &&
            activeItem
        ) {
            setFlip(flip);
        }
    }, [
        activeItem,
        activeItemDisplay,
        contentLayout,
        focusFlipped,
        isFit,
        isInverted,
        orientation,
        pipPos,
        pipShape,
        userCamera,
    ]);

    function flip() {
        if (!isMainDisplayCanvas) {
            return;
        }
        if (!isInverted) {
            startUserCameraLayerAnimation();
            startSourceLayerAnimation();
        }
    }

    function startUserCameraLayerAnimation() {
        if (!userCamera || isInverted) {
            return;
        }

        const fullscreenProps = getLayoutDrawProps({
            canvasWidth,
            canvasHeight,
            source: userCamera,
            isUserCamera: true,
            contentLayout: CONTENT_LAYOUT.CAMERA,
            pipPos,
            pipShape,
            isFit,
        });

        new TWEEN.Tween(
            flipped
                ? { ...fullscreenProps }
                : { ...baseUserCameraLayerDrawProps },
        )
            .to(
                flipped
                    ? { ...baseUserCameraLayerDrawProps }
                    : { ...fullscreenProps },
                FREEZE_TWEEN_TIME,
            )
            .easing(TWEEN.Easing.Quintic.InOut)
            .onUpdate(function (props, t) {
                if (t >= 0.5) {
                    setFlipped(!flipped);
                }
                // @ts-ignore
                setUserCameraLayerDrawProps(props);
            })
            .onComplete(focusFlipAnimationDone)
            .start();
    }

    function getSourcePipPos() {
        switch (contentLayout) {
            case CONTENT_LAYOUT.CIRCLE:
                return pipPos;
                break;
            case CONTENT_LAYOUT.BOTTOM:
                return PIP_POS.TOP_LEFT;
                break;
            case CONTENT_LAYOUT.LEFT:
                return PIP_POS.BOTTOM_RIGHT;
                break;
            case CONTENT_LAYOUT.TOP:
            case CONTENT_LAYOUT.RIGHT:
            default:
                return PIP_POS.BOTTOM_LEFT;
                break;
        }
    }

    function startSourceLayerAnimation() {
        if (!activeItemDisplay || isInverted) {
            return;
        }

        const sourceAspectRatio = getSourceAspectRatio(activeItemDisplay);

        const pipProps = getLayoutDrawProps({
            canvasWidth,
            canvasHeight,
            source: activeItemDisplay,
            isUserCamera: true,
            contentLayout: CONTENT_LAYOUT.CIRCLE,
            pipPos: getSourcePipPos(),
            pipShape:
                sourceAspectRatio < 1
                    ? PIP_SHAPE.PORTRAITISH
                    : sourceAspectRatio > 1
                    ? PIP_SHAPE.LANDSCAPEISH
                    : PIP_SHAPE.CIRCLE,
            isFit: false,
        });
        const layoutProps = baseSourceLayerDrawProps;

        new TWEEN.Tween(flipped ? { ...pipProps } : { ...layoutProps })
            .to(
                flipped ? { ...layoutProps } : { ...pipProps },
                FREEZE_TWEEN_TIME,
            )
            .easing(TWEEN.Easing.Quintic.InOut)
            .onUpdate(function (props) {
                // @ts-ignore
                setSourceLayerDrawProps(props);
            })
            .start();
    }

    function getUserCameraLayerProps() {
        if (isInverted && contentLayout === CONTENT_LAYOUT.CIRCLE) {
            return invertedUserCameraLayerDrawProps;
        }

        if (userCameraLayerDrawProps) {
            return userCameraLayerDrawProps;
        }

        return baseUserCameraLayerDrawProps;
    }

    function getSourceLayerProps() {
        if (isInverted && contentLayout === CONTENT_LAYOUT.CIRCLE) {
            return invertedSourceLayerDrawProps;
        }

        if (sourceLayerDrawProps) {
            return sourceLayerDrawProps;
        }

        return baseSourceLayerDrawProps;
    }

    function drawUserCameraLayer({ source, ctx, drawProps }: DrawProps) {
        if (!drawProps) {
            return;
        }
        const { sx, sy, sw, sh, dx, dy, dw, dh, radius } = drawProps;
        if (userCamera && dw > 0 && dh > 0) {
            const xPos =
                isDraggingPip &&
                (!isInverted || contentLayout !== CONTENT_LAYOUT.CIRCLE)
                    ? dragPos.x / stageScale - downPos.x
                    : dx;
            const yPos =
                isDraggingPip &&
                (!isInverted || contentLayout !== CONTENT_LAYOUT.CIRCLE)
                    ? dragPos.y / stageScale - downPos.y
                    : dy;
            ctx.save();
            ctx.beginPath();
            ctx.roundRect(xPos, yPos, dw, dh, radius);
            ctx.clip();
            ctx.translate(xPos + dw / 2, yPos);
            ctx.scale(-1, 1);
            ctx.drawImage(userCamera, sx, sy, sw, sh, -dw / 2, 0, dw, dh);
            ctx.restore();
            if (isMainDisplayCanvas) {
                if (!isInverted || contentLayout !== CONTENT_LAYOUT.CIRCLE) {
                    pipLayerPath.current.path = new Path2D();
                    pipLayerPath.current.path.roundRect(dx, dy, dw, dh, radius);
                    pipLayerPath.current.x = xPos;
                    pipLayerPath.current.y = yPos;
                }
            }
        }
    }

    function drawSourceLayer({ source, ctx, drawProps }: DrawProps) {
        if (!drawProps) {
            return;
        }
        const { sx, sy, sw, sh, dx, dy, dw, dh, radius } = drawProps;
        if (activeItemDisplay && dw > 0 && dh > 0) {
            const xPos =
                isDraggingPip &&
                isInverted &&
                contentLayout === CONTENT_LAYOUT.CIRCLE
                    ? dragPos.x / stageScale - downPos.x
                    : dx;
            const yPos =
                isDraggingPip &&
                isInverted &&
                contentLayout === CONTENT_LAYOUT.CIRCLE
                    ? dragPos.y / stageScale - downPos.y
                    : dy;
            ctx.save();
            if (isInverted || focusFlipActive) {
                ctx.beginPath();
                ctx.roundRect(xPos, yPos, dw, dh, radius);
                ctx.clip();

                if (contentLayout === CONTENT_LAYOUT.CIRCLE) {
                    pipLayerPath.current.path = new Path2D();
                    pipLayerPath.current.path.roundRect(
                        xPos,
                        yPos,
                        dw,
                        dh,
                        radius,
                    );
                    pipLayerPath.current.x = xPos;
                    pipLayerPath.current.y = yPos;
                }
            }
            ctx.drawImage(
                activeItemDisplay,
                sx,
                sy,
                sw,
                sh,
                xPos,
                yPos,
                dw,
                dh,
            );
            ctx.restore();
        } else {
            console.log('no active item display');
        }
    }

    function drawBackgroundLayer({ source, ctx, drawProps }: DrawProps) {
        if (!drawProps) {
            return;
        }
        const { sx, sy, sw, sh, dx, dy, dw, dh } = drawProps;
        if (source && dw > 0 && dh > 0) {
            ctx.save();
            if (isInverted) {
                ctx.translate(dw, dy);
                ctx.scale(-1, 1);
            }
            ctx.filter = 'blur(12px) brightness(44%) contrast(120%)';
            ctx.beginPath();
            const region = new Path2D();
            region.rect(dx, dy, dw, dh);
            ctx.clip(region);
            ctx.drawImage(source, sx, sy, sw, sh, dx, dy, dw, dh);
            ctx.restore();
        }
    }

    function drawCursor({ ctx }: { ctx: CanvasRenderingContext2D }) {
        ctx.save();
        ctx.drawImage(
            standardCursorIcon,
            cursorPos.x,
            cursorPos.y,
            standardCursorIcon.naturalWidth * 1.5,
            standardCursorIcon.naturalHeight * 1.5,
        );
        ctx.restore();
    }

    function drawCompCanvas(ctx: CanvasRenderingContext2D | null) {
        if (!ctx) {
            return;
        }

        if (isGif && activeItemDisplay) {
            if (
                activeItemDisplay.width === 0 ||
                activeItemDisplay.height === 0
            ) {
                return;
            }
        }

        const canvasPath = new Path2D();
        canvasPath.rect(0, 0, ctx.canvas.width, ctx.canvas.height);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        const userCameraLayerProps = getUserCameraLayerProps();
        const sourceLayerProps = getSourceLayerProps();

        if (bothViewsActive) {
            if (isInverted && contentLayout === CONTENT_LAYOUT.CIRCLE) {
                drawBackgroundLayer({
                    source: userCamera,
                    ctx,
                    drawProps: userCameraLayerBackgroundDrawProps,
                });
                drawUserCameraLayer({
                    ctx,
                    drawProps: userCameraLayerProps,
                });
                drawSourceLayer({
                    ctx,
                    drawProps: sourceLayerProps,
                });
            } else {
                if (flipped) {
                    drawBackgroundLayer({
                        source: activeItemDisplay,
                        ctx,
                        drawProps: userCameraLayerBackgroundDrawProps,
                    });
                    drawUserCameraLayer({
                        ctx,
                        drawProps: userCameraLayerProps,
                    });
                    drawSourceLayer({
                        ctx,
                        drawProps: sourceLayerProps,
                    });
                } else {
                    drawBackgroundLayer({
                        source: activeItemDisplay,
                        ctx,
                        drawProps: sourceBackgroundLayerDrawProps,
                    });
                    drawSourceLayer({
                        ctx,
                        drawProps: sourceLayerProps,
                    });
                    drawUserCameraLayer({
                        ctx,
                        drawProps: userCameraLayerProps,
                    });
                }
            }
        } else {
            if (contentLayout === CONTENT_LAYOUT.SOURCE) {
                drawBackgroundLayer({
                    source: activeItemDisplay,
                    ctx,
                    drawProps: sourceBackgroundLayerDrawProps,
                });
                drawSourceLayer({
                    ctx,
                    drawProps: sourceLayerProps,
                });
            } else if (contentLayout === CONTENT_LAYOUT.CAMERA) {
                drawBackgroundLayer({
                    source: userCamera,
                    ctx,
                    drawProps: userCameraLayerBackgroundDrawProps,
                });
                drawUserCameraLayer({
                    ctx,
                    drawProps: userCameraLayerProps,
                });
            }
        }

        if (cursorIsShowing && isMainDisplayCanvas) {
            drawCursor({ ctx });
        }
    }

    function handleMouseOver({ ctx, event }: CanvasClickEvent) {
        // if (!showCursor) {
        //     return;
        // }
        const { offsetX: x, offsetY: y } = event;
        const xPos = x / stageScale;
        const yPos = y / stageScale;
        if (showCursor) {
            setCursorIsShowing(true);
            setCursorPos({ x: xPos, y: yPos });
        }
        // console.log('over mouse', ctx);
        if (ctx) {
            if (ctx.isPointInPath(pipLayerPath.current.path, xPos, yPos)) {
                onMouseOver &&
                    onMouseOver({
                        event,
                        area: focusFlipped
                            ? HOVERING_AREA.SOURCE
                            : HOVERING_AREA.PIP,
                    });
            } else {
                if (
                    x <= 0 ||
                    x >= ctx.canvas.width * stageScale ||
                    y <= 0 ||
                    y >= ctx.canvas.height
                ) {
                    // It has left the canvas area
                    onMouseOver &&
                        onMouseOver({ event, area: HOVERING_AREA.NONE });
                } else {
                    onMouseOver &&
                        onMouseOver({ event, area: HOVERING_AREA.SOURCE });
                }
            }
        } else {
            // if (x)
            // onMouseOver && onMouseOver({ event, area: HOVERING_AREA.NONE });
        }
        // console.log('mouse over canvas', e);
    }

    function handleMouseLeave({ ctx, event }: CanvasClickEvent) {
        handleMouseOver({ event, ctx });
        if (!showCursor) {
            return;
        }
        setCursorIsShowing(false);
    }

    const canvasClasses = buildClasses({
        [style.dimmed]: dimmed,
    });

    return (
        <Canvas
            ref={ref}
            className={canvasClasses}
            draw={drawCompCanvas}
            tween={tween}
            onMouseDown={({ ctx, event }: CanvasClickEvent) => {
                if (!ctx || !isMainDisplayCanvas || focusFlipped) {
                    return;
                }
                const { offsetX: x, offsetY: y } = event;
                const isPipClick = ctx.isPointInPath(
                    pipLayerPath.current.path,
                    x / stageScale,
                    y / stageScale,
                );
                if (isPipClick) {
                    setDownPos({
                        x: x / stageScale - pipLayerPath.current.x,
                        y: y / stageScale - pipLayerPath.current.y,
                    });
                    onMouseDown && onMouseDown({ event, x, y });
                }
            }}
            onMouseUp={onMouseUp}
            onMouseLeave={handleMouseLeave}
            onMouseOver={handleMouseOver}
        />
    );
};

export default forwardRef(CompCanvas);
