/*  EVERYTHING RELATED TO HOME NEEDS TO BE SERIOUSLY REFACTORED.
    It was copied over from /cloud in favor of development speed.
    Please refactor!
*/

// from packages
import { createContext, FunctionalComponent, h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { route, Router } from 'preact-router';

// constants, enums, types
import { RECORDING_STATE } from './Record/enums';

// utils
import { buildClasses } from '../utils';
import { clearCookie, getIdFromCookie, storeCookie } from '../utils/auth';

// components
import { loadGoogleScripts } from './googleClient';
import Header from './header';
import Home from './Home/index';
import Record from './Record/index';
import Login from '../routes/login';
// TODO: clean all the Home stuff up
import ToastNotification, {
    ToastMessage,
} from './Home/components/toastNotification';
import Lightbox, {
    LightboxState,
    LightboxKind,
} from './Home/components/lightbox';

// assets

//styles
import style from './style.scss';

interface AppContext {
    isRecording: boolean;
}

const freshAndCleanState = {
    isRecording: true,
};

export const RodeoAppContext = createContext<AppContext>(freshAndCleanState);

const App: FunctionalComponent = () => {
    const [gapiLoaded, setGapiLoaded] = useState<boolean>(false);
    const [googleAuth, setGoogleAuth] = useState<any>();
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>(getIdFromCookie() || '');
    const isLoggedIn = !!userId;
    const [toast, setToast] = useState<ToastMessage | null>();
    const [lightboxState, setLightboxState] = useState<LightboxState>({
        open: false,
        contents: null,
    });
    const toastRef = useRef(toast);
    toastRef.current = toast;

    useEffect(() => {
        if (!gapiLoaded) {
            loadGoogleScripts(() => {
                setGapiLoaded(true);
                if (window.gapi) {
                    window.gapi.load('client:auth2', async () => {
                        let googleUserId: string;
                        try {
                            const _googleAuth = await window.gapi.auth2.init({
                                client_id: APP_CREDS.web.client_id,
                                scope: 'https://www.googleapis.com/auth/youtube',
                                prompt: 'select_account',
                            });
                            setGoogleAuth(_googleAuth);
                            if (_googleAuth.isSignedIn.get()) {
                                googleUserId = _googleAuth.currentUser
                                    .get()
                                    .getId();
                                setUserId(googleUserId);
                                // Some type of redux action?
                            } else {
                                route('/login');
                            }
                        } catch (e) {
                            console.log('auth did not work');
                            console.log(e);
                        }
                    });
                }
                // TODO: switch to GIS
                // if (window.google) {
                //     console.log('loaded!');
                //     initClient();
                // }
            });
        }
    }, []);

    // START Home stuff
    useEffect(() => {
        if (toast?.message) {
            const timer = setTimeout(
                (type: string) => {
                    if (
                        toastRef.current &&
                        toastRef.current.type === 'error' &&
                        type === 'error'
                    ) {
                        setToast(undefined);
                    }
                },
                5000,
                toast.type,
            );

            // @ts-ignore
            timer();
            return clearTimeout(timer);
        }
    }, [toast]);

    function handleSetToastMessage(msg: ToastMessage | null) {
        setToast(msg);
    }

    function handleLightboxOpen(contents?: JSX.Element, kind?: LightboxKind) {
        if (contents) {
            setLightboxState({ contents, open: true, kind });
        } else {
            handleLightboxClose();
        }
    }

    function handleLightboxClose() {
        setLightboxState({ open: false, contents: null });
    }

    // END Home stuff

    function handleLoggedIn(user: any) {
        setUserId(user.get().getId());
        storeCookie({ userId: user.get().getId() });
        route('/home');
    }

    function handleLoggedOut() {
        clearCookie({ userId });
        setUserId('');
        route('/login');
    }

    async function handleRoute(e: any) {
        const idValue = getIdFromCookie();
        switch (e.url) {
            case '/home':
            case '/record':
                if (!idValue && !userId) {
                    // @ts-ignore
                    document.startViewTransition(() => {
                        route('/login');
                    });
                }
                break;
            case '/login':
            case '/':
                if (idValue || isLoggedIn) {
                    // no idea why this doesn't work
                    // @ts-ignore
                    // document.startViewTransition(() => {
                    // route('/home', true);
                    break;
                    // });
                }
                break;
        }
    }

    function handleChangeRecordingState({ state }: { state: RECORDING_STATE }) {
        setIsRecording(
            state == RECORDING_STATE.RECORDING ||
                state === RECORDING_STATE.PAUSED ||
                state === RECORDING_STATE.PREVIEW,
        );
    }

    const rodeoAppContextValue = {
        isRecording,
    };

    const mainRodeoClasses = buildClasses({
        [style.rodeo]: true,
        [style.recording]: isRecording,
        [style['logged-in']]: isLoggedIn,
    });

    return (
        <RodeoAppContext.Provider value={rodeoAppContextValue}>
            <div class={mainRodeoClasses}>
                {isLoggedIn && (
                    <Header
                        inRecordingState={isRecording}
                        onLoggedOut={handleLoggedOut}
                        googleAuth={googleAuth}
                    />
                )}
                <div class={style['rodeo-content']}>
                    <Router onChange={handleRoute}>
                        <Home
                            path="/home"
                            isLoggedIn={isLoggedIn}
                            userId={userId}
                            setToastMessage={handleSetToastMessage}
                            onLightboxOpen={handleLightboxOpen}
                            lightboxOpen={lightboxState.open}
                            lightboxKind={lightboxState.kind}
                        />
                        <Record
                            path="/record"
                            isLoggedIn={isLoggedIn}
                            userId={userId}
                            handleChangeRecordingState={
                                handleChangeRecordingState
                            }
                        />
                        <Login
                            path="/login"
                            onLoggedIn={handleLoggedIn}
                            googleAuth={googleAuth}
                            default
                        />
                    </Router>
                </div>
                {toast && (
                    <ToastNotification
                        message={toast.message}
                        type={toast.type}
                    />
                )}
                {lightboxState.open && lightboxState.contents && (
                    <Lightbox
                        contents={lightboxState.contents}
                        onClose={handleLightboxClose}
                    />
                )}
                <div id="ytplayer"></div>
            </div>
        </RodeoAppContext.Provider>
    );
};

export default App;
