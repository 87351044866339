// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';

// constants, enums, types

// utils
import { getMedia } from '../../../../utils/mediaDevices';
// import { buildClasses } from '../../utils';
// import AudioEngine from '../../../../utils/audioEngine';

// components
import { RecordAppContext } from '../../index';

// assets
// import MicrophoneIcon from '../../../../assets/images/microphone-icon.svg';

//styles
import style from './style.scss';

interface Props {
    // stream?: Promise<MediaStream | undefined>;
}

const AudioMeter: FunctionalComponent<Props> = ({}: Props) => {
    const { audioEngine, createAudioEngine, currentMicDevice } =
        useContext(RecordAppContext);
    const [volume, setVolume] = useState<number>(0);
    const [stream, setStream] = useState<MediaStream>();

    useEffect(() => {
        const getStream = async () => {
            const micStream = await getMedia({
                audioSourceId: currentMicDevice!.deviceId,
            });
            setStream(micStream);
        };

        if (currentMicDevice) {
            getStream();
        }
    }, [currentMicDevice]);

    useEffect(() => {
        if (audioEngine && stream) {
            monitorAudioLevels();
        }

        if (!audioEngine) {
            createAudioEngine();
        }
    }, [audioEngine, stream]);

    function monitorAudioLevels() {
        audioEngine!.getMicrophoneVolume({
            stream,
            onUpdate: setVolume,
        });
    }

    return (
        <div class={style['audio-meter-container']}>
            <div class={style['audio-meter']}>
                <span
                    class={`${style['audio-meter-dot']} ${
                        style['audio-meter-dot-safe']
                    } ${volume > 5 ? style['audio-meter-dot-full'] : ''}`}
                ></span>
                <span
                    class={`${style['audio-meter-dot']} ${
                        style['audio-meter-dot-safe']
                    } ${volume > 20 ? style['audio-meter-dot-full'] : ''}`}
                ></span>
                <span
                    class={`${style['audio-meter-dot']} ${
                        style['audio-meter-dot-safe']
                    } ${volume > 40 ? style['audio-meter-dot-full'] : ''}`}
                ></span>
                <span
                    class={`${style['audio-meter-dot']} ${
                        style['audio-meter-dot-warn']
                    } ${volume > 75 ? style['audio-meter-dot-full'] : ''}`}
                ></span>
                <span
                    class={`${style['audio-meter-dot']} ${
                        style['audio-meter-dot-high']
                    } ${volume > 90 ? style['audio-meter-dot-full'] : ''}`}
                ></span>
            </div>
        </div>
    );
};

export default AudioMeter;
