import { FunctionalComponent, h, JSX } from 'preact';
import style from './style.scss';

export interface ToastMessage {
    message: string | JSX.Element;
    type: 'success' | 'warning' | 'error' | 'info';
}

const ToastNotification: FunctionalComponent<ToastMessage> = ({
    message,
    type,
}: ToastMessage) => {
    return (
        <div
            class={`${style['toast-notification-container']} ${
                style[`toast-notification-${type}`]
            }`}
        >
            {message}
        </div>
    );
};

export default ToastNotification;
