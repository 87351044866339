import { FunctionalComponent, h, Fragment, JSX } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import AudioPreview from '../audioPreview';
import ImagePreview from '../imagePreview';
import { LightboxKind } from '../lightbox';
import style from './style.scss';

interface Props {
    type: 'audio' | 'images' | 'GIF';
    files: DBFile[];
    onOpenFolder: (contents: JSX.Element, kind: LightboxKind) => void;
    onDeleteConfirm: (
        blobName: string,
        displayName: string,
        fileType: FileType,
        onDeleteSuccess: () => void,
    ) => void;
    onDownloadStarted: () => void;
    onDownloadCompleted: () => void;
    onDownloadError: () => void;
    downloadInProgress: boolean;
    lightboxOpen: boolean;
    lightboxKind?: LightboxKind;
    isMobileDevice: boolean;
    onToggleMobileAction: (
        file: DBFile,
        fileType: FileType,
        topPos: number,
    ) => void;
}

const SmartFolder: FunctionalComponent<Props> = ({
    type,
    files,
    onOpenFolder,
    onDeleteConfirm,
    downloadInProgress,
    onDownloadStarted,
    onDownloadCompleted,
    onDownloadError,
    lightboxOpen,
    lightboxKind,
    isMobileDevice,
    onToggleMobileAction,
}: Props) => {
    const [previewImages, setPreviewImages] = useState<DBFile[]>([]);

    useEffect(() => {
        if (lightboxOpen && lightboxKind === `${type} folder`) {
            if (files.length > 0) {
                handleLightboxOpen();
            }
        }

        if (type === 'images' || type === 'GIF') {
            setPreviewImages(files.slice(0, files.length > 4 ? 3 : 4));
        }
    }, [files]);

    function renderFileDetail(file: DBFile) {
        return (
            <li key={file.blobName} class={style['folder-item']}>
                {type === 'audio' && (
                    <AudioPreview
                        file={file}
                        onDeleteConfirm={onDeleteConfirm}
                        downloadInProgress={downloadInProgress}
                        onDownloadStarted={onDownloadStarted}
                        onDownloadCompleted={onDownloadCompleted}
                        onDownloadError={onDownloadError}
                    />
                )}
                {(type === 'images' || type === 'GIF') && (
                    <ImagePreview
                        file={file}
                        onDeleteConfirm={onDeleteConfirm}
                        downloadInProgress={downloadInProgress}
                        onDownloadStarted={onDownloadStarted}
                        onDownloadCompleted={onDownloadCompleted}
                        onDownloadError={onDownloadError}
                        isMobileDevice={isMobileDevice}
                        onToggleMobileAction={onToggleMobileAction}
                    />
                )}
            </li>
        );
    }

    function handleLightboxOpen() {
        onOpenFolder(
            <ul
                class={`${style['folder-item-list']} ${
                    style[`${type}-folder`]
                }`}
            >
                {files.map(renderFileDetail)}
            </ul>,
            `${type} folder`,
        );
    }

    function renderFolderPreview() {
        switch (type) {
            case 'audio':
                return (
                    <img
                        src={`../../../../assets/images//${type}-folder.png`}
                        alt={`${type} folder`}
                    />
                );
            case 'images':
                return (
                    <div class={style['image-preview-container']}>
                        {previewImages.map(file => {
                            return (
                                <div class={style['image-preview-item']}>
                                    <img src={file.thumbnailUrl} />
                                </div>
                            );
                        })}
                        {files.length > 4 && (
                            <div
                                class={`${style['image-preview-item']} ${style['image-remainder']}`}
                            >
                                +{files.length - 3}
                            </div>
                        )}
                    </div>
                );
            case 'GIF':
                return (
                    <div class={style['image-preview-container']}>
                        {previewImages.map(file => {
                            return (
                                <div class={style['image-preview-item']}>
                                    <img src={file.thumbnailUrl} />
                                </div>
                            );
                        })}
                        {files.length > 4 && (
                            <div
                                class={`${style['image-preview-item']} ${style['image-remainder']}`}
                            >
                                +{files.length - 3}
                            </div>
                        )}
                    </div>
                );
        }
    }

    return (
        <Fragment>
            <div
                class={style['smart-folder-container']}
                onClick={handleLightboxOpen}
            >
                {renderFolderPreview()}
                <span class={style['smart-folder-type']}>{type}</span>
            </div>
        </Fragment>
    );
};

export default SmartFolder;
