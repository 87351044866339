// from packages
import { FunctionalComponent, h, JSX } from 'preact';

// constants, enums, types

// utils

// components

// assets

//styles
import style from './style.scss';

interface Props {
	permission?: 'granted' | 'denied' | 'prompt';
	icon: JSX.Element;
}

const DevicePermission: FunctionalComponent<Props> = ({
	permission,
	icon,
}: Props) => {
	return (
		<div class={style['permission-container']}>
			{icon}
			<span
				class={`${style['indicator-badge']} ${
					permission && style[permission]
				}`}
			></span>
		</div>
	);
};

export default DevicePermission;
