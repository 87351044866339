// from packages
import { FunctionalComponent, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useContext, useEffect } from 'preact/hooks';

// constants, enums, types
import { PLAYLIST_ITEM_FILE_TYPE } from '../../enums';
import { FileType, PlaylistItemFile, ScreenStreamType } from '../../types';

// utils
import {
	// buildClasses,
	isImageFile,
	isVideoFile,
	// isAudioFile,
	isGifFile,
} from '../../../../utils';

// components
import AnimatedGif from '../animatedGif';
import { RecordAppContext } from '../../index';

// assets

//styles
import style from './style.scss';

interface Props {
	item: PlaylistItemFile | null;
	handleVideoEnded: () => void;
	onItemLoaded: () => void;
}

const ActiveItemDisplay: FunctionalComponent<Props> = (
	{ item, handleVideoEnded, onItemLoaded }: Props,
	ref: any,
) => {
	const { currentSpeakerDevice } = useContext(RecordAppContext);
	const isVideo =
		item &&
		item.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
		isVideoFile((item.item as FileType).file);
	const isGif =
		item &&
		item.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
		isGifFile((item.item as FileType).file);
	const isImage =
		item &&
		item.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
		isImageFile((item.item as FileType).file);
	const isScreenStream =
		item && item.itemType === PLAYLIST_ITEM_FILE_TYPE.SCREEN_STREAM;

	setVideoAudioOutputDevice();

	useEffect(() => {
		if (isVideo) {
			loadVideoFile();

			return () => {
				ref.current.removeEventListener('loadeddata', handleItemLoaded);
			};
		}
		if (isImage) {
			loadImageFile();
		}

		if (isScreenStream) {
			loadScreenStream();

			return () => {
				ref.current.removeEventListener('loadeddata', handleItemLoaded);
			};
		}
	}, [ref, item]);

	async function setVideoAudioOutputDevice() {
		if (
			isVideo &&
			currentSpeakerDevice &&
			ref.current &&
			'setSinkId' in ref.current
		) {
			await ref.current.setSinkId(currentSpeakerDevice.deviceId);
		}
	}

	function handleItemLoaded() {
		if (isVideo) {
			ref.current.currentTime = 0.001;
		} else if (isScreenStream) {
			ref.current.play();
		}

		onItemLoaded();
	}

	function loadVideoFile() {
		if (!ref.current || !item) {
			return null;
		}
		ref.current.src = (item.item as FileType).blob;
		ref.current.addEventListener('loadeddata', handleItemLoaded);
		ref.current.load();
	}

	function loadImageFile() {
		if (!ref.current || !item) {
			return null;
		}
		ref.current.onload = handleItemLoaded;
		ref.current.src = (item.item as FileType).blob;
	}

	function loadScreenStream() {
		if (ref.current && item) {
			ref.current.srcObject = (item.item as ScreenStreamType).stream;
			ref.current.addEventListener('loadeddata', handleItemLoaded);
			ref.current.load();
		}
	}

	if (isVideo) {
		return (
			<video
				class={style.media}
				key={(item.item as FileType).file.size}
				ref={ref}
				onEnded={handleVideoEnded}
				src=""
				hidden
			/>
		);
	}

	if (isGif) {
		return (
			<AnimatedGif
				className={style.media}
				ref={ref}
				key={(item.item as FileType).file.size}
				blob={(item.item as FileType).blob}
				onItemLoaded={onItemLoaded}
			/>
		);
	}

	if (isImage) {
		return (
			<img
				class={style.media}
				key={(item.item as FileType).file.size}
				ref={ref}
				src=""
				loading="eager"
			/>
		);
	}

	if (isScreenStream) {
		// const key = Math.random();

		return (
			<video
				class={style.media}
				// key={key}
				ref={ref}
				// src=""
				hidden
				autoPlay
				muted
			/>
		);
	}

	return null;
};

export default forwardRef(ActiveItemDisplay);
