import { FunctionalComponent, h, JSX } from 'preact';
import { buildClasses } from '../../../utils';
import Button from '../button';
import style from './style.scss';

interface Props {
    children: JSX.Element | JSX.Element[];
    fullTakeover: boolean;
    onClose: () => void;
    withOverlay: boolean;
    className?: string;
}

const Modal: FunctionalComponent<Props> = ({
    children,
    fullTakeover,
    onClose,
    withOverlay,
    className = '',
}: Props) => {
    const wrapperClasses = buildClasses({
        [style['modal-wrapper']]: true,
        [style.overlay]: withOverlay,
        [style.takeover]: fullTakeover,
    });
    const contentClasses = buildClasses({
        [style['modal-content']]: true,
        [className]: className !== '',
    });
    return (
        <div class={wrapperClasses}>
            <div class={contentClasses}>
                <Button
                    className={style['close-button']}
                    handleClick={onClose}
                    label="Close"
                    hideLabel={true}
                    buttonStyle="icon"
                    icon={<span class={style['close-icon']}>&times;</span>}
                />
                {children}
            </div>
        </div>
    );
};

export default Modal;
