import { FunctionalComponent, h, JSX } from 'preact';
import { buildClasses } from '../../../../utils';
import style from './style.scss';

export type InputType = 'text' | 'number' | 'textarea' | 'select';

interface Props {
    type?: InputType;
    className?: string;
    label: string;
    value: string;
    required?: boolean;
    maxCharacters?: number;
    placeholderText?: string;
    name: string;
    onChange: ({
        currentTarget,
    }: JSX.TargetedEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
        Event
    >) => void;
    options?: SelectOption[];
    icon?: JSX.Element;
}

interface SelectOption {
    value: string;
    label: string;
}

const FormInput: FunctionalComponent<Props> = ({
    type = 'text',
    className,
    label,
    value,
    required = false,
    maxCharacters,
    placeholderText,
    name,
    onChange,
    options,
    icon,
}: Props) => {
    const classes = buildClasses({
        [style['input-item']]: true,
        [`${className}`]: !!className,
    });

    function renderCharacterCount() {
        if (!maxCharacters) {
            return null;
        }
        const characterCountClasses = buildClasses({
            [style['input-character-count']]: true,
            [style['input-character-count-close']]:
                maxCharacters - value.length < 10 &&
                maxCharacters - value.length > 0,
            [style['input-character-count-over']]:
                maxCharacters - value.length < 0,
        });

        return (
            <span class={characterCountClasses}>
                {value.length}/{maxCharacters}
            </span>
        );
    }

    function renderField() {
        const inputClasses = buildClasses({
            [style['input-field']]: true,
            [style['with-char-count']]: !!maxCharacters,
            [style['with-icon']]: !!icon,
        });
        switch (type) {
            case 'textarea':
                return (
                    <textarea
                        class={inputClasses}
                        placeholder={placeholderText}
                        rows={5}
                        name={name}
                        value={value}
                        onInput={onChange}
                    ></textarea>
                );
                break;
            case 'select':
                if (!options) {
                    return null;
                }
                return (
                    <div class={inputClasses}>
                        {icon}
                        <select value={value} onChange={onChange} name={name}>
                            {options.map(({ value, label }: SelectOption) => (
                                <option value={value}>{label}</option>
                            ))}
                        </select>
                    </div>
                );
                break;
            default:
                return (
                    <input
                        type={type}
                        class={inputClasses}
                        placeholder={placeholderText}
                        name={name}
                        value={value}
                        onInput={onChange}
                    />
                );
        }
    }

    return (
        <div class={classes}>
            <label>
                {label}
                {required && ' (required)'}
                {renderField()}
                {maxCharacters && renderCharacterCount()}
            </label>
        </div>
    );
};

export default FormInput;
