export async function getLiveBroadcasts() {
    try {
        const broadcasts = await window.gapi.client.youtube.liveBroadcasts.list(
            {
                part: ['snippet,contentDetails,status'],
                mine: true,
            },
        );
        return (
            broadcasts.result?.items.filter(
                (item: any) => item.status.lifeCycleStatus === 'live',
            ) || []
        );
    } catch (e) {
        console.log('error getting broadcasts', e);
        // @ts-ignore
        return new Error(e);
    }
}

export async function getScheduledBroadcastsById(id: string) {
    try {
        const broadcasts = await window.gapi.client.youtube.liveBroadcasts.list(
            {
                part: ['snippet,contentDetails,status'],
                maxResults: 1,
                id: [id],
            },
        );
        return (
            broadcasts.result?.items.filter(
                (item: any) => item.status.lifeCycleStatus !== 'complete',
            ) || []
        );
    } catch (e) {
        console.log('error getting scheduled broadcasts', e);
        // @ts-ignore
        return new Error(e);
    }
}

export async function getAccessToken() {
    try {
        const accessTokenRes = await window.gapi.auth2
            .getAuthInstance()
            .currentUser.get()
            .reloadAuthResponse();
        return accessTokenRes.access_token;
    } catch (e) {
        console.log('error getting access token', e);
        // @ts-ignore
        return new Error(e);
    }
}

export async function insertStream(title: string) {
    try {
        const stream = await window.gapi.client.youtube.liveStreams.insert({
            part: ['snippet, cdn, contentDetails, status'],
            resource: {
                snippet: {
                    title,
                },
                cdn: {
                    frameRate: 'variable',
                    ingestionType: 'rtmp',
                    resolution: 'variable',
                },
            },
        });
        return stream.result;
    } catch (e) {
        console.log('error inserting stream', e);
        // @ts-ignore
        return new Error(e);
    }
}

export interface BroadcastContentDetails {
    monitorStream?: {
        enableMonitorStream: boolean;
        broadcastStreamDelayMs: number;
        embedHtml?: string;
    };
    enableDvr?: boolean;
    enableContentEncryption?: boolean;
    enableEmbed?: boolean;
    recordFromStart?: boolean;
    startWithSlate?: boolean;
    enableAutoStart?: boolean;
    enableAutoStop?: boolean;
    enableClosedCaptions?: boolean;
}

interface BroadcastSnippet {
    title: string;
    description: string;
    privacyStatus: 'public' | 'private' | 'unlisted';
}

export async function insertBroadcast({
    title,
    description,
    privacyStatus,
}: BroadcastSnippet) {
    try {
        const broadcast =
            await window.gapi.client.youtube.liveBroadcasts.insert({
                part: ['snippet', 'status', 'contentDetails'],
                resource: {
                    snippet: {
                        title,
                        scheduledStartTime: new Date(),
                        description,
                    },
                    status: {
                        privacyStatus: privacyStatus ? privacyStatus : 'public',
                    },
                    contentDetails: {
                        enableAutoStart: true,
                        enableAutoStop: true,
                        monitorStream: {
                            enableMonitorStream: false,
                        },
                    },
                },
            });
        return broadcast.result;
    } catch (e) {
        console.log('error inserting broadcast', e);
        // @ts-ignore
        return new Error(e);
    }
}

export async function updateBroadcast(
    id: string,
    { title, description, privacyStatus }: BroadcastSnippet,
    contentDetails: BroadcastContentDetails,
) {
    try {
        const broadcast =
            await window.gapi.client.youtube.liveBroadcasts.update({
                part: ['snippet', 'status', 'contentDetails'],
                resource: {
                    id,
                    snippet: {
                        title,
                        scheduledStartTime: new Date(),
                        description,
                    },
                    status: {
                        privacyStatus: privacyStatus ? privacyStatus : 'public',
                    },
                    contentDetails: {
                        ...contentDetails,
                        enableAutoStart: true,
                        enableAutoStop: true,
                        monitorStream: {
                            enableMonitorStream: false,
                        },
                    },
                },
            });
        return broadcast.result;
    } catch (e) {
        console.log('error updating broadcast', e);
        // @ts-ignore
        return new Error(e);
    }
}

export async function uploadThumbnail(id: string, thumbnailFile: File) {
    let accessToken;
    try {
        accessToken = await getAccessToken();
    } catch (e) {
        console.log('couldnt get access token in upload thumbnail');
        // @ts-ignore
        return new Error(e);
    }

    try {
        const res = await fetch(
            `https://www.googleapis.com/upload/youtube/v3/thumbnails/set?videoId=${id}&uploadType=media`,
            {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    'Content-Type': thumbnailFile.type,
                },
                body: thumbnailFile,
            },
        );

        return res;
    } catch (e) {
        console.log('error uploading thumbnail', e);
        // @ts-ignore
        return new Error(e);
    }
}

export async function bindLiveStream(broadcastId: string, streamId: string) {
    try {
        const params = {
            part: ['snippet', 'status'],
            id: broadcastId,
            streamId,
        };
        const result = await window.gapi.client.youtube.liveBroadcasts.bind(
            params,
        );
        return result;
    } catch (e) {
        console.log('error binding stream', e);
        // @ts-ignore
        return new Error(e);
    }
}

export async function deleteStream(id: string) {
    try {
        const res = await window.gapi.client.youtube.liveStreams.delete({
            id,
        });
        return res;
    } catch (e) {
        console.log('error deleting live Stream', e);
        // @ts-ignore
        return new Error(e);
    }
}

export async function deleteBroadcast(id: string) {
    try {
        const res = await window.gapi.client.youtube.liveBroadcasts.delete({
            id,
        });
        return res;
    } catch (e) {
        console.log('error deleting broadcast', e);
        // @ts-ignore
        return new Error(e);
    }
}
