import { FunctionalComponent, h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { buildClasses } from '../../../../utils';
import ActionButton from '../actionButton';
import style from './style.scss';

interface Props {
    itemType: 'video' | 'image' | 'audio';
    itemName: string;
    onCancel: () => void;
    onConfirm: () => void;
}

const DeleteConfirmation: FunctionalComponent<Props> = ({
    itemType,
    itemName,
    onCancel,
    onConfirm,
}: Props) => {
    const [closing, setClosing] = useState<boolean>(false);
    const lightboxRef = useRef<HTMLDivElement>(null);
    const didMount = useRef<boolean>(false);

    useEffect(() => {
        if (didMount.current) {
            lightboxRef.current?.addEventListener(
                'animationend',
                closeLightbox,
            );
        } else {
            didMount.current = true;
        }
    });

    function fadeOutLightbox() {
        setClosing(true);
    }

    function closeLightbox() {
        if (closing) {
            onCancel();
        }
    }

    function handleOverlayClick(event: Event) {
        const targetEl = event.target as HTMLElement;
        if (
            targetEl.classList.contains(style.lightbox) &&
            !targetEl.classList.contains(style['confirmation-container'])
        ) {
            fadeOutLightbox();
        }
    }

    const lightboxClasses = buildClasses({
        [style.lightbox]: true,
        [style.closing]: closing,
    });

    return (
        <div
            class={lightboxClasses}
            ref={lightboxRef}
            onClick={handleOverlayClick}
        >
            <div class={style['confirmation-container']}>
                <button
                    type="button"
                    class={`${style['close-icon']} ${style['lightbox-button']}`}
                    onClick={fadeOutLightbox}
                >
                    <svg
                        width="23"
                        height="23"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M21.753 1.247a1.5 1.5 0 0 1 0 2.121L13.621 11.5l8.132 8.132a1.5 1.5 0 1 1-2.121 2.121L11.5 13.621l-8.132 8.132a1.5 1.5 0 1 1-2.121-2.121L9.379 11.5 1.247 3.368a1.5 1.5 0 1 1 2.121-2.121L11.5 9.379l8.132-8.132a1.5 1.5 0 0 1 2.121 0Z"
                            fill="#FFF"
                            fill-rule="evenodd"
                        />
                    </svg>
                </button>
                <h3>Delete {itemType} file?</h3>
                <p>
                    Are you sure you want to delete{' '}
                    <span class={style['confirmation-item-name']}>
                        {itemName}
                    </span>{' '}
                    from Rodeo? This cannot be undone.
                </p>
                <div class={style['confirmation-footer']}>
                    <ActionButton
                        ui="text"
                        display="standard"
                        environment="dark"
                        action={fadeOutLightbox}
                        align="center"
                        type="normal"
                        text="No, cancel"
                    />
                    <ActionButton
                        ui="text"
                        display="standard"
                        environment="dark"
                        action={onConfirm}
                        align="center"
                        type="warning"
                        text="Yes, delete"
                    />
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirmation;
