// from packages
// import { ref, child, update } from 'firebase/database';
import { FunctionalComponent, h } from 'preact';
import { useContext, useEffect, useRef, useState } from 'preact/hooks';
import { route } from 'preact-router';

// constants, enums, types
import { URL_BASE, WEEK_DAYS } from '../../constants';
import { STAGE_ORIENTATION, WEB_REACTS_EVENTS } from '../../enums';

// utils
import { formatDateNum } from '../../../../utils';
import { logWebReactsEvent } from '../../../../utils/logger';
// import { db } from '../../../../utils/firebase';

// components
import { RecordAppContext } from '../../index';
import ProgressPill from '../progressPill';
import Button from '../../../common/button';
import RowGroup from '../../../common/rowGroup';

// assets
import CloudUploadIcon from '../../../../assets/images/cloud-upload-icon.svg';
import ComputerDownloadIcon from '../../../../assets/images/computer-download-icon.svg';

//styles
import style from './style.scss';

interface Props {
    url: string;
    // onDownloadProgress: (progess: number) => void;
}
const Download: FunctionalComponent<Props> = ({
    url,
}: // onDownloadProgress,
Props) => {
    const { orientation, userId, videoSessionId } =
        useContext(RecordAppContext);
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);
    // const [downloadDestination, setDownloadDestination] = useState<string>('');
    const [downloadLink, setDownloadLink] = useState<string>('');
    // const [downloadName, setDownloadName] = useState<string | undefined>('');
    const [downloadPercentComplete, setDownloadPercentComplete] =
        useState<number>(0);
    // const [estimatedTimeRemaining, setEstimatedTimeRemaining] =
    //     useState<number>(0); // TODO
    const estimatedTimeRemaining = 0;
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [linkClicked, setLinkClicked] = useState<boolean>(false);
    const [uploadPercentComplete, setUploadPercentComplete] =
        useState<number>(0);
    const downloadName = url ? url.split('/').at(-1) : '';

    // useEffect(() => {
    //     if (url) {
    //         console.log({ url });
    //         setDownloadName(url.split('/').at(-1));
    //     }
    // }, [url]);

    useEffect(() => {
        if (isDownloading) {
            downloadBlob();
        }
    }, [isDownloading]);

    useEffect(() => {
        if (isUploading) {
            // TODO
        }
    }, [isUploading]);

    useEffect(() => {
        if (downloadLink && downloadLinkRef.current) {
            downloadLinkRef.current.click();
            logWebReactsEvent({
                type: WEB_REACTS_EVENTS.DOWNLOADED_RECORDED_VIDEO,
                userId,
                videoSessionId,
            });
            setLinkClicked(true);
        }
    }, [downloadLink]);

    function downloadBlob() {
        try {
            // const response = await fetch(downloadDestination);
            // const blob = await response.blob();
            // setDownloadLink(URL.createObjectURL(blob));
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.onprogress = function (event) {
                if (event.lengthComputable) {
                    // var percentComplete = (event.loaded / event.total) * 100;
                    // setPercentComplete((event.loaded / event.total) * 100);
                    setDownloadPercentComplete(
                        (event.loaded / event.total) * 100,
                    );
                    // console.log('Download progress', percentComplete);
                }
            };
            xhr.onload = function (event) {
                if (xhr.status === 200) {
                    const blob = xhr.response;
                    // console.log('Download complete');
                    setDownloadLink(URL.createObjectURL(blob));
                } else {
                    console.log('Error on download', xhr.status);
                }
            };
            xhr.onerror = function (event) {
                console.log('XHR error', event);
            };
            xhr.send();
        } catch (e) {
            console.log('error getting the blob', e);
            logWebReactsEvent({
                type: WEB_REACTS_EVENTS.ERROR,
                userId,
                data: `Video download blob error: ${JSON.stringify(e)}`,
                videoSessionId,
            });
        }
    }

    async function uploadToCloud() {
        setIsUploading(true);
        try {
            const date = new Date();
            const fileName = `${userId}/Rodeo - ${formatDateNum(
                date.getMonth() + 1,
            )}-${formatDateNum(date.getDate())}-${`${date.getFullYear()}`.slice(
                -2,
            )} ${WEEK_DAYS[date.getDay()]} ${formatDateNum(
                date.getHours(),
            )} ${formatDateNum(date.getMinutes())} ${formatDateNum(
                date.getSeconds(),
            )} - ${videoSessionId}`;
            // console.log({ userId });
            const copyRes = await fetch(`${URL_BASE}/savetocloud`, {
                method: 'POST',
                // TODO: make these buckets constants
                body: `source_bucket_name=rodeo_web_experiments_storage&source_blob_name=${downloadName}&destination_bucket_name=stations_cloud_pal&destination_blob_name=${fileName}`,
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            setUploadPercentComplete(50);

            // console.log({ copyRes });
            if (copyRes.ok) {
                setUploadPercentComplete(100);
                logWebReactsEvent({
                    type: WEB_REACTS_EVENTS.SAVED_RECORDED_VIDEO,
                    userId,
                    videoSessionId,
                });
                route('/home');
                // // TODO: THIS IS BAD
                // setTimeout(async () => {
                //     const rtdbRes = await fetch(`${URL_BASE}/process`, {
                //         method: 'POST',
                //         body: `bucket_name=stations_cloud_pal&blob_name=${fileName}`,
                //         mode: 'cors',
                //         headers: {
                //             'Content-Type': 'application/x-www-form-urlencoded',
                //         },
                //     });
                //     console.log({ rtdbRes });
                // }, 4000);
            }
        } catch (e) {
            setIsUploading(false);

            console.log('Error copying video', e);
            logWebReactsEvent({
                type: WEB_REACTS_EVENTS.ERROR,
                userId,
                data: `Video copy error: ${JSON.stringify(e)}`,
                videoSessionId,
            });
        }
    }

    return (
        <RowGroup
            withGap={true}
            stack={
                orientation === STAGE_ORIENTATION.LANDSCAPE ? 'row' : 'column'
            }
        >
            {!isUploading && (
                <Button
                    buttonColor="outline"
                    buttonStyle="pill"
                    icon={<CloudUploadIcon />}
                    iconPosition="left"
                    label="My Rodeo media"
                    handleClick={uploadToCloud}
                    className={style['download-option-button']}
                    disabled={isDownloading && !linkClicked}
                />
            )}
            {isUploading && (
                <ProgressPill
                    estimatedTimeRemaining={estimatedTimeRemaining}
                    percentComplete={uploadPercentComplete}
                    status="Uploading..."
                    doneText="Saved to My Media"
                />
            )}
            {!isDownloading && (
                <Button
                    buttonColor="outline"
                    buttonStyle="pill"
                    icon={<ComputerDownloadIcon />}
                    iconPosition="left"
                    label="My computer"
                    handleClick={() => {
                        setIsDownloading(true);
                    }}
                    className={style['download-option-button']}
                />
            )}
            {isDownloading && (
                <ProgressPill
                    estimatedTimeRemaining={estimatedTimeRemaining}
                    percentComplete={downloadPercentComplete}
                    status="Downloading..."
                    doneText="Downloaded"
                />
            )}
            <a
                ref={downloadLinkRef}
                class={style.download}
                href={downloadLink}
                download={`Rodeo-reaction-${downloadName}`}
            >
                click here to manually download
            </a>
        </RowGroup>
    );
};
export default Download;
