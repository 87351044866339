// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

// constants, enums, types
import { CONTENT_LAYOUT, PLAYLIST_ITEM_FILE_TYPE } from '../../enums';
import { FileType } from '../../types';

// utils
import { isImageFile } from '../../../../utils';

// components
import Button from '../../../common/button';

// assets
import { RecordAppContext } from '../../index';
import FlipImageIcon from '../../../../assets/images/flip-image-icon.svg';
import UnflipImageIcon from '../../../../assets/images/unflip-image-icon.svg';
import NextIcon from '../../../../assets/images/next-icon.svg';
import PauseIconWhite from '../../../../assets/images/pause-icon-white.svg';
import PlayIconWhite from '../../../../assets/images/play-icon-white.svg';
import PrevIcon from '../../../../assets/images/prev-icon.svg';

//styles
import style from './style.scss';

interface Props {}

const MediaControls: FunctionalComponent<Props> = ({}: Props, ref: any) => {
    const {
        activeItem,
        contentLayout,
        focusFlipActive,
        focusFlipped,
        handleNext,
        handlePrev,
        handleTogglePlay,
        isPlaying,
        playlistItems,
    } = useContext(RecordAppContext);
    const isImage =
        activeItem &&
        activeItem.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
        isImageFile((activeItem.item as FileType).file);
    // const isVideo =
    //     activeItem &&
    //     activeItem.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
    //     isVideoFile((activeItem.item as FileType).file);
    // const isGif =
    //     activeItem &&
    //     activeItem.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
    //     isGifFile((activeItem.item as FileType).file);
    const isScreenStream =
        activeItem &&
        activeItem.itemType === PLAYLIST_ITEM_FILE_TYPE.SCREEN_STREAM;
    const canFlip =
        focusFlipActive &&
        contentLayout !== CONTENT_LAYOUT.SOURCE &&
        contentLayout !== CONTENT_LAYOUT.CAMERA;

    function handlePausePlayClick() {
        handleTogglePlay({ pausePlayClick: true });
    }

    return (
        <div class={style['controls-wrapper']}>
            <Button
                className={style['nav-control-button']}
                label="previous"
                iconPosition="full"
                icon={<PrevIcon />}
                hideLabel={true}
                handleClick={handlePrev}
                disabled={!activeItem || activeItem?.itemIndex === 0}
                buttonStyle="icon-circle"
                buttonColor="dark-translucent"
            />
            <Button
                className={style['action-control-button']}
                label={
                    isImage || isScreenStream
                        ? 'flip'
                        : isPlaying
                        ? 'pause'
                        : 'play'
                }
                iconPosition="full"
                icon={
                    isImage || isScreenStream ? (
                        focusFlipped ? (
                            <UnflipImageIcon />
                        ) : (
                            <FlipImageIcon />
                        )
                    ) : isPlaying ? (
                        <PauseIconWhite />
                    ) : (
                        <PlayIconWhite />
                    )
                }
                hideLabel={true}
                handleClick={handlePausePlayClick}
                buttonStyle="icon-circle"
                buttonColor="dark-translucent"
                disabled={isImage || isScreenStream ? !canFlip : false}
            />
            <Button
                className={style['nav-control-button']}
                label="next"
                iconPosition="full"
                icon={<NextIcon />}
                hideLabel={true}
                handleClick={handleNext}
                disabled={activeItem?.itemIndex === playlistItems.length}
                buttonStyle="icon-circle"
                buttonColor="dark-translucent"
            />
        </div>
    );
};

export default MediaControls;
