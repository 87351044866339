export interface UserDevices {
    [kind: string]: (InputDeviceInfo | MediaDeviceInfo)[];
}

interface MediaSources {
    audioSourceId?: string;
    videoSourceId?: string;
}

export const getMedia = async ({
    audioSourceId,
    videoSourceId,
}: MediaSources) => {
    const videoConstraints = {
        width: 1920,
        height: 1080,
        aspectRatio: 1.777777778,
    };

    const constraints = {
        audio: audioSourceId ? { deviceId: { exact: audioSourceId } } : true,
        video: videoSourceId
            ? { ...videoConstraints, deviceId: { exact: videoSourceId } }
            : videoConstraints,
    };
    return await navigator.mediaDevices.getUserMedia(constraints);
};

async function ensureMediaPermissions() {
    try {
        // const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCameraPermission =
            (await getAPIPermissionStatus({
                name: 'camera' as PermissionName,
            })) === 'granted';
        const hasMicrophonePermission =
            (await getAPIPermissionStatus({
                name: 'microphone' as PermissionName,
            })) === 'granted';
        if (!hasCameraPermission || !hasMicrophonePermission) {
            const mediaStream = await getMedia({});
            mediaStream.getTracks().forEach(track => {
                track.stop();
            });
        }
    } catch (e: unknown) {}
}

function getDevicesOfKind(
    deviceInfos: MediaDeviceInfo[],
    kind: 'audioinput' | 'audiooutput' | 'videoinput',
) {
    return deviceInfos.filter((deviceInfo: MediaDeviceInfo) => {
        return deviceInfo.kind === kind && deviceInfo.deviceId !== 'default';
    });
}

async function getDeviceList(): Promise<UserDevices | undefined> {
    try {
        const deviceInfos = await navigator.mediaDevices.enumerateDevices();
        navigator.mediaDevices.ondevicechange = getDeviceList;
        const devices = {
            audioinput: getDevicesOfKind(deviceInfos, 'audioinput'),
            audiooutput: getDevicesOfKind(deviceInfos, 'audiooutput'),
            videoinput: getDevicesOfKind(deviceInfos, 'videoinput'),
        };

        return devices;
    } catch (e) {
        console.log('error getting devices', e);
    }
}

export async function getUserDevices(): Promise<UserDevices | undefined> {
    try {
        await ensureMediaPermissions();
        const userDevices = await getDeviceList();
        return userDevices;
    } catch (e) {
        console.log('error here in the first part', e);
    }
}

export async function getAPIPermissionStatus({
    name,
}: {
    name: PermissionName;
}) {
    const permissionResult = await navigator.permissions.query({ name });
    return permissionResult.state;
}

export async function startCaptureScreen() {
    try {
        const captureStream =
            await window.navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: true,
            });
        return captureStream;
    } catch (e) {
        console.log('error: ', e);
    }
}
