import { FunctionalComponent, h } from 'preact';
import { buildClasses } from '../../../utils';
import style from './style.scss';

interface Props {
    align?: 'left' | 'right' | 'center';
    children: any;
    fullWidth?: boolean;
    stack?: 'row' | 'column';
    withGap?: boolean;
}

const RowGroup: FunctionalComponent<Props> = ({
    align = 'center',
    children,
    fullWidth = false,
    stack = 'row',
    withGap = false,
}: Props) => {
    const groupClasses = buildClasses({
        [style['row-group']]: true,
        [style[`align-${align}`]]: true,
        [style['with-gap']]: withGap,
        [style[stack]]: true,
        [style.full]: fullWidth,
    });

    return <div class={groupClasses}>{children}</div>;
};

export default RowGroup;
