import { getStorage } from 'firebase/storage';

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
// import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseApp = initializeApp({
	apiKey: 'AIzaSyBnIO9LmMetX0V8AClndjHCqzVDG6n0EDE',
	authDomain: 'stations-243022.firebaseapp.com',
	databaseURL: 'https://stations-243022.firebaseio.com',
	projectId: 'stations-243022',
	storageBucket: 'rodeo_web_experiments_storage',
	messagingSenderId: '733865144636',
	appId: '1:733865144636:web:0303f9ee80873711646e39',
	measurementId: 'G-6ENPL85PHH',
});

// const rodeoCloudFirebaseApp = initializeApp({
// 	apiKey: 'AIzaSyBnIO9LmMetX0V8AClndjHCqzVDG6n0EDE',
// 		authDomain: 'stations-243022.firebaseapp.com',
// 		databaseURL: 'https://stations-243022.firebaseio.com',
// 		projectId: 'stations-243022',
// 		storageBucket: 'stations_cloud_pal',
// 		messagingSenderId: '733865144636',
// 		appId: '1:733865144636:web:0303f9ee80873711646e39',
// 		measurementId: 'G-6ENPL85PHH',
// });

export const db = getDatabase(firebaseApp);
export const analyticsDb = getAnalytics(firebaseApp);
// export const firestoreDb = getFirestore(firebaseApp);
// export const rtdb = getDatabase(rodeoCloudfirebaseApp);

export const videoStorage = getStorage();
export const cloudVideoStorage = getStorage(undefined, 'stations_cloud_pal');
export const audioStorage = getStorage(undefined, 'rodeo_cloud_audio');
export const imageStorage = getStorage(undefined, 'rodeo_cloud_image');

export const superresStorage = getStorage(undefined, 'rodeo_cloud_comisr');
