import { FunctionalComponent, h } from 'preact';
import { buildClasses } from '../../../../utils';
import style from './style.scss';

interface Props {
    text?: string;
    center?: boolean;
}

const Loading: FunctionalComponent<Props> = ({
    text = 'Loading ...',
    center = false,
}: Props) => {
    const loaderClasses = buildClasses({
        [style.loading]: true,
        [style.center]: center,
    });

    const iconSrc = center
        ? '../../assets/images/big_loading_icon.png'
        : '../../assets/images/loading_icon.png';

    return (
        <div class={loaderClasses}>
            <img src={iconSrc} class={style['loading-icon']} alt="spinner" />
            <span class={style['loading-text']}>{text}</span>
        </div>
    );
};

export default Loading;
