// from packages
import { FunctionalComponent, h, JSX } from 'preact';
import { useContext, useState } from 'preact/hooks';

// constants, enums, types
import { PLAYLIST_ACTIONS } from '../../enums';

// utils
import { acceptedReactFileTypes, buildClasses } from '../../../../utils';

// components
import { RecordAppContext } from '../../index';

// assets
import BrowseComputerIcon from '../../../../assets/images/browse-computer-icon.svg';

//styles
import style from './style.scss';

interface Props {
	inline?: boolean;
}

const AddComputerMediaButton: FunctionalComponent<Props> = ({
	inline,
}: Props) => {
	const { playlistDispatch } = useContext(RecordAppContext);
	const [filePickerValue, setFilePickerValue] = useState<string>('');

	function addFiles({
		currentTarget: { files },
	}: JSX.TargetedEvent<HTMLInputElement, Event>) {
		if (files) {
			playlistDispatch({
				type: PLAYLIST_ACTIONS.ADD_FILES,
				payload: files,
			});
			setFilePickerValue('');
		}
	}

	const displayClasses = buildClasses({
		[style['add-media-button']]: true,
		[style.inline]: !!inline,
	});

	return (
		<label class={displayClasses}>
			<span class={style['label-text']}>
				<BrowseComputerIcon />
				<span class={style['label-text']}>Browse my computer</span>
			</span>
			<input
				type="file"
				multiple
				accept={acceptedReactFileTypes.join(', ')}
				onChange={addFiles}
				value={filePickerValue}
			/>
		</label>
	);
};

export default AddComputerMediaButton;
