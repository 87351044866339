// from packages
import { FunctionalComponent, h, JSX } from 'preact';
import { forwardRef } from 'preact/compat';
import { useContext, useEffect, useRef, useState } from 'preact/hooks';

// constants, enums, types
import { PLAYLIST_ITEM_FILE_TYPE, RECORDING_STATE } from '../../enums';
import { FileType, PlaylistItemFile, ScreenStreamType } from '../../types';

// utils
import {
    buildClasses,
    formatTime,
    isGifFile,
    isImageFile,
    isVideoFile,
} from '../../../../utils';

// components
// import GhostPlaylistItem from '../ghostPlaylistItem';
import Timeline from '../timeline';
import VideoStateIndicator from '../videoStateIndicator';
import VideoThumb from '../videoThumb';
import { RecordAppContext } from '../../index';

// assets
import PlayIcon from '../../../../assets/images/play-icon.svg';
import ScreenIcon from '../../../../assets/images/screen-icon.svg';

//styles
import style from './style.scss';

interface Props {
    item: PlaylistItemFile;
    handleClick: () => void;
    isActive: boolean;
    isFocussed: boolean;
}

// const libheif = require('libheif-js/wasm-bundle');

const PlaylistItem: FunctionalComponent<Props> = (
    { item, handleClick, isActive, isFocussed }: Props,
    ref: any,
) => {
    const {
        activeItemDisplay,
        handleDragPlaylistItemEnd,
        handleDragPlaylistItemStart,
        handleItemDrag,
        recordingState,
    } = useContext(RecordAppContext);
    const videoItemRef = useRef<HTMLVideoElement>(null);
    // const ghostItemRef = useRef<HTMLDivElement>(null);
    const isImage =
        item.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
        isImageFile((item.item as FileType).file);
    const isVideo =
        item.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
        isVideoFile((item.item as FileType).file);
    const isGif =
        item.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
        isGifFile((item.item as FileType).file);
    const isScreenStream =
        item.itemType === PLAYLIST_ITEM_FILE_TYPE.SCREEN_STREAM;
    const [itemDuration, setItemDuration] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const previewFrames = useRef<JSX.Element[]>();

    // useEffect(() => {
    //     return removeGhost;
    // }, []);

    useEffect(() => {
        if (videoItemRef.current) {
            if (isScreenStream) {
                videoItemRef.current.srcObject = (
                    item.item as ScreenStreamType
                ).stream;
                videoItemRef.current.addEventListener(
                    'play',
                    pauseScreenStream,
                );
                videoItemRef.current.load();
            } else if (isVideo) {
                videoItemRef.current.addEventListener(
                    'loadedmetadata',
                    setVideoMetadata,
                );
            }
        }

        return () => {
            if (videoItemRef.current) {
                videoItemRef.current.removeEventListener(
                    'play',
                    pauseScreenStream,
                );
                videoItemRef.current.removeEventListener(
                    'loadedmetadata',
                    setVideoMetadata,
                );
            }
        };
    }, [videoItemRef]);

    useEffect(() => {
        const getItemPreview = async () => {
            if (isScreenStream) {
                return;
            }
            let frames = [];
            for (let i = 1; i <= itemDuration; i = itemDuration / 4 + i) {
                frames.push(
                    <VideoThumb src={(item.item as FileType).blob} time={i} />,
                );
            }
            previewFrames.current = frames;

            setLoaded(true);
        };

        if (itemDuration > 0 && !loaded) {
            if (isVideo) {
                getItemPreview();
            } else {
                setLoaded(true);
            }
        }
    }, [itemDuration]);

    function setVideoMetadata() {
        setItemDuration(videoItemRef.current!.duration);
    }

    function pauseScreenStream() {
        if (videoItemRef.current) {
            videoItemRef.current.pause();
        }
    }

    function renderVideoFile() {
        if (loaded && isActive && activeItemDisplay) {
            return [
                <Timeline
                    activeItemDisplay={activeItemDisplay as HTMLVideoElement}
                    isPlaying={false}
                    onSeekTo={() => {}}
                    itemDuration={itemDuration}
                />,
                <div class={style['video-thumbs']}>
                    {previewFrames.current}
                </div>,
                <VideoStateIndicator />,
            ];
        } else {
            return (
                <video
                    ref={videoItemRef}
                    src={(item.item as FileType).blob}
                    // onDurationChange={() => {
                    //     console.log('duration change 1');
                    //     // console.log(videoItemRef.current.duration);
                    //     videoItemRef.current!.duration > 0 &&
                    //         setItemDuration(videoItemRef.current!.duration);
                    // }}
                />
            );
        }
    }

    function renderScreenPreview() {
        return <video ref={videoItemRef} autoPlay />;
    }

    function renderImageFile() {
        return <img src={(item.item as FileType).blob} />;
    }

    let content: JSX.Element | JSX.Element[] | null = null;

    if (isVideo) {
        content = renderVideoFile();
    }

    if (isImage) {
        content = renderImageFile();
    }

    if (isScreenStream) {
        content = renderScreenPreview();
    }

    function handleDragStart(e: DragEvent) {
        // e.preventDefault();
        handleDragPlaylistItemStart({ item });
        // const ghost = document.createElement('div');
        // const ghostImage = isImage
        //     ? document.createElement('img')
        //     : document.createElement('video');
        // // dragImage.innerHTML = `${content}`;
        // ghostImage.src = (item.item as FileType).blob;
        // // ghost.append(ghostImage);
        // // ghost.classList.add(style['ghost-item']);
        // // document.body.append(ghost);
        // // @ts-ignore
        // // ghostItemRef.current = ghost;
        // // console.log({ dragImage });
        // // ghostImage.setAttribute('style', `width: 40px; height: 40px;`);
        // // document.body.appendChild(ghost);

        // // And finally we assign the dragImage and center it on cursor
        // e.dataTransfer?.setDragImage(ghostImage, 20, 20);
        // // console.log({ key });
        // // setDragItemIndex(item.itemIndex)
    }

    // function handleDragOver(e: DragEvent) {
    //     e.preventDefault();
    //     const { clientX } = e;
    //     if (!itemRect || draggingPlaylistItem?.itemIndex === item.itemIndex) {
    //         return;
    //     }
    //     if (
    //         clientX > itemRect.left &&
    //         clientX < itemRect.left + itemRect.width
    //     ) {
    //         if (clientX < itemRect.left + itemRect.width / 2) {
    //             console.log(`it is on the left of ${item.itemIndex}`);
    //         } else {
    //             console.log(`it is on the right of ${item.itemIndex}`);
    //         }
    //     }
    // }

    // function removeGhost() {
    //     if (ghostItemRef.current) {
    //         document.body.removeChild(ghostItemRef.current);
    //         // @ts-ignore
    //         ghostItemRef.current = null;
    //     }
    // }

    function handleDragEnd(e: DragEvent) {
        // removeGhost();
        handleDragPlaylistItemEnd(e);
    }

    const itemClasses = buildClasses({
        [style['item-preview']]: true,
        [style.selected]: isActive,
        [style.active]:
            !!isVideo &&
            recordingState === RECORDING_STATE.RECORDING &&
            isActive,
        [style.focussed]: isFocussed,
        [style['item-image']]: !!isImage,
        [style['item-video']]: !!isVideo,
        [style['item-screen']]: !!isScreenStream,
        [style.disabled]: recordingState === RECORDING_STATE.DONE,
    });

    return (
        <li
            key={item.itemIndex}
            class={itemClasses}
            onClick={handleClick}
            onDragStart={handleDragStart}
            onDrag={handleItemDrag}
            onDragEnd={handleDragEnd}
            // onDragOver={handleDragOver}
            draggable={!isActive}
            ref={ref}
        >
            {content}
            {isImage && !isGif && <span class={style['item-meta']}>IMG</span>}
            {isScreenStream && (
                <span class={style['item-meta']}>
                    <ScreenIcon />
                </span>
            )}
            {isVideo && !isActive && (
                <span class={style['item-meta']}>
                    {formatTime(itemDuration)}
                </span>
            )}
            <div class={style['play-item-wrapper']}>
                <PlayIcon />
                <span class={style['label-text']}>play now</span>
            </div>
        </li>
    );
};

export default forwardRef(PlaylistItem);
