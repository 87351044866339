import { FunctionalComponent, h, Fragment, JSX } from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';
import Compressor from 'compressorjs';
import { buildClasses, formatTime } from '../../../../utils';
import { sendWebhookMessage } from '../../../../utils/webhooks';
// import { YouTubeChannelDetails } from '../../routes/dashboard';
import { ScheduledBroadcastDetails, months } from '../scheduledBroadcast';
import {
    getAccessToken,
    insertStream,
    insertBroadcast,
    updateBroadcast,
    uploadThumbnail,
    bindLiveStream,
    deleteStream,
    deleteBroadcast,
    BroadcastContentDetails,
    getLiveBroadcasts,
} from '../../../../utils/youtube';
import VideoPreview from '../videoPreview';
import ActionButton from '../actionButton';
import FormInput from '../formInput';
import Loading from '../loading';
import style from './style.scss';

interface Props {
    onClose: () => void;
    videos: DBFile[];
    scheduledBroadcastDetails: ScheduledBroadcastDetails | null;
    youTubeChannelDetails?: YouTubeChannelDetails;
    onSuccessfulPublish: (broadcastId: string) => void;
    videoToStream?: DBFile | null;
    videoPlaying?: boolean;
    scheduledBroadcasts: YouTubeBroadcast[];
    rodeoScheduledBroadcastId?: string;
    showLiveStreamingNotEnabledError: () => void;
    showTryAgainError: () => void;
    isMobileDevice: boolean;
}

interface YouTubeChannelDetails {
    title: string;
    url: string;
    thumbnailUrl: string;
}

interface StreamInfo {
    id: string;
    name: string;
}

interface OriginalBroadcastDetails {
    title: string;
    description: string;
    thumbnailUrl: string;
}

let videoObserver: IntersectionObserver;
const defaultScheduledBroadcastDetails = {
    date: '',
    title: '',
    description: '',
    privacyStatus: 'public',
    username: '',
    id: '',
    channelThumbnailUrl: '',
    contentDetails: {},
};

const StreamNow: FunctionalComponent<Props> = ({
    onClose,
    videos,
    scheduledBroadcastDetails,
    onSuccessfulPublish,
    youTubeChannelDetails,
    videoToStream,
    videoPlaying = false,
    scheduledBroadcasts,
    showLiveStreamingNotEnabledError,
    showTryAgainError,
    isMobileDevice,
}: Props) => {
    let {
        title,
        description,
        privacyStatus,
        username,
        channelThumbnailUrl,
        contentDetails,
    } = defaultScheduledBroadcastDetails;
    if (scheduledBroadcastDetails) {
        title = scheduledBroadcastDetails.title;
        description = scheduledBroadcastDetails.description;
        privacyStatus = scheduledBroadcastDetails.privacyStatus;
        username = scheduledBroadcastDetails.username;
        channelThumbnailUrl = scheduledBroadcastDetails.channelThumbnailUrl;
        contentDetails = scheduledBroadcastDetails.contentDetails;
    }
    const [closing, setClosing] = useState<boolean>(false);
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [streamInfo, setStreamInfo] = useState<StreamInfo | null>(null);
    const [broadcastId, setBroadcastId] = useState<string | null>(
        scheduledBroadcastDetails ? scheduledBroadcastDetails.id : null,
    );
    const [selectingVideo, setSelectingVideo] = useState<boolean>(false);
    const [selectedVideo, setSelectedVideo] = useState<
        DBFile | undefined | null
    >(videoToStream);
    const [tempSelectedVideo, setTempSelectedVideo] = useState<
        DBFile | undefined | null
    >(videoToStream);
    const [step, setStep] = useState<number>(videoToStream ? 1 : 2);
    const [streamTimeSelection, setStreamTimeSelection] = useState<
        'now' | 'existing' | null
    >(scheduledBroadcasts.length === 0 && videoToStream ? 'now' : 'existing');
    const [successfullyPublished, setSuccessfullyPublished] =
        useState<boolean>(false);
    const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
    const [showAlreadyLiveModal, setShowAlreadyLiveModal] =
        useState<boolean>(false);
    const [showAbandonModal, setShowAbandonModal] = useState<boolean>(false);
    const [originalBroadcastDetails, setOriginalBroadcastDetails] =
        useState<OriginalBroadcastDetails>({
            title: '',
            description: '',
            thumbnailUrl: '',
        });
    const [progressMessage, setProgressMessage] =
        useState<string>('Connecting');

    const timer1Ref = useRef<number | null>(null);
    const timer2Ref = useRef<number | null>(null);
    const thumbnailPrevRef = useRef<HTMLImageElement>(null);

    const [waiting, setWaiting] = useState<boolean>(false);

    // Broadcast details entry
    const [broadcastTitle, setBroadcastTitle] = useState<string>(
        title ? title : '',
    );
    const [broadcastDescription, setBroadcastDescription] = useState<string>(
        description || '',
    );
    const [broadcastThumbnailFile, setBroadcastThumbnailFile] = useState<
        File | Blob | null
    >(null);
    const [broadcastThumbnailUrl, setBroadcastThumbnailUrl] =
        useState<string>(channelThumbnailUrl);
    const [broadcastPrivacyStatus, setBroadcastPrivacyStatus] =
        useState<PrivacyStatus>((privacyStatus as PrivacyStatus) || 'public');
    const [broadcastContentDetails, setBroadcastContentDetails] =
        useState<BroadcastContentDetails>(contentDetails);

    const lightboxRef = useRef<HTMLDivElement>(null);
    const didMount = useRef<boolean>(false);
    const closeBtn = (action = fadeOutLightbox) => (
        <button
            type="button"
            class={`${style['close-icon']} ${style['lightbox-button']}`}
            onClick={action}
        >
            <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.753 1.247a1.5 1.5 0 0 1 0 2.121L13.621 11.5l8.132 8.132a1.5 1.5 0 1 1-2.121 2.121L11.5 13.621l-8.132 8.132a1.5 1.5 0 1 1-2.121-2.121L9.379 11.5 1.247 3.368a1.5 1.5 0 1 1 2.121-2.121L11.5 9.379l8.132-8.132a1.5 1.5 0 0 1 2.121 0Z"
                    fill="#FFF"
                    fill-rule="evenodd"
                />
            </svg>
        </button>
    );
    const privacyStatusIcon = (
        <svg width="24" height="14" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
                <path
                    class={`${style['visibility-icon']} ${style[broadcastPrivacyStatus]}`}
                    d="M11.949 14c7.035 0 11.915-4.988 11.915-7 0-2.012-4.83-7-11.945-7C4.805 0 0 5.084 0 7c0 1.916 4.913 7 11.949 7Z"
                    fill="#67D30B"
                />
                <circle
                    stroke="#353535"
                    stroke-width="1.5"
                    cx="11.932"
                    cy="7"
                    r="4.25"
                />
            </g>
        </svg>
    );

    useEffect(() => {
        if (didMount.current) {
            lightboxRef.current?.addEventListener(
                'animationend',
                closeLightbox,
            );
        } else {
            didMount.current = true;
        }
    });

    useEffect(() => {
        if (videoToStream) {
            setBroadcastId(null);
        }
        return () => {
            timer1Ref.current && clearTimeout(timer1Ref.current);
            timer2Ref.current && clearTimeout(timer2Ref.current);
        };
    }, []);

    useEffect(() => {
        // handle if it was existing or set on the fly?
        if (broadcastThumbnailFile) {
            var reader = new FileReader();

            reader.onload = function (e) {
                setBroadcastThumbnailUrl(e.target!.result!.toString());
            };

            reader.readAsDataURL(broadcastThumbnailFile);
        }
    }, [broadcastThumbnailFile]);

    useEffect(() => {
        if (streamInfo && streamInfo.id) {
            if (broadcastId) {
                updateYouTubeBroadcast();
            } else {
                insertYouTubeBroadcast();
            }
        }
    }, [streamInfo]);

    useEffect(() => {
        if (broadcastId && streamInfo && streamInfo.id) {
            bindYouTubeLiveStream(broadcastId);
        }
    }, [broadcastId]);

    useEffect(() => {
        if (accessToken && step > 3) {
            sendCloudPALRequest();
        }
    }, [accessToken]);

    useEffect(() => {
        if (successfullyPublished && timer1Ref.current && timer2Ref.current) {
            timer1Ref.current = window.setTimeout(() => {
                setProgressMessage('Connecting video to YouTube broadcast');
                timer2Ref.current = window.setTimeout(() => {
                    setWaiting(true);
                }, 4500);
            }, 4500);
        }
    }, [successfullyPublished]);

    useEffect(() => {
        if (waiting) {
            if (isMobileDevice) {
                setStep(6);
            } else {
                setProgressMessage('Waiting for broadcast to start');
            }
        }
    }, [waiting]);

    useEffect(() => {
        if (videoPlaying) {
            setStep(5);
        }
    }, [videoPlaying]);

    async function sendCloudPALRequest() {
        if (!selectedVideo || !streamInfo) {
            // Extremely not likely to hit this
            showTryAgainError();
            return;
        }
        setProgressMessage('Sending audio and video to YouTube');
        try {
            const res = await fetch('https://cloud.rodeo.live/publish', {
                method: 'POST',
                body: `bucket_name=stations_cloud_pal&blob_name=${selectedVideo.blobName}&broadcast_id=${broadcastId}&stream_key=${streamInfo.name}&auth_token=${accessToken}`,
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            // console.log({ res });
            if (res.ok) {
                setSuccessfullyPublished(true);
                onSuccessfulPublish(broadcastId!);
                const user = await window.gapi.auth2
                    .getAuthInstance()
                    .currentUser.get()
                    .getBasicProfile();
                sendWebhookMessage({
                    username: user.getName(),
                    action:
                        streamTimeSelection === 'existing'
                            ? 'adopted and started a broadcast'
                            : 'started a broadcast',
                    title: broadcastTitle,
                    privacy: broadcastPrivacyStatus,
                    url: `https://youtu.be/${broadcastId}`,
                    userId: user.getId(),
                });
                // try {
                //     await fetch(`${window.location.hostname}/wentLive`, {
                //         method: 'POST',
                //         body: `url=https://youtu.be/${broadcastId}`,
                //         headers: {
                //             'Content-Type': 'application/x-www-form-urlencoded',
                //         },
                //     });
                // } catch (e: any) {
                //     console.log('error calling wentLive', e);
                // }
            }
        } catch (e: any) {
            console.log('error sending cloud pal request', e);
        }
    }

    function fadeOutLightbox() {
        if (successfullyPublished) {
            setClosing(true);
            return;
        }

        // if the mode is now/new
        if (streamTimeSelection === 'now') {
            // and if there is a broadcast title or broadcast description or thumbnail
            if (
                broadcastTitle ||
                broadcastDescription ||
                broadcastThumbnailUrl
            ) {
                // then we check to see if they want to abandon
                setShowAbandonModal(true);
                return;
            }
        } else if (streamTimeSelection === 'existing') {
            if (
                broadcastTitle !== originalBroadcastDetails.title ||
                broadcastDescription !== originalBroadcastDetails.description ||
                broadcastThumbnailUrl !== originalBroadcastDetails.thumbnailUrl
            ) {
                setShowSaveModal(true);
                return;
            }
        }
        setClosing(true);
    }

    function closeLightbox() {
        if (closing) {
            lightboxRef.current?.removeEventListener(
                'animationend',
                closeLightbox,
            );

            if (!successfullyPublished) {
                deleteStreamAndBroadcast();
            }
            onClose();
            setClosing(false);
        }
    }

    function handleOverlayClick(event: Event) {
        const targetEl = event.target as HTMLElement;
        if (targetEl.classList.contains(style.lightbox)) {
            fadeOutLightbox();
        }
    }

    function renderFile(file: DBFile) {
        const itemClasses = buildClasses({
            [style['file-item']]: true,
            [style['selected-video']]: tempSelectedVideo === file,
            [style['not-selected-video']]:
                !!tempSelectedVideo && tempSelectedVideo !== file,
        });
        return (
            <li
                class={itemClasses}
                onClick={() => {
                    setTempSelectedVideo(file);
                }}
            >
                <VideoPreview
                    file={file}
                    showActions={false}
                    showInfo={true}
                    isMobileDevice={isMobileDevice}
                    observer={videoObserver}
                    onToggleMobileAction={() => {}}
                    handleUploadError={(error: Error) => {}}
                />
            </li>
        );
    }

    function renderFiles() {
        videoObserver = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    const vid = entry.target.getElementsByTagName('video')[0];
                    if (vid) {
                        if (entry.isIntersecting) {
                            vid.play();
                        } else {
                            vid.pause();
                        }
                    }
                });
            },
            { root: null, threshold: 0.5 },
        );
        return <ul class={style['file-list']}>{videos.map(renderFile)}</ul>;
    }

    async function insertYouTubeStream() {
        try {
            const result = await insertStream(broadcastTitle);

            if (result.id) {
                setStreamInfo({
                    id: result.id,
                    name: result.cdn.ingestionInfo.streamName,
                });
            }
        } catch (e: any) {
            if (
                e.result.error.message ===
                'The user is not enabled for live streaming.'
            ) {
                goBackToEdit();
                showLiveStreamingNotEnabledError();
            }
            console.log('error inserting stream', e);
        }
    }

    async function insertYouTubeBroadcast() {
        try {
            const result = await insertBroadcast({
                title: broadcastTitle,
                description: broadcastDescription,
                privacyStatus: broadcastPrivacyStatus,
            });
            if (result.id) {
                setBroadcastId(result.id);
            }
        } catch (e: any) {
            console.log('error inserting broadcast', e);
        }
    }

    async function updateYouTubeBroadcast() {
        if (!broadcastId) {
            return;
        }
        try {
            const result = await updateBroadcast(
                broadcastId,
                {
                    title: broadcastTitle,
                    description: broadcastDescription,
                    privacyStatus: broadcastPrivacyStatus,
                },
                broadcastContentDetails,
            );
            if (!closing) {
                if (result.id) {
                    if (broadcastId && streamInfo && streamInfo.id) {
                        bindYouTubeLiveStream(broadcastId);
                    }
                }
            }
        } catch (e: any) {
            console.log('error updating broadcast', e);
        }
    }

    async function uploadBroadcastThumbnail() {
        if (!broadcastId || !broadcastThumbnailFile) {
            return;
        }

        try {
            const response = await uploadThumbnail(
                broadcastId,
                // @ts-ignore
                broadcastThumbnailFile,
            );

            if (response instanceof Response && response.ok) {
                return response;
            } else {
                throw new Error();
            }
        } catch (e: any) {
            console.log('error uploading thumbnail', e);
        }
    }

    async function bindYouTubeLiveStream(id: string) {
        if (broadcastThumbnailFile) {
            try {
                await uploadBroadcastThumbnail();
            } catch (e: any) {
                console.log('error binding live stream', e);
            }
        }

        if (!streamInfo) {
            return;
        }

        try {
            await bindLiveStream(id, streamInfo.id);
            const accessToken = await getAccessToken();

            setAccessToken(accessToken);
        } catch (e: any) {
            console.log('error binding stream', e);
        }
    }

    async function deleteStreamAndBroadcast(force?: boolean) {
        if (broadcastId) {
            if (
                force ||
                !scheduledBroadcastDetails ||
                !scheduledBroadcastDetails.id
            ) {
                try {
                    await deleteBroadcast(broadcastId);
                    setBroadcastId(null);
                    if (streamInfo?.id) {
                        try {
                            deleteStream(streamInfo.id);
                            setStreamInfo(null);
                        } catch (e: any) {
                            console.log('error deleting live Stream', e);
                        }
                    }
                } catch (e: any) {
                    console.log('error deleting broadcast', e);
                }
            }
        }
    }

    async function insertStreamAndProceed() {
        setProgressMessage(
            streamTimeSelection === 'existing'
                ? 'Updating broadcast'
                : 'Creating broadcast',
        );
        try {
            const broadcasts = await getLiveBroadcasts();

            if (broadcasts.length > 0) {
                setShowAlreadyLiveModal(true);
                return;
            }
        } catch (e) {
            console.log(e);
        }

        insertYouTubeStream();
        nextStep();
    }

    function nextStep() {
        setStep(step + 1);
    }

    function previousStep() {
        setStep(step - 1);
        if (streamTimeSelection === 'now') {
            deleteStreamAndBroadcast();
        }
    }

    function selectExistingBroadcast(broadcast: any) {
        setStreamTimeSelection('existing');
        setOriginalBroadcastDetails({
            title: broadcast.snippet.title,
            description: broadcast.snippet.description,
            thumbnailUrl: broadcast.snippet.thumbnails.default.url,
        });
        setBroadcastTitle(broadcast.snippet.title);
        setBroadcastDescription(broadcast.snippet.description);
        setBroadcastContentDetails(broadcast.contentDetails);
        setBroadcastId(broadcast.id);
        setBroadcastThumbnailUrl(broadcast.snippet.thumbnails.default.url);
    }

    function selectNowBroadcast() {
        setStreamTimeSelection('now');
        setBroadcastTitle('');
        setBroadcastDescription('');
        setBroadcastId(null);
        if (youTubeChannelDetails) {
            setBroadcastThumbnailUrl(youTubeChannelDetails.thumbnailUrl);
        }
    }

    function renderScheduledBroadcastItem(broadcast: YouTubeBroadcast) {
        const broadcastDate = new Date(broadcast.snippet.scheduledStartTime);
        const broadcastTime = broadcastDate.toLocaleTimeString('default', {
            hour: 'numeric',
            minute: '2-digit',
        });
        const itemClasses = buildClasses({
            [style['scheduled-broadcast-item']]: true,
            [style['scheduled-broadcast-item-selected']]:
                broadcast.id === broadcastId,
        });

        return (
            <li
                class={itemClasses}
                onClick={() => {
                    selectExistingBroadcast(broadcast);
                }}
            >
                <img
                    src={broadcast.snippet.thumbnails.default.url}
                    class={style['broadcast-thumbnail']}
                    referrerpolicy="no-referrer"
                />
                <div>
                    <span class={style['scheduled-broadcast-item-title']}>
                        {broadcast.snippet.title}
                    </span>
                    <span class={style['scheduled-broadcast-item-date']}>
                        Scheduled:{' '}
                        {`${
                            months[broadcastDate.getMonth()]
                        } ${broadcastDate.getDate()} at ${broadcastTime}`}
                    </span>
                </div>
            </li>
        );
    }

    function renderStep1() {
        // step 1: pick now or later, or existing
        return (
            <Fragment>
                <div class={style['lightbox-content-header']}>
                    <h1 class={style['lightbox-content-title']}>
                        When should we stream to your channel?
                    </h1>
                    {closeBtn()}
                </div>
                <div class={style['lightbox-content-body']}>
                    <ul class={style['stream-time-option-list']}>
                        <li
                            class={`${style['stream-time-option']} ${
                                streamTimeSelection === 'now'
                                    ? style['stream-time-option-selected']
                                    : ''
                            }`}
                            onClick={selectNowBroadcast}
                        >
                            <div class={style['stream-time-content']}>
                                <svg
                                    width="40"
                                    height="29"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.002.43 8.83 3.259A15.952 15.952 0 0 0 4 14.715c0 4.364 1.747 8.32 4.58 11.206l-2.828 2.83A19.936 19.936 0 0 1 0 14.714 19.94 19.94 0 0 1 6.002.43Zm27.996 0A19.94 19.94 0 0 1 40 14.715a19.936 19.936 0 0 1-5.752 14.035l-2.828-2.829A15.948 15.948 0 0 0 36 14.715c0-4.491-1.85-8.55-4.83-11.456l2.828-2.83ZM10.952 5.38l2.83 2.829A8.974 8.974 0 0 0 11 14.715a8.97 8.97 0 0 0 2.53 6.256L10.701 23.8A12.958 12.958 0 0 1 7 14.715c0-3.663 1.515-6.972 3.952-9.335Zm18.096 0A12.962 12.962 0 0 1 33 14.715c0 3.535-1.411 6.741-3.701 9.085l-2.83-2.829A8.97 8.97 0 0 0 29 14.715a8.974 8.974 0 0 0-2.781-6.506l2.829-2.829ZM20 8.715a6 6 0 1 1 0 12 6 6 0 0 1 0-12Z"
                                        fill="#AAA"
                                        fill-rule="nonzero"
                                    />
                                </svg>
                                <span class={style['stream-time-name']}>
                                    Now
                                </span>
                                <span class={style['stream-time-description']}>
                                    Stream to a new link now
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div>
                        <span class={style['selection-separator']}>
                            OR SELECT AN EXISTING SCHEDULED LINK
                        </span>
                        <ul class={style['scheduled-broadcast-list']}>
                            {scheduledBroadcasts.length === 0 && (
                                <li class={style['scheduled-broadcast-item']}>
                                    <div>
                                        <span
                                            class={
                                                style[
                                                    'scheduled-broadcast-item-title'
                                                ]
                                            }
                                        >
                                            No scheduled broadcasts
                                        </span>
                                    </div>
                                </li>
                            )}
                            {scheduledBroadcasts.map(
                                renderScheduledBroadcastItem,
                            )}
                        </ul>
                    </div>
                    <p class={style['stream-time-subtext-promo']}>
                        Coming soon: schedule for the future
                    </p>
                </div>
                <div class={style['lightbox-content-footer']}>
                    <ActionButton
                        ui="text"
                        display="standard"
                        type="action"
                        environment="dark"
                        action={nextStep}
                        text="Next"
                        size="large"
                        disabled={!streamTimeSelection}
                    />
                </div>
            </Fragment>
        );
    }

    function handleInput({
        currentTarget,
    }: JSX.TargetedEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
        Event
    >) {
        switch (currentTarget.name) {
            case 'broadcastTitle':
                setBroadcastTitle(currentTarget.value);
                break;
            case 'broadcastDescription':
                setBroadcastDescription(currentTarget.value);
                break;
            case 'privacyStatus':
                const val = currentTarget.value as PrivacyStatus;
                setBroadcastPrivacyStatus(val);
                break;
        }
    }

    function handleNewThumbnailFile({
        currentTarget,
    }: JSX.TargetedEvent<HTMLInputElement, Event>) {
        if (currentTarget.files) {
            const file = currentTarget.files[0];
            new Compressor(file, {
                quality: 0.6,
                success(result) {
                    setBroadcastThumbnailFile(result);
                },
                error(err) {
                    console.log({ err });
                    showTryAgainError();
                },
            });
        }
    }

    function renderStep2() {
        // step 2: scheduler
        const titleCharacterLimit = 100;
        const descCharacterLimit = 5000;
        const canAdvance =
            broadcastTitle.length > 0 &&
            broadcastTitle.length <= titleCharacterLimit &&
            broadcastDescription.length <= descCharacterLimit &&
            selectedVideo;

        const advanceAction = selectingVideo
            ? () => {
                  setSelectedVideo(tempSelectedVideo);
                  setSelectingVideo(false);
              }
            : nextStep;
        const advanceActionIsDisabled = selectingVideo ? false : !canAdvance;
        const advanceActionText = selectingVideo ? 'Save' : 'Next';

        function cancelSelectingVideo() {
            setSelectingVideo(false);
            setTempSelectedVideo(selectedVideo);
        }

        const cancelAction = selectingVideo
            ? cancelSelectingVideo
            : previousStep;

        const content = selectingVideo ? (
            renderFiles()
        ) : (
            <div class={style['broadcast-details-entry-container']}>
                <p class={style['lightbox-content-text']}>
                    Stream a video you've uploaded to Rodeo Cloud as a high
                    quality live stream on your channel.
                </p>
                <h2>Details</h2>
                <div class={style['broadcast-details-entry']}>
                    <FormInput
                        className={style['broadcast-details-entry-title']}
                        label="Title"
                        value={broadcastTitle}
                        required={true}
                        maxCharacters={titleCharacterLimit}
                        placeholderText="Add a title"
                        name="broadcastTitle"
                        onChange={handleInput}
                    />
                    <FormInput
                        type="textarea"
                        className={style['broadcast-details-entry-description']}
                        label="Description"
                        value={broadcastDescription}
                        maxCharacters={descCharacterLimit}
                        placeholderText="Add a description"
                        name="broadcastDescription"
                        onChange={handleInput}
                    />
                    <div class={style['broadcast-details-entry-thumbnail']}>
                        <span class={style['lightbox-content-title']}>
                            Thumbnail
                        </span>
                        <p class={style['lightbox-content-text']}>
                            Select or upload a picture that represents your
                            stream. A good thumbnail stands out and draws
                            viewer’s attention.
                        </p>
                        {broadcastThumbnailUrl && (
                            <span class={style['thumbnail-container']}>
                                <img
                                    ref={thumbnailPrevRef}
                                    src={broadcastThumbnailUrl}
                                    referrerpolicy="no-referrer"
                                />
                            </span>
                        )}
                        <label
                            class={`${style['hidden-file-input']} ${
                                !broadcastThumbnailUrl
                                    ? style['thumbnail-empty']
                                    : ''
                            }`}
                        >
                            {broadcastThumbnailUrl
                                ? 'Change thumbnail'
                                : 'Choose thumbnail'}
                            <input
                                type="file"
                                accept="image/jpeg, image/png, application/octet-stream"
                                value={broadcastThumbnailFile?.toString()}
                                onChange={handleNewThumbnailFile}
                            />
                        </label>
                    </div>
                    <div class={style['broadcast-details-entry-video-details']}>
                        <FormInput
                            type="select"
                            className={
                                style['broadcast-details-entry-input-select']
                            }
                            label="Visibility"
                            value={broadcastPrivacyStatus}
                            icon={privacyStatusIcon}
                            name="privacyStatus"
                            options={[
                                { value: 'public', label: 'Public' },
                                { value: 'unlisted', label: 'Unlisted' },
                                { value: 'private', label: 'Private' },
                            ]}
                            onChange={handleInput}
                        />
                        <div
                            class={
                                style[
                                    'broadcast-details-entry-video-details-video'
                                ]
                            }
                        >
                            {!selectedVideo && (
                                <ActionButton
                                    ui="text"
                                    display="standard"
                                    type="action"
                                    environment="dark"
                                    action={() => {
                                        setSelectingVideo(true);
                                    }}
                                    text="Add video"
                                />
                            )}
                            {selectedVideo && (
                                <div
                                    class={
                                        style[
                                            'broadcast-details-entry-video-details-preview'
                                        ]
                                    }
                                >
                                    <div
                                        class={
                                            style[
                                                'broadcast-details-entry-video-details-thumb-container'
                                            ]
                                        }
                                    >
                                        <video
                                            src={selectedVideo.preview}
                                            poster={selectedVideo.thumbnailUrl}
                                            onClick={() => {
                                                setSelectingVideo(true);
                                            }}
                                        />
                                    </div>
                                    <div
                                        class={
                                            style[
                                                'broadcast-details-entry-video-details-info'
                                            ]
                                        }
                                    >
                                        <span
                                            class={
                                                style[
                                                    'broadcast-details-entry-video-details-label'
                                                ]
                                            }
                                        >
                                            Selected video
                                        </span>
                                        <span
                                            class={
                                                style[
                                                    'broadcast-details-entry-video-details-name'
                                                ]
                                            }
                                        >
                                            {selectedVideo.displayName}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <Fragment>
                <div class={style['lightbox-content-header']}>
                    <h1 class={style['lightbox-content-title']}>
                        Stream now on my channel
                        <svg
                            width="40"
                            height="29"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M6.002.43 8.83 3.259A15.952 15.952 0 0 0 4 14.715c0 4.364 1.747 8.32 4.58 11.206l-2.828 2.83A19.936 19.936 0 0 1 0 14.714 19.94 19.94 0 0 1 6.002.43Zm27.996 0A19.94 19.94 0 0 1 40 14.715a19.936 19.936 0 0 1-5.752 14.035l-2.828-2.829A15.948 15.948 0 0 0 36 14.715c0-4.491-1.85-8.55-4.83-11.456l2.828-2.83ZM10.952 5.38l2.83 2.829A8.974 8.974 0 0 0 11 14.715a8.97 8.97 0 0 0 2.53 6.256L10.701 23.8A12.958 12.958 0 0 1 7 14.715c0-3.663 1.515-6.972 3.952-9.335Zm18.096 0A12.962 12.962 0 0 1 33 14.715c0 3.535-1.411 6.741-3.701 9.085l-2.83-2.829A8.97 8.97 0 0 0 29 14.715a8.974 8.974 0 0 0-2.781-6.506l2.829-2.829ZM20 8.715a6 6 0 1 1 0 12 6 6 0 0 1 0-12Z"
                                fill="#FFF"
                                fill-rule="nonzero"
                            />
                        </svg>
                    </h1>
                    {closeBtn()}
                </div>
                <div
                    class={`${style['lightbox-content-body']} ${
                        selectingVideo ? style['video-selection-list'] : ''
                    }`}
                >
                    {content}
                </div>
                <div class={style['lightbox-content-footer']}>
                    <ActionButton
                        ui="text"
                        display="standard"
                        type="action"
                        environment="dark"
                        action={advanceAction}
                        text={advanceActionText}
                        size="large"
                        disabled={advanceActionIsDisabled}
                    />
                    {videoToStream && (
                        <ActionButton
                            ui="text"
                            display="standard"
                            type="normal"
                            environment="dark"
                            action={cancelAction}
                            text="Back"
                            size="large"
                        />
                    )}
                </div>
            </Fragment>
        );
    }
    function renderStep3() {
        // step 3: summary
        if (!selectedVideo) {
            previousStep();
            return;
        }
        return (
            <Fragment>
                <div class={style['lightbox-content-header']}>
                    <h1 class={style['lightbox-content-title']}>
                        Stream summary
                    </h1>
                    {closeBtn()}
                </div>
                <div
                    class={`${style['lightbox-content-body']} ${style['stream-summary']}`}
                >
                    <div class={style['stream-summary']}>
                        <div class={style['video-thumb-container']}>
                            <img
                                class={style['video-thumb']}
                                src={selectedVideo.thumbnailUrl}
                            />
                        </div>
                        <div class={style['stream-summary-content']}>
                            <div class={style['stream-summary-meta-info']}>
                                <span class={style['channel-name']}>
                                    {youTubeChannelDetails && (
                                        <img
                                            class={
                                                style['channel-profile-image']
                                            }
                                            src={
                                                youTubeChannelDetails.thumbnailUrl
                                            }
                                            alt={youTubeChannelDetails.title}
                                            referrerpolicy="no-referrer"
                                        />
                                    )}
                                    {youTubeChannelDetails
                                        ? youTubeChannelDetails.title
                                        : username}
                                </span>
                                <span
                                    class={`${style['stream-summary-visibility']} ${style[broadcastPrivacyStatus]}`}
                                >
                                    {privacyStatusIcon}
                                    {broadcastPrivacyStatus}
                                </span>
                            </div>
                            <div class={style['stream-summary-info-container']}>
                                <div class={style['stream-summar-info-thumb']}>
                                    <span class={style['thumbnail-container']}>
                                        <img src={broadcastThumbnailUrl} />
                                    </span>
                                </div>
                                <div>
                                    <h3 class={style['stream-summary-title']}>
                                        {broadcastTitle}
                                    </h3>
                                    {broadcastDescription && (
                                        <p
                                            class={
                                                style[
                                                    'stream-summary-description'
                                                ]
                                            }
                                        >
                                            {broadcastDescription}
                                        </p>
                                    )}
                                    <p class={style['stream-summary-duration']}>
                                        {formatTime(selectedVideo.duration)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class={style['lightbox-content-footer']}>
                    <ActionButton
                        ui="text"
                        display="standard"
                        type="warning"
                        environment="dark"
                        action={insertStreamAndProceed}
                        text="Start streaming"
                        size="large"
                    />
                    <ActionButton
                        ui="text"
                        display="standard"
                        type="normal"
                        environment="dark"
                        action={previousStep}
                        text="Go back and edit"
                        size="large"
                    />
                </div>
            </Fragment>
        );
    }
    function renderStep4() {
        // step 4: connecting
        return (
            <Fragment>
                <div class={style['lightbox-content-header']}>
                    <h1 class={style['lightbox-content-title']}>
                        Streaming live to{' '}
                        {youTubeChannelDetails
                            ? youTubeChannelDetails.title
                            : username
                            ? username
                            : 'your channel'}
                    </h1>
                    {closeBtn()}
                </div>
                <div
                    class={`${style['lightbox-content-body']} ${style.connecting}`}
                >
                    <Loading text={`${progressMessage} ...`} />
                </div>
            </Fragment>
        );
    }

    function renderStep5() {
        // step 5: published
        return (
            <Fragment>
                <div class={style['lightbox-content-header']}>
                    <h1 class={style['lightbox-content-title']}>
                        Streaming live to{' '}
                        {youTubeChannelDetails
                            ? youTubeChannelDetails.title
                            : username}
                    </h1>
                    {closeBtn()}
                </div>
                <div
                    class={`${style['lightbox-content-body']} ${style.connecting}`}
                >
                    <img
                        src="../../../../assets/images/celebration.png"
                        alt="A party hat. Yay!"
                    />
                    <p>
                        Your video has been published to your YouTube channel as
                        a live broadcast!
                    </p>
                </div>
                <div
                    class={`${style['lightbox-content-footer']} ${style['connecting-footer']}`}
                >
                    <a
                        class={style['go-back-button']}
                        href={`https://youtu.be/${broadcastId}`}
                    >
                        Watch on YouTube: https://youtu.be/{broadcastId}
                    </a>
                </div>
            </Fragment>
        );
    }

    async function saveBroadcastThenLeave() {
        await updateYouTubeBroadcast();
        setClosing(true);
    }

    function noEvent(e: Event) {
        e.preventDefault();
        e.stopPropagation();
    }

    function renderSaveModal() {
        return (
            <div
                class={`${style.lightbox} ${style['lightbox-modal']}`}
                onClick={noEvent}
            >
                <div
                    class={`${style['lightbox-content-body']} ${style['lightbox-modal-content']}`}
                >
                    <div
                        class={`${style['lightbox-content-header']} ${style['lightbox-modal-header']}`}
                    >
                        <span
                            class={`${style['lightbox-content-title']} ${style['lightbox-modal-title']}`}
                        >
                            Save before leaving?
                        </span>
                        {closeBtn(() => {
                            setShowSaveModal(false);
                        })}
                    </div>
                    <div>
                        <div class={style['stream-summary']}>
                            <p>
                                You're about to leave Rodeo app with unsaved
                                changes. What would you like to do?
                            </p>
                        </div>
                    </div>
                    <div
                        class={`${style['lightbox-content-footer']} ${style['lightbox-modal-footer']}`}
                    >
                        <ActionButton
                            ui="text"
                            display="standard"
                            type="warning"
                            environment="dark"
                            action={saveBroadcastThenLeave}
                            text="Save and leave"
                        />
                        <ActionButton
                            ui="text"
                            display="standard"
                            type="normal"
                            environment="dark"
                            action={() => {
                                setClosing(true);
                            }}
                            text="Leave without saving"
                        />
                    </div>
                </div>
            </div>
        );
    }

    function renderAlreadyLiveModal() {
        return (
            <div
                class={`${style.lightbox} ${style['lightbox-modal']}`}
                onClick={noEvent}
            >
                <div
                    class={`${style['lightbox-content-body']} ${style['lightbox-modal-content']}`}
                >
                    <div
                        class={`${style['lightbox-content-header']} ${style['lightbox-modal-header']}`}
                    >
                        <span
                            class={`${style['lightbox-content-title']} ${style['lightbox-modal-title']}`}
                        >
                            Already live
                        </span>
                        {closeBtn(() => {
                            setShowAlreadyLiveModal(false);
                        })}
                    </div>
                    <div>
                        <div class={style['stream-summary']}>
                            <p>
                                You are currently live. Please wait for that
                                broadcast to end before starting another one.
                            </p>
                        </div>
                    </div>
                    <div
                        class={`${style['lightbox-content-footer']} ${style['lightbox-modal-footer']}`}
                    >
                        <ActionButton
                            ui="text"
                            display="standard"
                            type="normal"
                            environment="dark"
                            action={() => {
                                setShowAlreadyLiveModal(false);
                            }}
                            text="OK"
                        />
                    </div>
                </div>
            </div>
        );
    }

    function goBackToEdit() {
        setStep(step > 2 ? 2 : step);
        deleteStreamAndBroadcast(true);
        setShowAbandonModal(false);
    }

    function abandonBroadcast() {
        deleteStreamAndBroadcast(true);
        setClosing(true);
    }

    function renderAbandonModal() {
        return (
            <div
                class={`${style.lightbox} ${style['lightbox-modal']}`}
                onClick={noEvent}
            >
                <div
                    class={`${style['lightbox-content-body']} ${style['lightbox-modal-content']}`}
                >
                    <div
                        class={`${style['lightbox-content-header']} ${style['lightbox-modal-header']}`}
                    >
                        <span
                            class={`${style['lightbox-content-title']} ${style['lightbox-modal-title']}`}
                        >
                            Abandon this stream?
                        </span>
                        {closeBtn(() => {
                            setShowSaveModal(false);
                        })}
                    </div>
                    <div>
                        <div class={style['stream-summary']}>
                            <p>
                                You're about to lose all the changes you just
                                made.
                            </p>
                        </div>
                    </div>
                    <div
                        class={`${style['lightbox-content-footer']} ${style['lightbox-modal-footer']}`}
                    >
                        <ActionButton
                            ui="text"
                            display="standard"
                            type="warning"
                            environment="dark"
                            action={abandonBroadcast}
                            text="Abandon"
                        />
                        <ActionButton
                            ui="text"
                            display="standard"
                            type="normal"
                            environment="dark"
                            action={goBackToEdit}
                            text="Back to edit"
                        />
                    </div>
                </div>
            </div>
        );
    }

    const lightboxClasses = buildClasses({
        [style.lightbox]: true,
        [style.closing]: closing,
        [style['video-selector']]: step === 1,
    });

    // TODO: Make a lightbox component
    return (
        <div
            class={lightboxClasses}
            ref={lightboxRef}
            onClick={handleOverlayClick}
        >
            <div
                class={`${style['lightbox-content']} ${
                    selectingVideo ? style['video-selection-content'] : ''
                }`}
            >
                {step === 1 && renderStep1()}
                {step === 2 && renderStep2()}
                {step === 3 && renderStep3()}
                {step === 4 && renderStep4()}
                {step === 5 && renderStep5()}
            </div>
            {showAbandonModal && renderAbandonModal()}
            {showSaveModal && renderSaveModal()}
            {showAlreadyLiveModal && renderAlreadyLiveModal()}
        </div>
    );
};

export default StreamNow;
