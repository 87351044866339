// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

// constants, enums, types
import { PIP_CIRCLE_SIZE, PIP_POS_PADDING } from '../../constants';
import { CONTENT_LAYOUT, PIP_POS, PIP_SHAPE } from '../../enums';
import { Coords } from '../../types';

// utils
import { buildClasses } from '../../../../utils';

// components
import UserCameraDropZone from '../userCameraDropZone';
import { RecordAppContext } from '../../index';

// assets

//styles
import style from './style.scss';

interface Props {
    dragPos: Coords;
    handleSetNewContentLayout: ({ layout }: { layout: CONTENT_LAYOUT }) => void;
    handleSetNewPipPos: ({ pipPos }: { pipPos: PIP_POS }) => void;
}

const UserCameraDropZones: FunctionalComponent<Props> = ({
    dragPos,
    handleSetNewContentLayout,
    handleSetNewPipPos,
}: Props) => {
    const { contentLayout, pipShape, stageScale } =
        useContext(RecordAppContext);

    function getDropZones() {
        switch (contentLayout) {
            case CONTENT_LAYOUT.CIRCLE:
                if (pipShape === PIP_SHAPE.CIRCLE) {
                    return [
                        <UserCameraDropZone
                            position={{
                                top: `${PIP_POS_PADDING * stageScale}px`,
                                left: `${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.TOP_LEFT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: `${PIP_POS_PADDING * stageScale}px`,
                                left: `calc(100% - ${
                                    PIP_CIRCLE_SIZE * stageScale
                                }px - ${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.TOP_RIGHT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: `calc(100% - ${
                                    PIP_CIRCLE_SIZE * stageScale
                                }px - ${PIP_POS_PADDING * stageScale}px`,
                                left: `${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.BOTTOM_LEFT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: `calc(100% - ${
                                    PIP_CIRCLE_SIZE * stageScale
                                }px - ${PIP_POS_PADDING * stageScale}px`,
                                left: `calc(100% - ${
                                    PIP_CIRCLE_SIZE * stageScale
                                }px - ${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.BOTTOM_RIGHT,
                                });
                            }}
                            showZone={true}
                        />,
                    ]; // TODO: More positions than just 4 corners
                } else if (pipShape === PIP_SHAPE.LANDSCAPE) {
                    return [
                        <UserCameraDropZone
                            position={{
                                top: `${PIP_POS_PADDING * stageScale}px`,
                                left: '6.05%',
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.TOP_LEFT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: 'auto',
                                bottom: `${PIP_POS_PADDING * stageScale}px`,
                                left: '6.05%',
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.BOTTOM_LEFT,
                                });
                            }}
                            showZone={true}
                        />,
                    ];
                } else if (pipShape === PIP_SHAPE.PORTRAITISH) {
                    return [
                        <UserCameraDropZone
                            position={{
                                top: `${PIP_POS_PADDING * stageScale}px`,
                                left: `${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.TOP_LEFT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: `${PIP_POS_PADDING * stageScale}px`,
                                left: `calc(100% - ${
                                    PIP_CIRCLE_SIZE * stageScale
                                }px - ${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.TOP_RIGHT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: 'auto',
                                bottom: `${PIP_POS_PADDING * stageScale}px`,
                                left: `${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.BOTTOM_LEFT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: 'auto',
                                bottom: `${PIP_POS_PADDING * stageScale}px`,
                                left: `calc(100% - ${
                                    PIP_CIRCLE_SIZE * stageScale
                                }px - ${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.BOTTOM_RIGHT,
                                });
                            }}
                            showZone={true}
                        />,
                    ];
                } else if (pipShape === PIP_SHAPE.LANDSCAPEISH) {
                    return [
                        <UserCameraDropZone
                            position={{
                                top: `${PIP_POS_PADDING * stageScale}px`,
                                left: `${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.TOP_LEFT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: `${PIP_POS_PADDING * stageScale}px`,
                                left: 'auto',
                                right: `${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.TOP_RIGHT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: 'auto',
                                bottom: `${PIP_POS_PADDING * stageScale}px`,
                                left: `${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.BOTTOM_LEFT,
                                });
                            }}
                            showZone={true}
                        />,
                        <UserCameraDropZone
                            position={{
                                top: 'auto',
                                bottom: `${PIP_POS_PADDING * stageScale}px`,
                                left: 'auto',
                                right: `${PIP_POS_PADDING * stageScale}px`,
                            }}
                            dragPos={dragPos}
                            handleZoneActive={() => {
                                handleSetNewContentLayout({
                                    layout: CONTENT_LAYOUT.CIRCLE,
                                });
                                handleSetNewPipPos({
                                    pipPos: PIP_POS.BOTTOM_RIGHT,
                                });
                            }}
                            showZone={true}
                        />,
                    ];
                }
                break;
            case CONTENT_LAYOUT.TOP:
                return (
                    <UserCameraDropZone
                        position={{
                            top: '50%',
                            left: '0',
                        }}
                        dragPos={dragPos}
                        handleZoneActive={() => {
                            handleSetNewContentLayout({
                                layout: CONTENT_LAYOUT.BOTTOM,
                            });
                        }}
                        showZone={false}
                    />
                );
                break;
            case CONTENT_LAYOUT.BOTTOM:
                return (
                    <UserCameraDropZone
                        position={{
                            top: '0',
                            left: '0',
                        }}
                        dragPos={dragPos}
                        handleZoneActive={() => {
                            handleSetNewContentLayout({
                                layout: CONTENT_LAYOUT.TOP,
                            });
                        }}
                        showZone={false}
                    />
                );
                break;
            case CONTENT_LAYOUT.LEFT:
                return (
                    <UserCameraDropZone
                        position={{
                            top: '0',
                            left: '50%',
                        }}
                        dragPos={dragPos}
                        handleZoneActive={() => {
                            handleSetNewContentLayout({
                                layout: CONTENT_LAYOUT.RIGHT,
                            });
                        }}
                        showZone={false}
                    />
                );
                break;
            case CONTENT_LAYOUT.RIGHT:
                return (
                    <UserCameraDropZone
                        position={{
                            top: '0',
                            left: '0',
                        }}
                        dragPos={dragPos}
                        handleZoneActive={() => {
                            handleSetNewContentLayout({
                                layout: CONTENT_LAYOUT.LEFT,
                            });
                        }}
                        showZone={false}
                    />
                );
                break;
        }
    }

    const dropZoneWrapperClasses = buildClasses({
        [style['user-camera-drop-zones-wrapper']]: true,
        [style[contentLayout.toLowerCase()]]: true,
    });

    return <div class={dropZoneWrapperClasses}>{getDropZones()}</div>;
};

export default UserCameraDropZones;
