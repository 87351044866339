import { Fragment, FunctionalComponent, h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { buildClasses } from '../../../../utils';
import CloudIcon from '../cloudIcon';
import Delete from '../delete';
import Download from '../download';
import style from './style.scss';

interface Props {
    file: DBFile;
    onDeleteConfirm: (
        blobName: string,
        displayName: string,
        fileType: FileType,
        onDeleteSuccess: () => void,
    ) => void;
    onDownloadStarted: () => void;
    onDownloadCompleted: () => void;
    onDownloadError: () => void;
    downloadInProgress: boolean;
    isMobileDevice: boolean;
    onToggleMobileAction: (
        file: DBFile,
        fileType: FileType,
        topPos: number,
    ) => void;
}

const ImagePreview: FunctionalComponent<Props> = ({
    file,
    onDeleteConfirm,
    downloadInProgress,
    onDownloadStarted,
    onDownloadCompleted,
    onDownloadError,
    isMobileDevice,
    onToggleMobileAction,
}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const actionMenuDotsRef = useRef<HTMLDivElement>(null);
    const [actionsMenuActive, setActionsMenuActive] = useState<boolean>(false);
    const [actionsMenuPos, setActionsMenuPos] = useState<'left' | 'right'>(
        'left',
    );

    useEffect(() => {
        window.addEventListener('resize', determineMenuPosition);
    }, []);

    useEffect(() => {
        if (actionMenuDotsRef.current) {
            determineMenuPosition();
            actionMenuDotsRef.current.addEventListener(
                'mouseenter',
                openActionMenu,
            );
        }
    }, [actionMenuDotsRef]);

    useEffect(() => {
        if (actionsMenuActive) {
            const timer = setTimeout(() => {
                setActionsMenuActive(false);
            }, 3000);
            // @ts-ignore
            timer();

            return clearTimeout(timer);
        }
    }, [actionsMenuActive]);

    function determineMenuPosition() {
        if (!actionMenuDotsRef.current) {
            return;
        }

        if (
            actionMenuDotsRef.current.getBoundingClientRect().left <
            window.innerWidth / 2
        ) {
            setActionsMenuPos('right');
        } else {
            setActionsMenuPos('left');
        }
    }

    function openActionMenu(event: Event) {
        event.stopPropagation();
        setActionsMenuActive(!actionsMenuActive);
    }

    function renderActions() {
        const itemActionsClasses = buildClasses({
            [style['item-actions']]: !isMobileDevice,
            [style['mobile-actions']]: isMobileDevice,
        });

        if (isMobileDevice) {
            return (
                <div
                    class={itemActionsClasses}
                    onClick={event => {
                        const target = event.target as HTMLElement;
                        const pos = target.getBoundingClientRect();
                        onToggleMobileAction(file, 'image', pos.y);
                    }}
                >
                    <span>
                        <CloudIcon className={style['uploaded-icon']} />
                    </span>
                </div>
            );
        } else {
            return (
                <div class={itemActionsClasses}>
                    <div
                        class={style['action-menu-container']}
                        ref={actionMenuDotsRef}
                        onClick={openActionMenu}
                    >
                        <span class={style['action-menu-text']}>Actions</span>
                        <span class={style['action-menu-icon']}>
                            <span class={style['action-menu-dot']}></span>
                            <span class={style['action-menu-dot']}></span>
                            <span class={style['action-menu-dot']}></span>
                        </span>
                    </div>
                    <span class={style['detail-element']}>
                        <CloudIcon className={style['uploaded-icon']} />
                    </span>
                </div>
            );
        }
    }

    function renderActionMenu() {
        const actionMenuClasses = buildClasses({
            [style['action-menu']]: true,
            [style.active]: actionsMenuActive,
        });
        return (
            <ul
                class={actionMenuClasses}
                onClick={e => {
                    e.stopPropagation();
                }}
                style={
                    actionsMenuPos === 'left'
                        ? { left: 'auto', right: '36px' }
                        : { left: 'calc(100% - 20px)', right: 'auto' }
                }
            >
                <li class={style['item-title']}>{file.displayName}</li>
                <li>
                    <Download
                        ui="both"
                        blobName={file.blobName}
                        align="left"
                        displayName={file.displayName}
                        onDownloadStarted={onDownloadStarted}
                        onDownloadCompleted={onDownloadCompleted}
                        onDownloadError={onDownloadError}
                        size={file.size}
                        disabled={downloadInProgress}
                        fileType="image"
                        display="standard"
                    />
                </li>
                <li>
                    <Delete
                        ui="both"
                        blobName={file.blobName}
                        align="left"
                        action={onDeleteConfirm!}
                        displayName={file.displayName}
                        display="standard"
                        onDeleteSuccess={() => {}}
                        fileType="image"
                    />
                </li>
            </ul>
        );
    }

    return (
        <Fragment>
            <div class={style.item} ref={containerRef}>
                <div class={style['image-container']} data-test="tyler">
                    <img src={file.thumbnailUrl} />
                </div>
                {renderActions()}
            </div>
            {renderActionMenu()}
        </Fragment>
    );
};

export default ImagePreview;
