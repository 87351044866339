import { FunctionalComponent, h } from 'preact';
import {
    ref as storageRef,
    uploadBytesResumable,
    UploadTaskSnapshot,
    UploadTask,
} from 'firebase/storage';
// import { v4 as uuid } from 'uuid';
import { nanoid } from 'nanoid';
import { formatDateNum, acceptedFileTypes } from '../../../../utils';
import {
    audioStorage,
    cloudVideoStorage,
    imageStorage,
} from '../../../../utils/firebase';

import style from './style.scss';

interface Props {
    userId: string;
    onFilesChosen: (event: Event) => void;
}

export interface UploadFile {
    file: File;
    isUploading: boolean;
    blobName: string;
    // bytesTransferred: number;
    uploadTask: any;
    startTime: Date;
}

// TODO: is this the right spot for this? feels weird to export it and use it in from a prop. Oh well
export function handleUploadProgress(snapshot: UploadTaskSnapshot) {
    return snapshot.bytesTransferred;
}

export async function handleUploadSuccess(uploadTask: UploadTask) {
    // console.log('done');
    // const url = await getDownloadURL(uploadTask.snapshot.ref);
    // console.log({ url });
}

export function uploadFiles(
    fileList: FileList | File[],
    userId: string,
): UploadFile[] {
    const files = [];
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    for (let i = 0; i < fileList.length; i++) {
        let fileStorageRef;
        const fileType = fileList[i].type;
        const date = new Date();
        const fileName = `${userId}/Rodeo - ${formatDateNum(
            date.getMonth() + 1,
        )}-${formatDateNum(date.getDate())}-${`${date.getFullYear()}`.slice(
            -2,
        )} ${weekDays[date.getDay()]} ${formatDateNum(
            date.getHours(),
        )} ${formatDateNum(date.getMinutes())} ${formatDateNum(
            date.getSeconds(),
        )} - ${nanoid()}`;

        if (fileType.includes('audio')) {
            fileStorageRef = storageRef(audioStorage, fileName);
        } else if (fileType.includes('video')) {
            fileStorageRef = storageRef(cloudVideoStorage, fileName);
        } else if (fileType.includes('image')) {
            fileStorageRef = storageRef(imageStorage, fileName);
        } else {
            // Not a supported file
            // Show an error toast
            return [];
        }
        const uploadTask = uploadBytesResumable(fileStorageRef, fileList[i], {
            customMetadata: {
                displayName: fileList[i].name,
                creationDate: date.toISOString(),
            },
        });
        // const uploadTask = () => {};
        let file: UploadFile = {
            file: fileList[i],
            blobName: fileName,
            startTime: new Date(),
            isUploading: true,
            uploadTask,
        };
        files.push(file);
    }
    return files;
}

const Upload: FunctionalComponent<Props> = ({
    userId,
    onFilesChosen,
}: Props) => {
    return (
        <div class={style.upload}>
            <label>
                Upload media
                <input
                    type="file"
                    accept={acceptedFileTypes.join(', ')}
                    multiple
                    onChange={event => {
                        onFilesChosen(event);
                    }}
                />
            </label>
        </div>
    );
};

export default Upload;
