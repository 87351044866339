import { CanvasDrawProps } from '../components/Record/types';
import {
	CONTENT_LAYOUT,
	STAGE_ORIENTATION,
	PIP_POS,
	PIP_SHAPE,
} from '../components/Record/enums';
import {
	PIP_POS_PADDING,
	PIP_CIRCLE_SIZE,
	CANVAS_BLUR_RADIUS,
} from '../components/Record/constants';

interface Rect {
	// x: number;
	// y: number;
	width: number;
	height: number;
}

interface DrawFactors {
	canvasWidth: number;
	canvasHeight: number;
	source: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement;
	orientation?: STAGE_ORIENTATION; // probably don't need
	contentLayout: CONTENT_LAYOUT;
	pipPos: PIP_POS;
	pipShape: PIP_SHAPE;
	isUserCamera: boolean;
	isFit: boolean;
}

// interface PipOffsetProps {
// 	contentLayout: CONTENT_LAYOUT;
// 	pipPos: PIP_POS;
// 	canvasWidth: number;
// 	canvasHeight: number;
// }

export function getSourceWidth(
	source: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement,
) {
	if ('naturalWidth' in source) {
		return source.naturalWidth;
	}

	if ('videoWidth' in source) {
		return source.videoWidth;
	}

	return source.width;
}

export function getSourceHeight(
	source: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement,
) {
	if ('naturalHeight' in source) {
		return source.naturalHeight;
	}

	if ('videoHeight' in source) {
		return source.videoHeight;
	}

	return source.height;
}

export function getSourceAspectRatio(
	source: HTMLVideoElement | HTMLImageElement | HTMLCanvasElement,
) {
	return getSourceWidth(source) / getSourceHeight(source);
}

export function makeRect({
	aspectRatio,
	inRect,
	fit,
}: {
	aspectRatio: number;
	inRect: Rect;
	fit: boolean;
}) {
	const rectAspect = inRect.width / inRect.height;
	// console.log({ rectAspect, fit });
	const fillHeight =
		(fit && aspectRatio > rectAspect) || (!fit && aspectRatio < rectAspect);
	if (fillHeight) {
		const newWidth = inRect.height * aspectRatio;
		return {
			x: (inRect.width - newWidth) / 2,
			y: 0,
			width: newWidth,
			height: inRect.height,
		};
	} else {
		const newHeight = inRect.width / aspectRatio;
		return {
			x: 0,
			y: (inRect.height - newHeight) / 2,
			width: inRect.width,
			height: newHeight,
		};
	}
}

export function rectContains({
	x,
	y,
	w,
	h,
	pX,
	pY,
}: {
	x: number;
	y: number;
	w: number;
	h: number;
	pX: number;
	pY: number;
}) {
	// separating axis theorem
	return pX >= x && pX <= x + w && pY >= y && pY <= y + h;
}

function getPipShape({
	canvasWidth,
	canvasHeight,
	pipShape,
}: {
	canvasWidth: number;
	canvasHeight: number;
	pipShape: PIP_SHAPE;
}) {
	if (pipShape === PIP_SHAPE.LANDSCAPE) {
		const dw = canvasWidth * 0.879;
		const dh = dw * (9 / 16);
		return { dw, dh, radius: 24 };
	}

	if (pipShape === PIP_SHAPE.PORTRAITISH) {
		const dh = PIP_CIRCLE_SIZE * 2; // TODO: What is this size actually?
		const dw = dh * (9 / 16);
		return { dw, dh, radius: 24 };
	}

	if (pipShape === PIP_SHAPE.LANDSCAPEISH) {
		const dh = PIP_CIRCLE_SIZE; // TODO: What is this size actually?
		const dw = dh * (16 / 9);
		return { dw, dh, radius: 24 };
	}

	return {
		dw: PIP_CIRCLE_SIZE,
		dh: PIP_CIRCLE_SIZE,
		radius: PIP_CIRCLE_SIZE / 2,
	};
}

// TODO: I don't think this is needed
// export function getPipOffset({
// 	contentLayout,
// 	pipPos,
// 	canvasWidth,
// 	canvasHeight,
// }: PipOffsetProps) {
// 	switch (contentLayout) {
// 		case CONTENT_LAYOUT.CIRCLE:
// 			switch (pipPos) {
// 				case PIP_POS.TOP_LEFT:
// 					return {
// 						x:
// 							canvasWidth * 2 -
// 							Math.min(canvasWidth, canvasHeight) / 2 +
// 							3,
// 						y: 0,
// 					};
// 					break;
// 				case PIP_POS.TOP_RIGHT:
// 					return {
// 						x: Math.min(canvasWidth, canvasHeight) / 2 - 3,
// 						y: 0,
// 					};
// 					break;
// 				case PIP_POS.BOTTOM_LEFT:
// 					return {
// 						// x: Math.min(canvasWidth, canvasHeight) / 2 - 3,
// 						x: canvasWidth,
// 						y: 0,
// 					};
// 					break;
// 				case PIP_POS.BOTTOM_RIGHT:
// 					return {
// 						x:
// 							canvasWidth * 2 -
// 							Math.min(canvasWidth, canvasHeight) / 2 +
// 							3,
// 						y: 0,
// 					};
// 					break;
// 			}
// 			break;
// 		case CONTENT_LAYOUT.TOP:
// 			return {
// 				x: canvasWidth,
// 				y: 0,
// 			};
// 			break;
// 		case CONTENT_LAYOUT.BOTTOM:
// 			return {
// 				x: canvasWidth,
// 				y: 0,
// 			};
// 			break;
// 		case CONTENT_LAYOUT.LEFT:
// 			return {
// 				x: canvasWidth / 2,
// 				y: 0,
// 			};
// 			break;
// 		case CONTENT_LAYOUT.RIGHT:
// 			return {
// 				x: canvasWidth * 1.5,
// 				y: 0,
// 			};
// 			break;
// 		default:
// 			return {
// 				x: 0,
// 				y: 0,
// 			};
// 	}

// 	return {
// 		x: 0,
// 		y: 0,
// 	};
// }

export function getFullscreenDrawProps({
	canvasWidth,
	canvasHeight,
	source,
	orientation,
	contentLayout,
	pipPos,
	isUserCamera,
	isFit,
}: DrawFactors): CanvasDrawProps {
	const sourceWidth = getSourceWidth(source);
	const sourceHeight = getSourceHeight(source);
	const canvasRatio = canvasWidth / canvasHeight;
	let sourceRect;
	let drawProps = {
		sx: 0,
		sy: 0,
		sw: 0,
		sh: 0,
		dx: 0,
		dy: 0,
		dw: 0,
		dh: 0,
		radius: 0,
		// tx: 0,
		// ty: 0,
	};

	if (isUserCamera) {
		sourceRect = makeRect({
			aspectRatio: canvasRatio,
			inRect: { width: sourceWidth, height: sourceHeight },
			fit: false,
		});
		// TODO: is it like this every time?
		drawProps = {
			sx: sourceRect.x,
			sy: sourceRect.y,
			sw: sourceRect.width,
			sh: sourceRect.height,
			dx: 0,
			dy: 0,
			dw: canvasWidth,
			dh: canvasHeight,
			radius: 0,
			// tx: canvasWidth,
			// ty: 0,
		};
	} else {
		sourceRect = makeRect({
			aspectRatio: 1,
			inRect: { width: sourceWidth, height: sourceHeight },
			fit: isFit,
		});

		drawProps = {
			sx: sourceRect.x,
			sy: sourceRect.y,
			sw: sourceRect.width,
			sh: sourceRect.height,
			dx: PIP_POS_PADDING,
			dy: canvasHeight - PIP_POS_PADDING - PIP_CIRCLE_SIZE,
			dw: PIP_CIRCLE_SIZE,
			dh: PIP_CIRCLE_SIZE,
			radius: 0,
			// tx: 0,
			// ty: 0,
		};

		if (contentLayout === CONTENT_LAYOUT.CIRCLE) {
			switch (pipPos) {
				case PIP_POS.TOP_LEFT:
					drawProps.dx = PIP_POS_PADDING;
					drawProps.dy = PIP_POS_PADDING;
					break;
				case PIP_POS.TOP_RIGHT:
					drawProps.dx =
						canvasWidth - PIP_POS_PADDING - PIP_CIRCLE_SIZE;
					drawProps.dy = PIP_POS_PADDING;
					break;
				case PIP_POS.BOTTOM_LEFT:
					drawProps.dx = PIP_POS_PADDING;
					drawProps.dy =
						canvasHeight - PIP_POS_PADDING - PIP_CIRCLE_SIZE;
					break;
				case PIP_POS.BOTTOM_RIGHT:
					drawProps.dx =
						canvasWidth - PIP_POS_PADDING - PIP_CIRCLE_SIZE;
					drawProps.dy =
						canvasHeight - PIP_POS_PADDING - PIP_CIRCLE_SIZE;
					break;
			}
		}
	}

	return drawProps;
}

export function getBackgroundDrawProps({
	canvasWidth,
	canvasHeight,
	source,
	contentLayout,
	pipPos,
	isUserCamera,
	isFit,
}: DrawFactors): CanvasDrawProps {
	const sourceWidth = getSourceWidth(source);
	const sourceHeight = getSourceHeight(source);
	let props = {
		sx: 0,
		sy: 0,
		sw: 0,
		sh: 0,
		dx: 0,
		dy: 0,
		dw: canvasWidth + CANVAS_BLUR_RADIUS * 2,
		dh: canvasHeight + CANVAS_BLUR_RADIUS * 2,
		radius: 0,
		// tx: 0,
		// ty: 0,
	};

	switch (contentLayout) {
		case CONTENT_LAYOUT.TOP:
			props.dx = -CANVAS_BLUR_RADIUS;
			props.dh = canvasHeight / 2 + CANVAS_BLUR_RADIUS * 2;
			if (isUserCamera) {
				props.dy = -CANVAS_BLUR_RADIUS;
			} else {
				props.dy = canvasHeight / 2 - CANVAS_BLUR_RADIUS;
			}
			break;
		case CONTENT_LAYOUT.BOTTOM:
			props.dx = -CANVAS_BLUR_RADIUS;
			props.dh = canvasHeight / 2 + CANVAS_BLUR_RADIUS * 2;
			if (isUserCamera) {
				props.dy = canvasHeight / 2 - CANVAS_BLUR_RADIUS;
			} else {
				props.dy = -CANVAS_BLUR_RADIUS;
			}
			break;
		case CONTENT_LAYOUT.LEFT:
			props.dy = -CANVAS_BLUR_RADIUS;
			props.dw = canvasWidth / 2 + CANVAS_BLUR_RADIUS * 2;
			if (isUserCamera) {
				props.dx = -CANVAS_BLUR_RADIUS;
			} else {
				props.dx = canvasWidth / 2 - CANVAS_BLUR_RADIUS * 2;
			}
			break;
		case CONTENT_LAYOUT.RIGHT:
			props.dy = -CANVAS_BLUR_RADIUS;
			props.dw = canvasWidth / 2 + CANVAS_BLUR_RADIUS * 2;
			if (isUserCamera) {
				props.dx = canvasWidth / 2 - CANVAS_BLUR_RADIUS * 2;
			} else {
				props.dx = -CANVAS_BLUR_RADIUS;
			}
			break;
		case CONTENT_LAYOUT.CIRCLE:
			if (isUserCamera) {
				props.dw = PIP_CIRCLE_SIZE;
				props.dh = PIP_CIRCLE_SIZE;
				props.radius = PIP_CIRCLE_SIZE / 2;
				switch (pipPos) {
					case PIP_POS.TOP_LEFT:
						props.dx = PIP_POS_PADDING;
						props.dy = PIP_POS_PADDING;
						break;
					case PIP_POS.TOP_RIGHT:
						props.dx =
							canvasWidth - PIP_POS_PADDING - PIP_CIRCLE_SIZE;
						props.dy = PIP_POS_PADDING;
						break;
					case PIP_POS.BOTTOM_LEFT:
						props.dx = PIP_POS_PADDING;
						props.dy =
							canvasHeight - PIP_POS_PADDING - PIP_CIRCLE_SIZE;
						break;
					case PIP_POS.BOTTOM_RIGHT:
						props.dx =
							canvasWidth - PIP_POS_PADDING - PIP_CIRCLE_SIZE;
						props.dy =
							canvasHeight - PIP_POS_PADDING - PIP_CIRCLE_SIZE;
						break;
				}
			} else {
				props.dw = canvasWidth + CANVAS_BLUR_RADIUS * 2;
				props.dh = canvasHeight + CANVAS_BLUR_RADIUS * 2;
				props.dx = -CANVAS_BLUR_RADIUS;
				props.dy = -CANVAS_BLUR_RADIUS;
			}
			break;
	}

	const sourceRect = makeRect({
		aspectRatio: props.dw / props.dh,
		inRect: { width: sourceWidth, height: sourceHeight },
		fit: false,
	});
	props.sx = sourceRect.x;
	props.sy = sourceRect.y;
	props.sw = sourceRect.width;
	props.sh = sourceRect.height;

	return props;
}

// // gets the properties for what to draw when the source is in the layout
// // aka not full screen
export function getLayoutDrawProps({
	canvasWidth,
	canvasHeight,
	source,
	contentLayout,
	pipPos,
	pipShape,
	isUserCamera,
	isFit,
}: DrawFactors): CanvasDrawProps {
	const sourceWidth = getSourceWidth(source);
	const sourceHeight = getSourceHeight(source);
	// const { x: tx, y: ty } = getPipOffset({
	// 	contentLayout,
	// 	pipPos,
	// 	canvasWidth,
	// 	canvasHeight,
	// });

	let props = {
		sx: 0,
		sy: 0,
		sw: 0,
		sh: 0,
		dx: 0,
		dy: 0,
		dw: canvasWidth,
		dh: canvasHeight,
		radius: 0,
		// tx: isUserCamera ? tx : 0,
		// ty: isUserCamera ? ty : 0,
	};

	switch (contentLayout) {
		case CONTENT_LAYOUT.CAMERA:
			if (isUserCamera) {
				return getFullscreenDrawProps({
					canvasWidth,
					canvasHeight,
					source,
					contentLayout,
					pipPos,
					pipShape,
					isUserCamera,
					isFit,
				});
			} else {
				return props;
			}
			break;
		case CONTENT_LAYOUT.TOP:
			props.dx = 0;
			props.dh = canvasHeight / 2;
			if (isUserCamera) {
				props.dy = 0;
			} else {
				props.dy = canvasHeight / 2;
			}
			break;
		case CONTENT_LAYOUT.BOTTOM:
			props.dx = 0;
			props.dh = canvasHeight / 2;
			if (isUserCamera) {
				props.dy = canvasHeight / 2;
			} else {
				props.dy = 0;
			}
			break;
		case CONTENT_LAYOUT.LEFT:
			props.dy = 0;
			props.dw = canvasWidth / 2;
			if (isUserCamera) {
				props.dx = 0;
			} else {
				props.dx = canvasWidth / 2;
			}
			break;
		case CONTENT_LAYOUT.RIGHT:
			props.dy = 0;
			props.dw = canvasWidth / 2;
			if (isUserCamera) {
				props.dx = canvasWidth / 2;
			} else {
				props.dx = 0;
			}
			break;
		case CONTENT_LAYOUT.CIRCLE:
			if (isUserCamera) {
				const { dw, dh, radius } = getPipShape({
					canvasWidth,
					canvasHeight,
					pipShape,
				});
				props.dw = dw;
				props.dh = dh;
				props.radius = radius;
				switch (pipPos) {
					case PIP_POS.TOP_LEFT:
						if (pipShape === PIP_SHAPE.LANDSCAPE) {
							props.dx = (canvasWidth - props.dw) / 2;
						} else {
							props.dx = PIP_POS_PADDING;
						}
						props.dy = PIP_POS_PADDING;
						break;
					case PIP_POS.TOP_RIGHT:
						if (pipShape === PIP_SHAPE.LANDSCAPE) {
							props.dx = (canvasWidth - props.dw) / 2;
						} else {
							props.dx = canvasWidth - PIP_POS_PADDING - props.dw;
						}
						props.dy = PIP_POS_PADDING;
						break;
					case PIP_POS.BOTTOM_LEFT:
						if (pipShape === PIP_SHAPE.CIRCLE) {
							props.dx = PIP_POS_PADDING; // CIRCLE
						} else if (pipShape === PIP_SHAPE.LANDSCAPE) {
							props.dx = (canvasWidth - props.dw) / 2; // LANDSCAPE
						} else if (pipShape === PIP_SHAPE.PORTRAITISH) {
							props.dx =
								canvasWidth -
								PIP_POS_PADDING * 2 -
								props.dw * 2; // PORTRAITISH
						} else if (pipShape === PIP_SHAPE.LANDSCAPEISH) {
							props.dx = PIP_POS_PADDING;
						}
						props.dy = canvasHeight - PIP_POS_PADDING - props.dh;
						break;
					case PIP_POS.BOTTOM_RIGHT:
						if (pipShape === PIP_SHAPE.LANDSCAPE) {
							props.dx = (canvasWidth - props.dw) / 2;
						} else {
							props.dx = canvasWidth - PIP_POS_PADDING - props.dw;
						}
						props.dy = canvasHeight - PIP_POS_PADDING - props.dh;
						break;
				}
			} else {
				props.dw = canvasWidth;
				props.dh = canvasHeight;
				props.dx = 0;
				props.dy = 0;
			}
			break;
	}

	const sourceRect = makeRect({
		aspectRatio: props.dw / props.dh,
		inRect: { width: sourceWidth, height: sourceHeight },
		fit: !isUserCamera && isFit,
	});

	props.sx = sourceRect.x;
	props.sy = sourceRect.y;
	props.sw = sourceRect.width;
	props.sh = sourceRect.height;

	return props;
}

// // this is the minimized
// // export function getMinimizedDrawProps({
// //     canvas,
// //     source,
// //     orientation,
// //     pipLayout,
// //     pipPos,
// //     isPip,
// //     isFit,
// // }: DrawFactors): ReactionDrawProps {
// //     const { width: canvasWidth, height: canvasHeight } = canvas;
// //     const sourceWidth =
// //         'naturalWidth' in source ? source.naturalWidth : source.videoWidth;
// //     const sourceHeight =
// //         'naturalHeight' in source ? source.naturalHeight : source.videoHeight;
// //     const canvasRatio = canvasWidth / canvasHeight;
// //     const sourceRect = makeRect(
// //         canvasRatio,
// //         { width: sourceWidth, height: sourceHeight },
// //         !isPip && isFit
// //     );

// //     // TODO: is it like this every time?
// //     return {
// //         sx: sourceRect.x,
// //         sy: sourceRect.y,
// //         sw: sourceRect.width,
// //         sh: sourceRect.height,
// //         dx: canvasWidth - PIP_POS_PADDING - PIP_CIRCLE_SIZE,
// //         dy: PIP_POS_PADDING,
// //         dw: PIP_CIRCLE_SIZE,
// //         dh: PIP_CIRCLE_SIZE,
// //         radius: isPip ? PIP_CIRCLE_SIZE / 2 : 0,
// //     };
// // }

// export function getStartingDrawProps({
//     canvas,
//     source,
//     orientation,
//     pipLayout,
//     pipPos,
//     isPip,
//     isFit,
// }: DrawFactors): ReactionDrawProps {
//     const { width: canvasWidth, height: canvasHeight } = canvas;
//     const sourceWidth = getSourceWidth(source);
//     const sourceHeight = getSourceHeight(source);
//     const canvasRatio = canvasWidth / canvasHeight;
//     const sourceRect = makeRect(
//         canvasRatio,
//         { width: sourceWidth, height: sourceHeight },
//         !isPip && isFit
//     );

//     let props = {
//         sx: sourceRect.x,
//         sy: sourceRect.y,
//         sw: sourceRect.width,
//         sh: sourceRect.height,
//         dx: canvasWidth - PIP_POS_PADDING,
//         dy: PIP_POS_PADDING,
//         dw: PIP_CIRCLE_SIZE,
//         dh: PIP_CIRCLE_SIZE,
//         radius: PIP_CIRCLE_SIZE / 2,
//         tx: 0,
//         ty: 0,
//     };

//     if (isPip) {
//         if (pipLayout === 'circle') {
//             if (pipPos === 1) {
//                 props = {
//                     ...props,
//                     dx: canvasWidth - PIP_POS_PADDING,
//                     dy: PIP_POS_PADDING,
//                     dw: PIP_CIRCLE_SIZE,
//                     dh: PIP_CIRCLE_SIZE,
//                     radius: PIP_CIRCLE_SIZE / 2,
//                 };
//             }

//             if (pipPos === 2) {
//                 props = {
//                     ...props,
//                     dx: PIP_CIRCLE_SIZE - PIP_POS_PADDING,
//                     dy: PIP_POS_PADDING,
//                     dw: PIP_CIRCLE_SIZE,
//                     dh: PIP_CIRCLE_SIZE,
//                     radius: PIP_CIRCLE_SIZE / 2,
//                 };
//             }

//             if (pipPos === 3) {
//                 props = {
//                     ...props,
//                     dx: PIP_CIRCLE_SIZE + PIP_POS_PADDING,
//                     dy: canvasHeight - PIP_CIRCLE_SIZE - PIP_POS_PADDING,
//                     dw: PIP_CIRCLE_SIZE,
//                     dh: PIP_CIRCLE_SIZE,
//                     radius: PIP_CIRCLE_SIZE / 2,
//                 };
//             }

//             if (pipPos === 4) {
//                 props = {
//                     ...props,
//                     dx: canvasWidth - PIP_POS_PADDING,
//                     dy: canvasHeight - PIP_CIRCLE_SIZE - PIP_POS_PADDING,
//                     dw: PIP_CIRCLE_SIZE,
//                     dh: PIP_CIRCLE_SIZE,
//                     radius: PIP_CIRCLE_SIZE / 2,
//                 };
//             }
//         }
//     }

//     return props;
// }

// export function getEndingingDrawProps({
//     canvas,
//     source,
//     orientation,
//     pipLayout,
//     isPip,
// }: DrawFactors) {}
