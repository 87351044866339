// from packages
import { FunctionalComponent, h } from 'preact';
import { TargetedEvent } from 'preact/compat';
import { useContext, useState } from 'preact/hooks';

// constants, enums, types

// utils
// import { formatTime } from '../../../../utils';

// components
import { RecordAppContext } from '../../index';
import Button from '../../../common/button';

// assets
import ExportIconWhite from '../../../../assets/images/export-icon-white.svg';
import ExportIconBlack from '../../../../assets/images/export-icon-black.svg';
import PauseIconWhite from '../../../../assets/images/pause-icon-white.svg';
import PlayIconWhite from '../../../../assets/images/play-icon-white.svg';

//styles
import style from './style.scss';

interface Props {
	canPlayPreview: boolean;
	handleExportButtonClick: () => void;
	onSetPreviewTime: (time: number) => void;
	toggleClipsPlaying: () => void;
	videoCurrentTime: number;
	videoDuration: number;
}

const ClipsFilmstrip: FunctionalComponent<Props> = ({
	canPlayPreview,
	handleExportButtonClick,
	onSetPreviewTime,
	toggleClipsPlaying,
	videoCurrentTime,
	videoDuration,
}: Props) => {
	const { clipsPlaying } = useContext(RecordAppContext);
	const [exportIcon, setExportIcon] = useState<JSX.Element>(
		<ExportIconWhite />,
	);
	// const timeRemaining = videoDuration - videoCurrentTime;

	function getProgress() {
		if (!videoDuration || videoDuration === 0) {
			return 0;
		}
		return (videoCurrentTime / videoDuration) * 100;
	}

	function handleChange(event: TargetedEvent<HTMLInputElement, Event>) {
		event.preventDefault();
		event.stopPropagation();
		const target = event.target as HTMLInputElement;
		if (videoDuration && target) {
			// activeItemDisplay.currentTime =
			//     duration * (parseInt(target.value) / 100);

			onSetPreviewTime(videoDuration * (parseInt(target.value) / 100));
		}
		return;
	}
	return (
		<div class={style['filmstrip-wrapper']}>
			<Button
				handleClick={toggleClipsPlaying}
				label={clipsPlaying ? 'Pause' : 'Play'}
				hideLabel={true}
				buttonStyle="icon"
				icon={clipsPlaying ? <PauseIconWhite /> : <PlayIconWhite />}
				disabled={!canPlayPreview}
			/>
			<div class={style['timeline-container']}>
				<input
					type="range"
					class={style.timeline}
					value={getProgress()}
					onChange={handleChange}
					step="any"
					disabled={!videoDuration || videoDuration < 1}
				/>
				<span
					class={style.progress}
					style={{
						width: `${
							videoDuration
								? (videoCurrentTime / videoDuration) * 100
								: 0
						}%`,
					}}
				></span>
			</div>
			<div
				class={style['export-button-container']}
				onMouseEnter={() => {
					setExportIcon(<ExportIconBlack />);
				}}
				onMouseLeave={() => {
					setExportIcon(<ExportIconWhite />);
				}}
			>
				<Button
					handleClick={handleExportButtonClick}
					label="Export"
					iconPosition="top"
					buttonStyle="icon"
					icon={exportIcon}
					buttonColor="dark"
					className={style['export-button']}
				/>
			</div>
		</div>
	);
};

export default ClipsFilmstrip;
