import { FunctionalComponent, h, Fragment } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.scss';

interface Props {
    withLink?: boolean;
}

const logo = (
    <svg
        class={style.logo}
        width="93"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g fill="none" fill-rule="evenodd">
            <g fill-rule="nonzero">
                <path
                    d="M71.6.9v21.4H69l-.4-2.1h-.1c-.5 1.4-1.7 2.4-3.2 2.4-2.4 0-3.2-1.7-3.2-6.6v-2.4c0-4.6 1-6.6 3.3-6.6 1.2 0 2.3.6 2.9 1.6h.1c-.1-1.3-.1-2.2-.1-3.3V.9h3.3Zm-3.3 17.8v-8.5c-.3-.5-.8-.8-1.3-.8-1.1 0-1.4 1-1.4 4.3v1.9c0 3.4.3 4.4 1.3 4.4.5.1 1.2-.4 1.4-1.3Zm15-2.8v-2.2C83.3 8.8 84.7 7 88 7c3.3 0 4.6 1.9 4.6 6.7v2.2c0 4.7-1.2 6.7-4.7 6.7s-4.6-2-4.6-6.7Zm-7.024.025v.76c0 2.648.146 3.555 1.274 3.555 1.078 0 1.324-.83 1.348-2.549l2.916.171c.221 3.236-1.47 4.682-4.339 4.682-3.48 0-4.51-2.28-4.51-6.422v-2.626C72.965 9.133 74.118 7 77.574 7c3.48 0 4.363 1.813 4.363 6.348v2.577h-5.661Zm0-3.09v1.079h2.475v-1.072c0-2.764-.196-3.555-1.244-3.555-1.047 0-1.231.81-1.231 3.555v-.006ZM89.2 17.1v-4.7c0-2.1-.3-3.1-1.3-3.1s-1.2 1.1-1.2 3.1v4.7c0 2.2.3 3.1 1.2 3.1 1 0 1.3-.9 1.3-3.1Zm-37.9-1.2v-2.2C51.3 8.8 52.7 7 56 7c3.3 0 4.6 1.9 4.6 6.7v2.2c0 4.7-1.2 6.7-4.7 6.7s-4.6-2-4.6-6.7Zm5.9 1.2v-4.7c0-2.1-.3-3.1-1.3-3.1s-1.2 1.1-1.2 3.1v4.7c0 2.2.3 3.1 1.2 3.1 1 0 1.3-.9 1.3-3.1ZM46.82 22.032l-2.207-8.915a9.796 9.796 0 0 1-.962.057h-.368v8.858H40V1.4h4.302c4.217 0 5.519 1.925 5.519 5.632v.51c0 2.207-.85 3.792-2.35 4.698l.652 1.84 2.32 7.952h-3.622ZM43.85 10.91c1.867 0 2.518-1.132 2.518-3.31v-.482c0-2.406-.594-3.198-2.49-3.198h-.623v7.019h.594v-.029Z"
                    fill="#000000"
                />
                <path
                    d="M33.57 3.747A4.294 4.294 0 0 0 30.54.716C27.865 0 17.139 0 17.139 0S6.422 0 3.747.716A4.294 4.294 0 0 0 .716 3.747C0 6.422 0 12 0 12s0 5.578.716 8.253a4.294 4.294 0 0 0 3.031 3.031C6.422 24 17.143 24 17.143 24s10.722 0 13.396-.716a4.294 4.294 0 0 0 3.031-3.031c.716-2.675.716-8.253.716-8.253s0-5.578-.716-8.253Z"
                    fill="red"
                />
                <path fill="#FFF" d="M13.714 17.143 22.622 12l-8.908-5.143z" />
            </g>
        </g>
    </svg>
);

const text = <span class={style.title}>Rodeo</span>;

const RodeoLogo: FunctionalComponent<Props> = ({ withLink = false }: Props) => {
    if (withLink) {
        return (
            <Link class={style.link} href="/home">
                {logo}
                {text}
            </Link>
        );
    } else {
        return (
            <Fragment>
                {logo}
                {text}
            </Fragment>
        );
    }
};

export default RodeoLogo;
