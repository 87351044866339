// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext, useRef, useState } from 'preact/hooks';

// constants, enums, types
import { PLAYLIST_ACTIONS } from '../../enums';

// utils
// import { buildClasses } from '../../utils';

// components
import { RecordAppContext } from '../../index';

// assets
import ClosedTrashIcon from '../../../../assets/images/closed-trash-icon.svg';
import OpenTrashIcon from '../../../../assets/images/open-trash-icon.svg';

//styles
import style from './style.scss';

interface Props {}

const DeleteDropZone: FunctionalComponent<Props> = ({}: Props) => {
    const { playlistDispatch, draggingPlaylistItem } =
        useContext(RecordAppContext);
    const [isOverIcon, setIsOverIcon] = useState<boolean>(false);
    const dragCounter = useRef<number>(0);
    function handleDragOverDropArea(e: DragEvent) {
        dragCounter.current = dragCounter.current + 1;
        e.preventDefault();
        // e.stopPropagation();
        setIsOverIcon(true);
    }
    function handleDragLeaveDropArea(e: DragEvent) {
        dragCounter.current = dragCounter.current - 1;
        // e.preventDefault();
        // e.stopPropagation();
        if (dragCounter.current < 1) {
            setIsOverIcon(false);
        }
    }
    function handleDrop(e: DragEvent) {
        e.preventDefault();

        if (draggingPlaylistItem) {
            playlistDispatch({
                type: PLAYLIST_ACTIONS.REMOVE,
                payload: draggingPlaylistItem,
            });
        }
    }
    return (
        <div class={style['delete-drop-zone']}>
            <span
                class={`${style.icon} ${isOverIcon && style.over}`}
                onDragOver={handleDragOverDropArea}
                onDragLeave={handleDragLeaveDropArea}
                onDrop={handleDrop}
            >
                <span class={style['inactive-icon']}>
                    <ClosedTrashIcon />
                </span>
                <span class={style['active-icon']} onDrop={handleDrop}>
                    <OpenTrashIcon />
                </span>
            </span>
            <span class={style['delete-text']}>
                {!isOverIcon && 'Drop media here to remove'}
            </span>
        </div>
    );
};

export default DeleteDropZone;
