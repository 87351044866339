import { getBrowserInfo } from './index';

interface WebhookParams {
	username?: string;
	action: string;
	privacy?: PrivacyStatus;
	title?: string;
	url?: string;
	userId: string;
}

const teamUsernames = new Set([
"John Hibionada",
"itsDORiTODAVE",
"Hannah Hironaka",
"Eric Chung",
"Ryan Gordon",
"Ankur Thakkar",
"James Bergen Plummer",
"stations test3",
"花Hannah",
"johnhib",
"Sandor Frey",
"Eric C",
"stations test",
"JASON MANTE",
"Rodeo Insider",
"Tyler Lesperance",
"Windows of Kenn",
"Osha Martell",
"ericiamm",
"フレイSændor",
"Rodeo Testing"
]);

const webhookUrl = "https://chat.googleapis.com/v1/spaces/AAAAOSNyW1U/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=hBbBgrgdWwUavUWKOlox8E-9bnwkG-oOZRr-IYvnliY%3D"
const teamWebhookUrl = "https://chat.googleapis.com/v1/spaces/AAAAFnRFJl8/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=Y5din2BYGW1XuG8AUTM2f7ALdFt1kTTs8jkInX37IR4%3D"

export async function sendWebhookMessage({
	username,
	action,
	privacy,
	title,
	url,
	userId,
}: WebhookParams) {
	function getAction() {
		return `${username ? username : 'Someone'} ${action} *from the web*`;
	}

	function getPrivacy() {
		if (!privacy) {
			return '';
		}

		return `\nPrivacy: ${privacy}`;
	}

	function getBrowser() {
		const {
			browser: { name, version },
		} = getBrowserInfo();
		return `${name} ${version}`;
	}

	function getTitle() {
		if (!title) {
			return '';
		}

		return `\nTitle: ${title}`;
	}

	function getUrl() {
		if (!url) {
			return '';
		}

		return `\n${url}`;
	}

	function getWebhookUrl() {
		if (username && teamUsernames.has(username!)) {
			return teamWebhookUrl;
		}
		return webhookUrl;
	}

	try {
		const message = `${getAction()}${getPrivacy()}
User ID: ${userId}${getTitle()}${getUrl()}
${getBrowser()}`;

		const data = JSON.stringify({
			text: message,
		});
		await fetch(getWebhookUrl(), {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
			},
			body: data,
		});
	} catch (e) {
		console.log('error sending a bot message', e);
	}
}
