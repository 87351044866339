import { FunctionalComponent, h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import {
    buildClasses,
    deviceCanUseMouse,
    acceptedFileTypes,
} from '../../../../utils';
import style from './style.scss';

interface Props {
    onFilesDropped: (files: File[]) => void;
    onDragStart: () => void;
    onDragEnd: () => void;
    onFolderDropped: () => void;
}

const Dropzone: FunctionalComponent<Props> = ({
    onFilesDropped,
    onDragStart,
    onDragEnd,
    onFolderDropped,
}: Props) => {
    const [isDragging, setIsDragging] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('dragenter', handleDragEnter);
        window.addEventListener('dragover', handleDragOver);
        window.addEventListener('drop', handleDrop);
        window.addEventListener('dragleave', handleDragLeave);
        return () => {
            window.removeEventListener('dragenter', handleDragEnter);
            window.removeEventListener('dragover', handleDragOver);
            window.removeEventListener('drop', handleDrop);
            window.removeEventListener('dragleave', handleDragLeave);
        };
    }, []);

    useEffect(() => {
        if (isDragging) {
            onDragStart();
        } else {
            onDragEnd();
        }
    }, [isDragging]);

    function handleDragEnter(event: DragEvent) {
        setIsDragging(true);
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    function handleDragOver(event: DragEvent) {
        setIsDragging(true);
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    }

    function handleDragLeave(event: DragEvent) {
        setIsDragging(false);
        event.preventDefault();
    }

    function handleDrop(event: DragEvent) {
        setIsDragging(false);
        event.preventDefault();
        handleFileSelection(event);
        event.stopImmediatePropagation();
    }

    // function isValidVideoType(file: File) {
    //     return file.type.includes('video') && file.type !== 'video/webm';
    // }

    function isValidAudioType(file: File) {
        return file.type.includes('audio');
    }

    function isValidImageType(file: File) {
        return file.type.includes('image');
    }

    function isValidFileType(item: DataTransferItem) {
        if (item.webkitGetAsEntry) {
            // this is implemented in non-webkit browsers, possible renaming in the future
            const entry = item.webkitGetAsEntry();
            if (entry) {
                if (entry.isDirectory) {
                    onFolderDropped();
                    return false;
                }
            }
        }

        const file = item.getAsFile();
        if (file) {
            if (
                acceptedFileTypes.indexOf(file.type) > -1 ||
                isValidImageType(file) ||
                isValidAudioType(file)
            ) {
                return true;
            }
        }
        onFolderDropped();
        return false;
    }

    function handleFileSelection(event: DragEvent) {
        const { dataTransfer } = event;
        const droppedFiles = [];

        if (dataTransfer) {
            for (let i = 0; i < dataTransfer.items.length; i++) {
                if (isValidFileType(dataTransfer.items[i])) {
                    const file = dataTransfer.items[i].getAsFile();
                    file && droppedFiles.push(file);
                }
            }
            if (droppedFiles.length > 0) {
                onFilesDropped(droppedFiles);
            }
        }
    }

    const dropzoneClasses = buildClasses({
        [style.dropzone]: true,
        [style['drag-active']]: isDragging,
    });

    const dropzoneContentClasses = buildClasses({
        [style.content]: true,
        [style.active]: isDragging,
    });

    if (!deviceCanUseMouse) {
        return null;
    }
    return (
        <div class={dropzoneClasses}>
            <div class={dropzoneContentClasses}>
                <img
                    src="../../../assets/images/upload-cloud.png"
                    alt="Cloud with up arrow inside"
                />
                Drag and drop here
            </div>
        </div>
    );
};

export default Dropzone;
