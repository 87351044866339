import { FunctionalComponent, h } from 'preact';
import { useRef } from 'preact/hooks';
import Download from '../download';
import Delete from '../delete';
import style from './style.scss';

interface Props {
    thumbnailUrl: string;
    displayName: string;
    blobName: string;
    size: number;
    fileType: FileType;
    onClose: () => void;
    onDeleteConfirm: (
        blobName: string,
        displayName: string,
        fileType: FileType,
        onDeleteSuccess: () => void,
    ) => void;
    onDownloadStarted: () => void;
    onDownloadCompleted: () => void;
    onDownloadError: () => void;
    onSelectVideo: () => void;
}

const ActionMenu: FunctionalComponent<Props> = ({
    thumbnailUrl,
    displayName,
    blobName,
    size,
    fileType,
    onClose,
    onDeleteConfirm,
    onDownloadStarted,
    onDownloadCompleted,
    onDownloadError,
    onSelectVideo,
}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <div class={style['action-menu-container']} ref={containerRef}>
            <div class={style['action-menu-header']}>
                <button
                    type="button"
                    class={style['close-icon']}
                    onClick={onClose}
                >
                    <svg
                        width="23"
                        height="23"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M21.753 1.247a1.5 1.5 0 0 1 0 2.121L13.621 11.5l8.132 8.132a1.5 1.5 0 1 1-2.121 2.121L11.5 13.621l-8.132 8.132a1.5 1.5 0 1 1-2.121-2.121L9.379 11.5 1.247 3.368a1.5 1.5 0 1 1 2.121-2.121L11.5 9.379l8.132-8.132a1.5 1.5 0 0 1 2.121 0Z"
                            fill="#FFF"
                            fill-rule="evenodd"
                        />
                    </svg>
                </button>
            </div>
            <ul class={style['action-menu']}>
                <li
                    class={`${style['action-menu-item']} ${style['item-details']}`}
                >
                    <img src={thumbnailUrl} class={style['item-thumb']} />
                    <span class={style['item-title']}>{displayName}</span>
                </li>
                {fileType === 'video' && (
                    <li
                        class={`${style['action-menu-item']} ${style['action-stream']}`}
                        onClick={onSelectVideo}
                    >
                        <svg
                            width="20"
                            height="14.5"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M3.00130795,0.429504359 L4.41555165,1.84386848 C2.92534538,3.2968697 2,5.32648142 2,7.57221913 C2,9.75441741 2.8737239,11.7325465 4.29042759,13.1758624 L2.87612094,14.5900808 C1.09753696,12.784797 0,10.3066798 0,7.57221913 C0,4.77416633 1.14917878,2.24451861 3.00130795,0.429504359 Z M16.998692,0.429504359 C18.8508212,2.24451861 20,4.77416633 20,7.57221913 C20,10.3066798 18.902463,12.784797 17.1238791,14.5900808 L15.7095724,13.1758624 C17.1262761,11.7325465 18,9.75441741 18,7.57221913 C18,5.32648142 17.0746546,3.2968697 15.5844483,1.84386848 L16.998692,0.429504359 Z M5.47615648,2.90478899 L6.89053056,4.31933366 C6.0336839,5.13863285 5.5,6.29310171 5.5,7.57221913 C5.5,8.78797821 5.98212351,9.8911313 6.76560859,10.7009165 L5.35100268,12.1150001 C4.20580296,10.943193 3.5,9.34013064 3.5,7.57221913 C3.5,5.74077487 4.2574428,4.08624085 5.47615648,2.90478899 Z M14.5238435,2.90478899 C15.7425572,4.08624085 16.5,5.74077487 16.5,7.57221913 C16.5,9.34013064 15.794197,10.943193 14.6489973,12.1150001 L13.2343914,10.7009165 C14.0178765,9.8911313 14.5,8.78797821 14.5,7.57221913 C14.5,6.29310171 13.9663161,5.13863285 13.1094694,4.31933366 L14.5238435,2.90478899 Z M10,4.57221913 C11.6568542,4.57221913 13,5.91536488 13,7.57221913 C13,9.22907338 11.6568542,10.5722191 10,10.5722191 C8.34314575,10.5722191 7,9.22907338 7,7.57221913 C7,5.91536488 8.34314575,4.57221913 10,4.57221913 Z"
                                fill="#AAA"
                                fill-rule="nonzero"
                            />
                        </svg>{' '}
                        Stream on my channel
                    </li>
                )}
                <li class={style['action-menu-item']}>
                    <Download
                        ui="both"
                        blobName={blobName}
                        align="left"
                        display="transparent"
                        environment="dark"
                        displayName={displayName}
                        onDownloadStarted={onDownloadStarted}
                        onDownloadCompleted={onDownloadCompleted}
                        onDownloadError={onDownloadError}
                        size={size}
                        disabled={false}
                        fileType={fileType}
                    />
                </li>
                <li class={style['action-menu-item']}>
                    <Delete
                        ui="both"
                        blobName={blobName}
                        align="left"
                        display="transparent"
                        environment="dark"
                        action={onDeleteConfirm}
                        displayName={displayName}
                        onDeleteSuccess={onClose}
                        fileType={fileType}
                    />
                </li>
            </ul>
        </div>
    );
};

export default ActionMenu;
