import { FunctionalComponent, h, JSX } from 'preact';
// import { buildClasses } from '../../../utils';
import style from './style.scss';

interface Props {
    children: JSX.Element | JSX.Element[];
    className?: string;
    title: string;
}

const PopUpPanel: FunctionalComponent<Props> = (
    { children, className = '', title }: Props,
    ref: any,
) => {
    return (
        <div class={`${style['popup-panel-wrapper']} ${className}`}>
            <div class={style['popup-panel-title']}>{title}</div>
            {children}
        </div>
    );
};

export default PopUpPanel;
