import { FunctionalComponent, h, Fragment } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import ProgressIcon from '../progressIcon';
import CloudIcon from '../cloudIcon';
import Delete from '../delete';
import { UploadFile, handleUploadSuccess } from '../upload';
import Download from '../download';
import { formatTime, buildClasses } from '../../../../utils';
import style from './style.scss';

interface Props {
    file?: DBFile;
    tempFile?: UploadFile;
    isTempFile?: boolean;
    bytesTransferred?: number;
    showActions?: boolean;
    showInfo?: boolean;
    onDeleteConfirm?: (
        blobName: string,
        displayName: string,
        fileType: FileType,
        onDeleteSuccess: () => void,
    ) => void;
    onDownloadStarted?: () => void;
    onDownloadCompleted?: () => void;
    onDownloadError?: () => void;
    downloadInProgress?: boolean;
    onSelectVideo?: (file: DBFile) => void;
    observer?: IntersectionObserver;
    isMobileDevice: boolean;
    handleUploadError: (error: Error) => void;
    onToggleMobileAction: (
        file: DBFile,
        fileType: FileType,
        topPos: number,
    ) => void;
}

const VideoPreview: FunctionalComponent<Props> = ({
    file = {
        thumbnailUrl: '',
        duration: 0,
        preview: '',
        blobName: '',
        displayName: '',
        size: 0,
        creationDate: '',
        feature: 'none',
    },
    isTempFile = false,
    tempFile,
    showActions = true,
    showInfo = false,
    onDeleteConfirm,
    downloadInProgress = false,
    onDownloadStarted = () => {},
    onDownloadCompleted = () => {},
    onDownloadError = () => {},
    onSelectVideo,
    observer,
    isMobileDevice,
    handleUploadError,
    onToggleMobileAction,
}: Props) => {
    const { thumbnailUrl, duration, preview, blobName, displayName, size } =
        file;
    const containerRef = useRef<HTMLDivElement>(null);
    const actionMenuDotsRef = useRef<HTMLDivElement>(null);
    const [actionsMenuActive, setActionsMenuActive] = useState<boolean>(false);
    const [actionsMenuPos, setActionsMenuPos] = useState<'left' | 'right'>(
        'left',
    );
    const itemDuration = (
        <span class={style['item-duration']}>{formatTime(duration)}</span>
    );

    useEffect(() => {
        window.addEventListener('resize', determineMenuPosition);
    }, []);

    useEffect(() => {
        if (containerRef.current && !isTempFile && observer) {
            observer.observe(containerRef.current);
        }
    }, [containerRef]);

    useEffect(() => {
        if (actionMenuDotsRef.current) {
            determineMenuPosition();
            actionMenuDotsRef.current.addEventListener(
                'mouseenter',
                openActionMenu,
            );
        }
    }, [actionMenuDotsRef]);

    useEffect(() => {
        if (actionsMenuActive) {
            setTimeout(() => {
                setActionsMenuActive(false);
            }, 3000);
            // timer();

            // return clearTimeout(timer);
        }
    }, [actionsMenuActive]);

    const itemClasses = buildClasses({
        [style.item]: true,
        [style.temp]: isTempFile,
    });

    if (isTempFile && tempFile) {
        const srcFile = URL.createObjectURL(tempFile!.file);
        return (
            <div class={itemClasses}>
                <span class={style.status}>
                    <ProgressIcon
                        size={tempFile!.file.size}
                        uploadTask={tempFile!.uploadTask}
                        startTime={tempFile!.startTime}
                        onUploadError={handleUploadError}
                        onUploadSuccess={handleUploadSuccess}
                        fileName={tempFile.blobName}
                    />
                </span>
                <video src={srcFile} />
            </div>
        );
    }

    function determineMenuPosition() {
        if (!actionMenuDotsRef.current) {
            return;
        }

        if (
            actionMenuDotsRef.current.getBoundingClientRect().left <
            window.innerWidth / 2
        ) {
            setActionsMenuPos('right');
        } else {
            setActionsMenuPos('left');
        }
    }

    function openActionMenu(event: Event) {
        event.stopPropagation();
        setActionsMenuActive(!actionsMenuActive);
    }

    function renderActions() {
        if (!file || !onSelectVideo) {
            return;
        }
        const itemActionsClasses = buildClasses({
            [style['item-actions']]: !isMobileDevice,
            [style['mobile-actions']]: isMobileDevice,
            [style.active]: actionsMenuActive,
        });

        if (isMobileDevice) {
            return (
                <div
                    class={itemActionsClasses}
                    onClick={event => {
                        const target = event.target as HTMLElement;
                        const pos = target.getBoundingClientRect();
                        onToggleMobileAction(file, 'video', pos.y);
                    }}
                >
                    <span>
                        <CloudIcon className={style['uploaded-icon']} />
                    </span>
                </div>
            );
        }

        return (
            <div
                class={itemActionsClasses}
                onClick={() => {
                    onSelectVideo(file);
                }}
            >
                <div
                    class={`${style['action-menu-container']} ${style['detail-element']}`}
                    ref={actionMenuDotsRef}
                    onClick={openActionMenu}
                >
                    <span class={style['action-menu-text']}>Actions</span>
                    <span class={style['action-menu-icon']}>
                        <span class={style['action-menu-dot']}></span>
                        <span class={style['action-menu-dot']}></span>
                        <span class={style['action-menu-dot']}></span>
                    </span>
                </div>
                <span class={style['detail-element']}>
                    <CloudIcon className={style['uploaded-icon']} />
                </span>
                <div class={style['item-actions-cta']}>
                    Stream on my channel
                </div>
                <span class={style['item-duration']}>{itemDuration}</span>
            </div>
        );
    }

    function renderInfo() {
        return (
            <Fragment>
                <span class={style['cloud-icon']}>
                    <CloudIcon className={style['uploaded-icon']} />
                </span>
                {itemDuration}
            </Fragment>
        );
    }

    function renderActionMenu() {
        const actionMenuClasses = buildClasses({
            [style['action-menu']]: true,
            [style.active]: actionsMenuActive,
        });
        return (
            <ul
                class={actionMenuClasses}
                style={
                    actionsMenuPos === 'left'
                        ? { left: 'auto', right: '20px' }
                        : { left: 'calc(100% - 10px)', right: 'auto' }
                }
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                <li class={style['item-title']}>{displayName}</li>
                <li>
                    <Download
                        ui="both"
                        blobName={blobName}
                        align="left"
                        displayName={displayName}
                        onDownloadStarted={onDownloadStarted}
                        onDownloadCompleted={onDownloadCompleted}
                        onDownloadError={onDownloadError}
                        size={size}
                        disabled={downloadInProgress}
                        fileType="video"
                        display="transparent"
                    />
                </li>
                <li>
                    <Delete
                        ui="both"
                        blobName={blobName}
                        align="left"
                        action={onDeleteConfirm!}
                        displayName={displayName}
                        onDeleteSuccess={() => {}}
                        fileType="video"
                        display="transparent"
                    />
                </li>
            </ul>
        );
    }

    return (
        <Fragment>
            <div class={itemClasses} ref={containerRef}>
                {showActions && renderActions()}
                {showInfo && renderInfo()}
                <video
                    src={preview}
                    poster={thumbnailUrl}
                    muted
                    autoPlay
                    loop
                />
            </div>
            {renderActionMenu()}
        </Fragment>
    );
};

export default VideoPreview;
