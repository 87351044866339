import { FunctionalComponent, h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { UploadTask } from 'firebase/storage';
import { buildClasses, timeString } from '../../../../utils';
import style from './style.scss';

interface Props {
    size: number;
    uploadTask: any;
    onUploadError: (error: Error) => void;
    onUploadSuccess: (uploadTask: UploadTask) => void;
    startTime: Date;
    fileName: string;
}

const ProgressIcon: FunctionalComponent<Props> = ({
    uploadTask,
    size,
    onUploadError,
    onUploadSuccess,
    startTime,
    fileName,
}: Props) => {
    const [percentDone, setPercentDone] = useState<number>(0);
    const [estimatedTimeRemaining, setEstimatedTimeRemaining] =
        useState<number>(0);

    useEffect(() => {
        if (percentDone > 0) {
            const elapsedTime =
                -1 * (startTime.getTime() - new Date().getTime());
            const estimatedTotalTime = elapsedTime / (percentDone / 100);
            const estimatedMsRemaining = estimatedTotalTime - elapsedTime;
            const estimatedSecondsRemaining = Math.ceil(
                estimatedMsRemaining / 1000,
            );
            setEstimatedTimeRemaining(estimatedSecondsRemaining);
        }
    }, [percentDone]);

    uploadTask.on(
        'state_change',
        (snapshot: any) => {
            setPercentDone(
                Math.floor((snapshot.bytesTransferred / size) * 100),
            );
        },
        (error: Error) => {
            onUploadError(error);
        },
        () => {
            onUploadSuccess(uploadTask);
        },
    );

    const withBar = percentDone >= 1 && percentDone < 100;

    const progressClasses = buildClasses({
        [style.progress]: true,
        [style['with-bar']]: withBar,
    });

    function cancelUpload() {
        uploadTask.cancel();
    }

    return (
        <div class={progressClasses}>
            <div class={style.cloud}>
                <svg width="42" height="28" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <filter
                            x="-21.1%"
                            y="-37.5%"
                            width="142.2%"
                            height="175%"
                            filterUnits="objectBoundingBox"
                        >
                            <feMorphology
                                radius=".75"
                                operator="dilate"
                                in="SourceAlpha"
                                result="shadowSpreadOuter1"
                            />
                            <feOffset
                                in="shadowSpreadOuter1"
                                result="shadowOffsetOuter1"
                            />
                            <feMorphology
                                radius="1"
                                in="SourceAlpha"
                                result="shadowInner"
                            />
                            <feOffset in="shadowInner" result="shadowInner" />
                            <feComposite
                                in="shadowOffsetOuter1"
                                in2="shadowInner"
                                operator="out"
                                result="shadowOffsetOuter1"
                            />
                            <feGaussianBlur
                                stdDeviation="2"
                                in="shadowOffsetOuter1"
                                result="shadowBlurOuter1"
                            />
                            <feColorMatrix
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                                in="shadowBlurOuter1"
                            />
                        </filter>
                        <clipPath id="cloudPath">
                            <path
                                d="M27.052 7.906v-.683c.022-3.392-2.684-6.335-6.49-7.058-3.807-.722-7.675.974-9.275 4.066-1.37-.762-3.106-.816-4.534-.142-1.429.674-2.324 1.97-2.339 3.386-2.986.772-4.843 3.362-4.329 6.037.514 2.676 3.237 4.599 6.35 4.483H26.02c3.075.07 5.683-1.955 5.956-4.623.272-2.669-1.884-5.062-4.925-5.466Z"
                                id="cloud-upload-outline"
                            />
                        </clipPath>
                    </defs>
                    <g
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        transform="translate(5 5)"
                        fill="none"
                        fill-rule="evenodd"
                    >
                        <use
                            fill="#000"
                            filter="url(#b)"
                            xlinkHref="#cloud-upload-outline"
                        />
                        <use
                            stroke="#FFF"
                            stroke-width="1.5"
                            xlinkHref="#cloud-upload-outline"
                        />
                    </g>
                </svg>
                {(withBar || percentDone >= 100) && (
                    <span class={style['cloud-progress-fill']}></span>
                )}
            </div>
            <button onClick={cancelUpload} class={style['cancel-upload-btn']}>
                <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M21.753 1.247a1.5 1.5 0 0 1 0 2.121L13.621 11.5l8.132 8.132a1.5 1.5 0 1 1-2.121 2.121L11.5 13.621l-8.132 8.132a1.5 1.5 0 1 1-2.121-2.121L9.379 11.5 1.247 3.368a1.5 1.5 0 1 1 2.121-2.121L11.5 9.379l8.132-8.132a1.5 1.5 0 0 1 2.121 0Z"
                        fill="#FFF"
                        fill-rule="evenodd"
                    />
                </svg>
            </button>
            <span class={style['progress-text']}>
                {withBar
                    ? 'Uploading . . .'
                    : percentDone >= 100
                    ? 'Processing . . .'
                    : 'Waiting . . .'}
            </span>
            {withBar && (
                <Fragment>
                    <div class={style['progress-track']}>
                        <span
                            class={style['progress-bar']}
                            style={{ width: `${percentDone}%` }}
                        ></span>
                    </div>
                    {estimatedTimeRemaining > 0 && (
                        <span class={style['progress-time']}>
                            {timeString(estimatedTimeRemaining)} left
                        </span>
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default ProgressIcon;
