// @ts-ignore
const apiSrc = 'https://apis.google.com/js/api.js';
// @ts-ignore
// const gsiSrc = 'https://accounts.google.com/gsi/client';
export const loadGoogleScripts = (cb: any) => {
	(function () {
		const id = 'google-js';
		const src = apiSrc;
		const firstJs = document.getElementsByTagName('script')[0];
		if (!firstJs) {
			return;
		}

		if (document.getElementById(id)) {
			return;
		}
		const js = document.createElement('script');
		js.id = id;
		js.src = src;
		js.onload = cb;
		// @ts-ignore
		firstJs.parentNode.insertBefore(js, firstJs);
	})();
};
