import { FunctionalComponent, h, JSX } from 'preact';
import { forwardRef } from 'preact/compat';
import { buildClasses } from '../../../utils';
import style from './style.scss';

interface Props {
    buttonColor?:
        | 'light'
        | 'dark'
        | 'dark-translucent'
        | 'transparent'
        | 'outline';
    buttonStyle:
        | 'pill'
        | 'icon'
        | 'icon-circle'
        | 'rectangle'
        | 'cta'
        | 'text'
        | 'confirm';
    disabled?: boolean;
    handleClick: () => void;
    hideLabel?: boolean;
    icon?: JSX.Element;
    iconPosition?: 'left' | 'right' | 'top' | 'full';
    label: string;
    className?: string;
    // isActive: boolean;
    // name: string;
    // activeIcon?: JSX.Element;
    // inactiveIcon?: JSX.Element;
    // buttonStyle?: 'pill' | 'circle' | 'stack' | 'tile';
    // withBg?: boolean;
    // withShadow?: boolean;
    // size?: 'small' | 'medium' | 'large';
    // showText?: boolean;
}

const Button: FunctionalComponent<Props> = (
    {
        buttonColor = 'light',
        buttonStyle,
        disabled = false,
        handleClick,
        hideLabel = false,
        icon,
        iconPosition = 'full',
        label,
        className = '',
    }: // isActive,
    // name,
    // activeIcon,
    // inactiveIcon,
    // buttonStyle = 'circle',
    // withBg = false,
    // withShadow = false,
    // size = 'small',
    // buttonColor = 'light',
    // showText = true,
    Props,
    ref,
) => {
    const buttonClasses = buildClasses({
        [style['control-button']]: true,
        [style[buttonStyle]]: true,
        [style['hide-label']]: hideLabel,
        [style[`icon-${iconPosition}`]]: !!icon,
        //     [style[size]]: true,
        [style[buttonColor]]: true,
        [className]: true,
        //     [style.active]: isActive,
        //     [style['with-shadow']]: withShadow,
        //     [style['with-bg']]: withBg,
        //     [style['hidden-text']]: !showText,
    });

    // const Icon = () => {
    //     // if (!isActive && inactiveIcon) {
    //     //     return inactiveIcon;
    //     // }
    //     // if (activeIcon) {
    //     //     return activeIcon;
    //     // }
    //     if (!icon) {
    //         return null;
    //     }
    //     return icon;
    // };

    return (
        <button
            type="button"
            onClick={handleClick}
            class={buttonClasses}
            disabled={disabled}
            ref={ref}
        >
            {icon && icon}
            <span class={style['button-text']}>{label}</span>
        </button>
    );
};

export default forwardRef(Button);
