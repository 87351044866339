import { FunctionalComponent, h } from 'preact';
import Upload from '../upload';
import style from './style.scss';

interface Props {
    userId: string;
    onFilesChosen: (event: Event) => void;
}

const Footer: FunctionalComponent<Props> = ({
    onFilesChosen,
    userId,
}: Props) => {
    return (
        <footer class={style.footer}>
            <Upload userId={userId} onFilesChosen={onFilesChosen} />
        </footer>
    );
};

export default Footer;
