// from packages
import { Fragment, FunctionalComponent, h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';

// constants, enums, types
import { CONTENT_LAYOUT } from '../../enums';

// utils
import { buildClasses } from '../../../../utils';

// components
// import Button from '../../../common/button';
import { RecordAppContext } from '../../index';

// assets
import ReturnArrow from '../../../../assets/images/return-arrow.svg';
import InvertIcon from '../../../../assets/images/invert-hotkey-icon.svg';

//styles
import style from './style.scss';

interface Props {
    keys?: Key[];
}

interface Key {
    disabled?: boolean;
    display: string | JSX.Element;
    hidden?: boolean;
    isSquare: boolean;
    keyCode: number;
    label: string;
    onKeyDown: () => void;
    onKeyUp: () => void;
}

const ReactionKeyboardShortcuts: FunctionalComponent<Props> = ({}: // keys,
Props) => {
    const {
        activeItem,
        activeItemDisplay,
        contentLayout,
        focusFlipped,
        handleFitFillButtonClick,
        handleToggleInvert,
        handleNext,
        handlePrev,
        handleRecordingButtonClick,
        handleTogglePlay,
        playlistItems,
    } = useContext(RecordAppContext);
    const hotkeysOpen = true;
    const [keys, setKeys] = useState<Key[]>([]);
    const [activeKey, setActiveKey] = useState<number | null>(null);
    const keyCodes = keys.map(key => key.keyCode);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, true);
        document.addEventListener('keyup', handleKeyUp, true);
        return () => {
            document.removeEventListener('keydown', handleKeyDown, true);
            document.removeEventListener('keyup', handleKeyUp, true);
        };
    }, [keys]);

    useEffect(() => {
        setKeys([
            {
                disabled:
                    !activeItemDisplay || !('currentTime' in activeItemDisplay),
                display: <Fragment>&rarr;</Fragment>,
                isSquare: true,
                keyCode: 39,
                label: '+5 sec',
                onKeyDown: () => {
                    if (
                        !activeItemDisplay ||
                        !('currentTime' in activeItemDisplay)
                    ) {
                        return;
                    }
                    activeItemDisplay.currentTime =
                        activeItemDisplay.currentTime + 5;
                },
                onKeyUp: () => {},
            },
            {
                disabled:
                    !activeItemDisplay || !('currentTime' in activeItemDisplay),
                display: <Fragment>&larr;</Fragment>,
                isSquare: true,
                keyCode: 37,
                label: '-5 sec',
                onKeyDown: () => {
                    if (
                        !activeItemDisplay ||
                        !('currentTime' in activeItemDisplay)
                    ) {
                        return;
                    }
                    activeItemDisplay.currentTime =
                        activeItemDisplay.currentTime - 5;
                },
                onKeyUp: () => {},
            },
            {
                disabled: !playlistItems.length,
                display: '>',
                isSquare: true,
                keyCode: 190,
                label: 'Next',
                onKeyDown: handleNext,
                onKeyUp: () => {},
            },
            {
                disabled: !playlistItems.length,
                display: '<',
                isSquare: true,
                keyCode: 188,
                label: 'Previous',
                onKeyDown: handlePrev,
                onKeyUp: () => {},
            },
            {
                disabled: !activeItem,
                display: 'F',
                isSquare: true,
                keyCode: 70,
                label: 'Fit / Fill',
                onKeyDown: handleFitFillButtonClick,
                onKeyUp: () => {},
            },
            {
                disabled:
                    !activeItem ||
                    contentLayout !== CONTENT_LAYOUT.CIRCLE ||
                    focusFlipped,
                display: <InvertIcon />,
                isSquare: true,
                keyCode: 73,
                label: 'Invert',
                onKeyDown: handleToggleInvert,
                onKeyUp: () => {},
            },
            {
                disabled: !activeItemDisplay || !('muted' in activeItemDisplay),
                display: 'M',
                isSquare: true,
                keyCode: 77,
                label: 'Mute Video',
                onKeyDown: () => {
                    if (!activeItemDisplay || !('muted' in activeItemDisplay)) {
                        return;
                    }
                    activeItemDisplay.muted = !activeItemDisplay.muted;
                },
                onKeyUp: () => {},
            },
            {
                display: <ReturnArrow />,
                isSquare: false,
                keyCode: 13,
                label: 'Rec / Stop',
                onKeyDown: handleRecordingButtonClick,
                onKeyUp: () => {},
            },
            {
                display: 'Space',
                isSquare: false,
                keyCode: 32,
                label: 'Pause',
                onKeyDown: () => {
                    handleTogglePlay({ pausePlayClick: true });
                },
                onKeyUp: () => {},
            },
            {
                hidden: true,
                disabled: true,
                display: 'R',
                isSquare: true,
                keyCode: 82,
                label: 'Hotkeys',
                onKeyDown: () => {},
                onKeyUp: () => {},
            },
        ]);
    }, [
        handleFitFillButtonClick,
        handleNext,
        handlePrev,
        handleRecordingButtonClick,
        handleTogglePlay,
        // toggleHotkeysOpen,
    ]);

    // function closeHotkeys(e: MouseEvent) {
    //     const { target } = e;

    //     if (
    //         target &&
    //         (target as HTMLElement).closest(`.${style['shortcuts-wrapper']}`)
    //     ) {
    //         return null;
    //     } else {
    //         setHotkeysOpen(false);
    //     }
    // }

    // function toggleHotkeysOpen() {
    //     setHotkeysOpen(!hotkeysOpen);
    // }

    function handleKeyDown(event: KeyboardEvent) {
        const { keyCode, repeat } = event;
        setActiveKey(keyCode);

        if (event.ctrlKey || event.altKey || event.shiftKey) {
            return;
        }

        if (keyCodes.includes(keyCode)) {
            event.preventDefault();
            const key = keys.filter(key => key.keyCode === keyCode).at(0);
            if (repeat || !key || key.disabled) {
                return;
            }
            key.onKeyDown();
        }
    }

    function handleKeyUp(event: KeyboardEvent) {
        setActiveKey(null);
        const { keyCode } = event;
        if (keyCodes.includes(keyCode)) {
            event.preventDefault();
            keys.filter(key => key.keyCode === keyCode)
                .at(0)
                ?.onKeyUp();
        }
    }

    return (
        <div class={style['shortcuts-wrapper']}>
            {hotkeysOpen && (
                <ul class={style['keyboard-shortcuts']}>
                    {keys.map(key => {
                        const {
                            disabled,
                            hidden,
                            isSquare,
                            keyCode,
                            onKeyDown,
                            display,
                            label,
                        } = key;
                        const keyClasses = buildClasses({
                            [style.key]: true,
                            [style.square]: isSquare,
                        });
                        const containerClasses = buildClasses({
                            [style['key-container']]: true,
                            [style.active]: !disabled && activeKey === keyCode,
                            [style.disabled]: !!disabled,
                        });
                        if (hidden) {
                            return null;
                        }
                        return (
                            <li class={containerClasses} onClick={onKeyDown}>
                                <div class={keyClasses}>{display}</div>
                                <span class={style['key-label']}>{label}</span>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default ReactionKeyboardShortcuts;
