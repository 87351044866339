import { logEvent, setUserId } from 'firebase/analytics';
import { analyticsDb } from './firebase';
import { WEB_REACTS_EVENTS } from '../components/Record/enums';

interface LogEventProps {
  data?: string;
  type: WEB_REACTS_EVENTS;
  userId: string;
  videoSessionId: string;
}

export function logWebReactsEvent({
  data,
  type,
  userId,
  videoSessionId,
}: LogEventProps) {
  setUserId(analyticsDb, userId);
  try {
    console.log('logging with', { data, type, userId, videoSessionId });
    logEvent(analyticsDb, type, {
      data: data || '',
      videoSessionId,
    });
  } catch (e) {
    console.log('error trying to log web reacts event', e);
  }
}
