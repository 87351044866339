// https://github.com/react-dropzone/react-dropzone/blob/master/src/index.js for some inspiration
import { FunctionalComponent, h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { buildClasses, deviceCanUseMouse } from '../../../utils';
import style from './style.scss';

interface Props {
    acceptedFileTypes: string[];
    handleDragCancel: () => void;
    handleDragEnd: () => void;
    handleDragStart: () => void;
    handleFilesDropped: (files: FileList) => void;
    handleFolderDropped: () => void;
}

const Dropzone: FunctionalComponent<Props> = ({
    acceptedFileTypes,
    handleDragCancel,
    handleDragEnd,
    handleDragStart,
    handleFilesDropped,
    handleFolderDropped,
}: Props) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const dragTargetsRef = useRef<HTMLElement[]>([]);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [files, setFiles] = useState<FileList>();

    useEffect(() => {
        document.addEventListener('dragenter', handleDragEnter);
        document.addEventListener('dragover', handleDragOver);
        document.addEventListener('drop', handleDrop);
        document.addEventListener('dragleave', handleDragLeave);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('dragenter', handleDragEnter);
            document.removeEventListener('dragover', handleDragOver);
            document.removeEventListener('drop', handleDrop);
            document.removeEventListener('dragleave', handleDragLeave);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        if (files) {
            if (files.length > 0) {
                handleFilesDropped(files);
            }
        }
    }, [files]);

    useEffect(() => {
        if (isDragging) {
            handleDragStart();
        } else {
            handleDragEnd();
        }
    }, [isDragging]);

    function handleKeyDown(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            endDragging();
            handleDragCancel();
        }
    }

    function handleDragEnter(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        if (event.target) {
            dragTargetsRef.current = [
                ...dragTargetsRef.current,
                event.target as HTMLElement,
            ];
        }
        setIsDragging(true);
    }

    function handleDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        // setIsDragging(true);
        // dragCounter.current = dragCounter.current + 1;
    }

    function handleDragLeave(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        const targets = dragTargetsRef.current.filter(
            target => rootRef.current && rootRef.current.contains(target),
        );
        const targetIdx = targets.indexOf(event.target as HTMLElement);
        if (targetIdx !== -1) {
            targets.splice(targetIdx, 1);
        }
        dragTargetsRef.current = targets;
        if (targets.length > 0) {
            return;
        } else {
            endDragging();
        }
    }

    function handleDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        handleFileSelection(event);
        endDragging();
    }

    function endDragging() {
        dragTargetsRef.current = [];
        setIsDragging(false);
    }

    // function isValidVideoType(file: File) {
    //     return file.type.includes('video') && file.type !== 'video/webm';
    // }

    // function isValidAudioType(file: File) {
    //     return file.type.includes('audio');
    // }

    // function isValidImageType(file: File) {
    //     return file.type.includes('image');
    // }

    function isValidFileType(item: DataTransferItem) {
        if (item.webkitGetAsEntry) {
            // this is implemented in non-webkit browsers, possible renaming in the future
            const entry = item.webkitGetAsEntry();
            if (entry) {
                if (entry.isDirectory) {
                    handleFolderDropped();
                    return false;
                }
            }
        }

        const file = item.getAsFile();
        if (file) {
            if (acceptedFileTypes.indexOf(file.type) > -1) {
                return true;
            }
        }
        handleFolderDropped();
        return false;
    }

    function handleFileSelection(event: DragEvent) {
        const { dataTransfer } = event;
        const droppedFiles = [];

        if (dataTransfer) {
            for (let i = 0; i < dataTransfer.items.length; i++) {
                if (isValidFileType(dataTransfer.items[i])) {
                    droppedFiles.push(dataTransfer.items[i].getAsFile());
                }
            }
        }
        if (droppedFiles.length > 0) {
            // @ts-ignore TODO!
            setFiles(droppedFiles);
        } else {
            endDragging();
            handleDragCancel();
        }
    }

    const dropzoneClasses = buildClasses({
        [style.dropzone]: true,
        [style['drag-active']]: isDragging,
    });

    if (!deviceCanUseMouse) {
        return null;
    }
    return <div ref={rootRef} class={dropzoneClasses}></div>;
};

export default Dropzone;
