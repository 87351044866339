import { FunctionalComponent, h } from 'preact';

interface Props {
    className?: string;
}

const CloudIcon: FunctionalComponent<Props> = ({ className = '' }: Props) => {
    return (
        <svg
            class={className}
            width="40"
            height="27"
            xmlns="http://www.w3.org/2000/svg"
        >
            <defs>
                <filter
                    x="-18.6%"
                    y="-32.2%"
                    width="137.1%"
                    height="164.4%"
                    filterUnits="objectBoundingBox"
                    id="a"
                >
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur
                        stdDeviation="2"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                    />
                    <feColorMatrix
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                        in="shadowBlurOuter1"
                    />
                </filter>
                <path
                    d="M27.548 24.262c1.616-3.203 5.52-4.958 9.363-4.21 3.843.748 6.575 3.796 6.552 7.308v.706c3.07.42 5.248 2.897 4.973 5.66-.276 2.763-2.909 4.86-6.013 4.788H22.649c-3.142.12-5.891-1.871-6.41-4.642-.519-2.77 1.356-5.452 4.37-6.252.015-1.465.919-2.807 2.361-3.505a4.977 4.977 0 0 1 4.578.147Zm9.379 2.203a.893.893 0 0 0-1.284.06l-4.693 5.269-2.224-1.961-.133-.098a.893.893 0 0 0-1.148.197.949.949 0 0 0 .096 1.314l2.894 2.552.13.096a.893.893 0 0 0 1.133-.175l5.287-5.937.1-.134a.95.95 0 0 0-.158-1.183Z"
                    id="b"
                />
            </defs>
            <g
                transform="translate(-12.342 -16)"
                fill="none"
                fillRule="evenodd"
            >
                <use fill="#000" filter="url(#a)" xlinkHref="#b" />
                <use fill="#FFF" xlinkHref="#b" />
            </g>
        </svg>
    );
};

export default CloudIcon;
