// from packages
import { FunctionalComponent, h } from 'preact';
import {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'preact/hooks';

// constants, enums, types
import { ITEM_FOCUS_TIMEOUT } from '../../constants';
import { PlaylistItemFile } from '../../types';

// utils
// import { buildClasses } from '../../utils';

// components
import PlaylistItem from '../playlistItem';
import { RecordAppContext } from '../../index';

// assets

//styles
import style from './style.scss';

interface Props {}

const Playlist: FunctionalComponent<Props> = ({}: Props) => {
    const { activeItem, handleSetActiveItem, playlistItems } =
        useContext(RecordAppContext);
    const activeItemRef = useCallback((el: any) => {
        if (el) {
            new ResizeObserver(() => {
                if (playlistWrapperRef.current) {
                    const left = el.offsetLeft + el.offsetWidth / 2;
                    playlistWrapperRef.current.scrollTo({
                        top: 0,
                        left: el.offsetLeft + el.offsetWidth / 2 + 25,
                        behavior: 'smooth',
                    });
                    setMarginLeft(`${left}px`);
                    setScrolled(true);
                }
            }).observe(el);
        }
    }, []);
    const playlistWrapperRef = useRef<HTMLDivElement>(null);
    const playlistItemsRef = useRef<HTMLUListElement>(null);
    const [focusItem, setFocusItem] = useState<PlaylistItemFile | null>(null);
    const [scrolled, setScrolled] = useState<boolean>(false);
    const [marginLeft, setMarginLeft] = useState<string>('');

    useEffect(() => {
        let ignore = false;
        const removeFocus = () => {
            if (ignore) {
                return;
            }
            setFocusItem(null);
        };
        if (focusItem) {
            setTimeout(removeFocus, ITEM_FOCUS_TIMEOUT);
        }

        return () => {
            ignore = true;
        };
    }, [focusItem]);

    useEffect(() => {
        if (
            playlistItemsRef.current &&
            playlistWrapperRef.current &&
            playlistItems.length > 0 &&
            !scrolled
        ) {
            playlistWrapperRef.current.scrollLeft = 50;
        }
    }, [playlistItems]);
    // const tempItems = [
    // 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    // 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    // 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
    // 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    // ].map(i => {
    // return { itemIndex: i, file: {} };
    // });

    return (
        <div class={style['playlist-container']}>
            <div class={style['playlist-wrapper']} ref={playlistWrapperRef}>
                {playlistItems.length > 0 && (
                    <ul
                        class={style['playlist-items']}
                        ref={playlistItemsRef}
                        style={{ marginLeft: `calc(50% - ${marginLeft})` }}
                    >
                        {playlistItems.map(item => {
                            if (item) {
                                return (
                                    <PlaylistItem
                                        key={item.itemIndex}
                                        item={item}
                                        handleClick={() => {
                                            if (activeItem === item) {
                                                return;
                                            }
                                            if (focusItem === item) {
                                                handleSetActiveItem({ item });
                                                setFocusItem(null);
                                            } else {
                                                setFocusItem(item);
                                            }
                                        }}
                                        isFocussed={focusItem === item}
                                        isActive={activeItem === item}
                                        {...(activeItem === item
                                            ? { ref: activeItemRef }
                                            : {})}
                                    />
                                );
                            }
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};
// <span class={style['playlist-curtain']}></span>

export default Playlist;
