// from packages
import { FunctionalComponent, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

// constants, enums, types

// utils
import { buildClasses } from '../../../../utils';

// components
import AudioMeter from '../audioMeter';

// assets

//styles
import style from './style.scss';

interface Props {
    devices: MediaDeviceInfo[];
    selectedDeviceId?: string;
    onSelectDevice: ({
        device,
    }: {
        device: MediaDeviceInfo | InputDeviceInfo;
    }) => void;
    listIsOpen: boolean;
    onSetActiveList: () => void;
    icon: JSX.Element;
    showAudioMeter?: boolean;
}

const DevicesList: FunctionalComponent<Props> = ({
    devices,
    icon,
    listIsOpen,
    onSelectDevice,
    onSetActiveList,
    selectedDeviceId,
    showAudioMeter,
}: Props) => {
    const [listOpen, setListOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!listIsOpen && listOpen) {
            closeList();
        } else if (listIsOpen && !listOpen) {
            openList();
        }
    }, [listIsOpen]);

    useEffect(() => {
        listOpen && onSetActiveList();
    }, [listOpen]);

    function toggleList() {
        setListOpen(!listOpen);
    }

    function openList() {
        setListOpen(true);
    }

    function closeList() {
        setListOpen(false);
    }

    function renderDevice(device: MediaDeviceInfo) {
        const isActiveDevice = device.deviceId === selectedDeviceId;
        return (
            <li
                class={`${style['device-option']} ${
                    isActiveDevice ? style.selected : ''
                }`}
                onClick={e => {
                    onSelectDevice({ device });
                }}
            >
                <span class={style['device-label']}>
                    {device.label.split('(')[0]}
                </span>
                {isActiveDevice && showAudioMeter && <AudioMeter />}
            </li>
        );
    }

    const listClasses = buildClasses({
        [style['device-option-list']]: true,
        [style.active]: listOpen,
        [style['cant-open']]: devices.length < 2,
    });

    return (
        <div class={style['device-list-wrapper']}>
            {icon}
            <ul class={listClasses} onClick={toggleList}>
                {devices.map(renderDevice)}
            </ul>
        </div>
    );
};

export default DevicesList;
