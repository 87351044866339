// from packages
import { FunctionalComponent, h } from 'preact';

// constants, enums, types

// utils

// components
import Button from '../../../common/button';
import Modal from '../../../common/modal';

// assets

//styles
import style from './style.scss';

interface Props {
	handleDeleteRecording: () => void;
	show: boolean;
	toggleDeleteRecordingConfirmation: () => void;
}

const DeleteRecordingConfirmationModal: FunctionalComponent<Props> = ({
	handleDeleteRecording,
	show,
	toggleDeleteRecordingConfirmation,
}: Props) => {
	if (!show) {
		return null;
	}

	return (
		<Modal
			onClose={toggleDeleteRecordingConfirmation}
			withOverlay={false}
			fullTakeover={false}
			className={style['delete-confirmation-modal']}
		>
			<div class={style['delete-confirmation-content']}>
				<div class={style['delete-confirmation-title']}>
					Delete This Recording?
				</div>
				<p>Start a new recording with selected media below?</p>
				<Button
					label="Delete and start over"
					handleClick={handleDeleteRecording}
					buttonStyle="confirm"
				/>
			</div>
		</Modal>
	);
};

export default DeleteRecordingConfirmationModal;
