import { FunctionalComponent, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { buildClasses } from '../../utils';
import style from './style.scss';

interface Props {
    googleAuth?: any;
    handleSignOut: () => void;
}

const UserMenu: FunctionalComponent<Props> = ({
    googleAuth,
    handleSignOut,
}: Props) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const closeMenu = (event: MouseEvent) => {
        const targetEl = event.target as HTMLElement;
        const targetClasses = targetEl.classList.value;
        if (
            menuOpen &&
            !targetClasses.includes(style.avatar) &&
            !targetClasses.includes(style['user-menu-item-content'])
        ) {
            setMenuOpen(!menuOpen);
        }
    };

    // useEffect(() => {
    //     if (!document.location.pathname.includes('/login')) {
    //         if (googleAuth?.currentUser?.get().getBasicProfile) {
    //             setSignedIn(true);
    //         }
    //     } else {
    //         setSignedIn(false);
    //     }
    // });

    useEffect(() => {
        if (menuOpen) {
            window.addEventListener('click', closeMenu, true);
        } else {
            window.removeEventListener('click', closeMenu, true);
        }
        return () => {
            window.removeEventListener('click', closeMenu, true);
        };
    }, [menuOpen]);

    function renderUserAvatar(click: boolean) {
        let profileImage = googleAuth?.currentUser
            .get()
            .getBasicProfile()
            .getImageUrl();
        let profileName =
            googleAuth?.currentUser.get().getBasicProfile().getName() || 'user';
        if (!profileImage) {
            profileImage = '../../assets/images/default-profile-image.png';
        }
        return (
            <img
                class={style.avatar}
                referrerpolicy="no-referrer"
                src={profileImage}
                alt={profileName}
                onClick={() => {
                    click && setMenuOpen(!menuOpen);
                }}
            />
        );
    }

    const navMenuClasses = buildClasses({
        [style['user-menu']]: true,
        [style['user-menu-open']]: menuOpen,
    });

    const userName = googleAuth.currentUser.get().getBasicProfile().getName();

    return (
        <div class={style['user-menu-container']}>
            <span class={style['user-menu-toggle']}>
                {renderUserAvatar(true)}
            </span>
            <ul class={navMenuClasses}>
                <li class={style['user-menu-item']}>
                    <span class={style['user-menu-item-content']}>
                        <span class={style['user-menu-item-icon']}>
                            {renderUserAvatar(false)}
                        </span>
                        Signed in{userName ? ` as ${userName}` : ''}
                    </span>
                </li>
                <li class={style['user-menu-item']}>
                    <button
                        type="button"
                        class={style['user-menu-item-content']}
                        onClick={handleSignOut}
                    >
                        <svg
                            class={style['user-menu-item-icon']}
                            width="17"
                            height="18"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g fill="#000" fill-rule="evenodd">
                                <path d="M8.5 3.55 12.949 8H13v.05l.45.45-.45.448V9h-.051L8.5 13.45l-.707-.707 3.742-3.744L0 9V8h11.535L7.793 4.256 8.5 3.55Z" />
                                <path d="M17 18H5v-1h11V1H5V0h12v18Z" />
                            </g>
                        </svg>
                        <span>Sign Out</span>
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default UserMenu;
