// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

// constants, enums, types

// utils

// components
import DevicesList from '../devicesList';
import { RecordAppContext } from '../../index';
import PopUpPanel from '../../../common/popupPanel';

// assets
import MicrophoneIcon from '../../../../assets/images/little-microphone-icon.svg';
import SpeakerIcon from '../../../../assets/images/little-speaker-icon.svg';
import CameraIcon from '../../../../assets/images/little-camera-icon.svg';

//styles
import style from './style.scss';

interface Props {}

const AVSettingsPanel: FunctionalComponent<Props> = ({}: Props) => {
	const {
		currentCameraDevice,
		currentMicDevice,
		currentSpeakerDevice,
		saveMicDevice,
		saveSpeakerDevice,
		saveCameraDevice,
		userDevices,
	} = useContext(RecordAppContext);
	function handleSelectAudioInputDevice({
		device,
	}: {
		device: InputDeviceInfo;
	}) {
		const { deviceId } = device;
		if (!saveMicDevice || !currentMicDevice) {
			return;
		}
		if (deviceId === currentMicDevice.deviceId) {
			return;
		} else {
			saveMicDevice({ deviceId });
		}
	}
	function handleSelectAudioOutputDevice({
		device,
	}: {
		device: MediaDeviceInfo;
	}) {
		const { deviceId } = device;
		if (!saveSpeakerDevice || !currentSpeakerDevice) {
			return;
		}
		if (deviceId === currentSpeakerDevice.deviceId) {
			return;
		} else {
			saveSpeakerDevice({ deviceId });
		}
	}
	function handleSelectVideoInputDevice({
		device,
	}: {
		device: InputDeviceInfo;
	}) {
		const { deviceId } = device;
		if (!saveCameraDevice || !currentCameraDevice) {
			return;
		}
		if (deviceId === currentCameraDevice.deviceId) {
			return;
		} else {
			saveCameraDevice({ deviceId });
		}
	}

	if (!userDevices) {
		return null;
	}

	return (
		<PopUpPanel title="Settings" className={style['av-settings-list']}>
			<ul>
				<li class={style['devices-list-container']}>
					<DevicesList
						devices={userDevices.audioinput || []}
						selectedDeviceId={currentMicDevice?.deviceId}
						onSelectDevice={handleSelectAudioInputDevice}
						listIsOpen={false}
						onSetActiveList={() => {}}
						icon={<MicrophoneIcon />}
						showAudioMeter={true}
					/>
				</li>
				<li class={style['devices-list-container']}>
					<DevicesList
						devices={userDevices.audiooutput || []}
						selectedDeviceId={currentSpeakerDevice?.deviceId}
						onSelectDevice={handleSelectAudioOutputDevice}
						listIsOpen={false}
						onSetActiveList={() => {}}
						icon={<SpeakerIcon />}
					/>
				</li>
				<li class={style['devices-list-container']}>
					<DevicesList
						devices={userDevices.videoinput || []}
						selectedDeviceId={currentCameraDevice?.deviceId}
						onSelectDevice={handleSelectVideoInputDevice}
						listIsOpen={false}
						onSetActiveList={() => {}}
						icon={<CameraIcon />}
					/>
				</li>
			</ul>
		</PopUpPanel>
	);
};

export default AVSettingsPanel;
