import { FunctionalComponent, h, JSX } from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';
import { buildClasses } from '../../../../utils';
import style from './style.scss';

export type LightboxKind =
	| 'audio folder'
	| 'images folder'
	| 'GIF folder'
	| 'gif folder';

export interface LightboxState {
	kind?: LightboxKind;
	open: boolean;
	contents: JSX.Element | null;
}

interface Props {
	contents: JSX.Element;
	onClose: () => void;
}

const Lightbox: FunctionalComponent<Props> = ({ contents, onClose }: Props) => {
	const [closing, setClosing] = useState<boolean>(false);
	const lightboxRef = useRef<HTMLDivElement>(null);
	const didMount = useRef<boolean>(false);

	useEffect(() => {
		if (didMount.current) {
			lightboxRef.current?.addEventListener(
				'animationend',
				closeLightbox,
			);
		} else {
			didMount.current = true;
		}
	});

	function fadeOutLightbox() {
		setClosing(true);
	}

	function closeLightbox() {
		if (closing) {
			onClose();
		}
	}

	function handleOverlayClick(event: Event) {
		const targetEl = event.target as HTMLElement;
		// TODO: this could be a better check
		if (targetEl.nodeName === 'DIV') {
			if (
				targetEl.classList.value === lightboxClasses ||
				targetEl.classList.value === style['lightbox-content']
			) {
				fadeOutLightbox();
			}
		}
	}

	const lightboxClasses = buildClasses({
		[style.lightbox]: true,
		[style.closing]: closing,
	});

	return (
		<div
			class={lightboxClasses}
			ref={lightboxRef}
			onClick={handleOverlayClick}
		>
			<button
				type="button"
				class={`${style['close-icon']} ${style['lightbox-button']}`}
				onClick={fadeOutLightbox}
			>
				<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M21.753 1.247a1.5 1.5 0 0 1 0 2.121L13.621 11.5l8.132 8.132a1.5 1.5 0 1 1-2.121 2.121L11.5 13.621l-8.132 8.132a1.5 1.5 0 1 1-2.121-2.121L9.379 11.5 1.247 3.368a1.5 1.5 0 1 1 2.121-2.121L11.5 9.379l8.132-8.132a1.5 1.5 0 0 1 2.121 0Z"
						fill="#FFF"
						fill-rule="evenodd"
					/>
				</svg>
			</button>
			<div class={style['lightbox-content']}>{contents}</div>
		</div>
	);
};

export default Lightbox;
