// from packages
import { FunctionalComponent, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useContext, useEffect } from 'preact/hooks';
import TWEEN from '@tweenjs/tween.js';

// constants, enums, types

// utils
import { STAGE_ORIENTATION } from '../../enums';
import { buildClasses } from '../../../../utils';

// components
import { RecordAppContext } from '../../index';

// assets

//styles
import style from './style.scss';

export interface CanvasClickEvent {
    ctx: CanvasRenderingContext2D | null;
    event: MouseEvent;
}

interface Props {
    className: string;
    draw: (ctx: CanvasRenderingContext2D | null) => void;
    onClick?: ({ ctx, event }: CanvasClickEvent) => void;
    onMouseDown?: ({ ctx, event }: CanvasClickEvent) => void;
    onMouseLeave?: ({ ctx, event }: CanvasClickEvent) => void;
    onMouseOver?: ({ ctx, event }: CanvasClickEvent) => void;
    onMouseUp?: (event: MouseEvent) => void;
    tween?: typeof TWEEN;
}

const Canvas: FunctionalComponent<Props> = (
    {
        className,
        draw,
        onClick,
        onMouseDown,
        onMouseLeave,
        onMouseOver,
        onMouseUp,
        tween,
    }: Props,
    ref: any,
) => {
    const { orientation } = useContext(RecordAppContext);
    let ctx: CanvasRenderingContext2D | null = null;

    useEffect(() => {
        if (ref.current) {
            ctx = ref.current.getContext('2d', { alpha: false });
            ref.current.width =
                orientation === STAGE_ORIENTATION.PORTRAIT ? 1080 : 1920;
            ref.current.height =
                orientation === STAGE_ORIENTATION.PORTRAIT ? 1920 : 1080;
        }

        let animationFrameId: number;

        const render = (time?: number) => {
            draw(ctx);
            tween && tween.update(time);
            animationFrameId = requestAnimationFrame(render);
        };

        render();

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [draw, orientation]);

    const canvasClasses = buildClasses({
        [style.canvas]: true,
        [style[orientation]]: true,
        [className]: true,
    });

    return (
        <canvas
            class={canvasClasses}
            ref={ref}
            onMouseUp={onMouseUp}
            // onClick={(event: MouseEvent) => {
            //     console.log({ ctx, event });
            //     onClick && onClick({ ctx, event });
            //     // onMouseUp && onMouseUp();
            // }}
            onMouseDown={(event: MouseEvent) => {
                onMouseDown && onMouseDown({ ctx, event });
            }}
            onMouseLeave={(event: MouseEvent) => {
                onMouseLeave && onMouseLeave({ ctx, event });
            }}
            onMouseMove={(event: MouseEvent) => {
                onMouseOver && onMouseOver({ ctx, event });
            }}
        />
    );
};

export default forwardRef(Canvas);
