import { FunctionalComponent, h } from 'preact';
import { useId } from 'preact/hooks';
import { buildClasses } from '../../../utils';
import style from './style.scss';

interface Props {
    activeText?: string;
    handleClick: () => void;
    isActive: boolean;
    inactiveText?: string;
    label: string;
    labelPosition: 'top' | 'bottom' | 'hidden';
    disabled?: boolean;
}

const Toggle: FunctionalComponent<Props> = ({
    activeText = 'On',
    handleClick,
    isActive,
    inactiveText = 'Off',
    label,
    labelPosition = 'top',
    disabled = false,
}: Props) => {
    const toggleId = useId();
    const classes = buildClasses({
        [style.toggle]: true,
        [style[`label-${labelPosition}`]]: true,
        [style['is-disabled']]: disabled,
    });

    return (
        <div class={classes}>
            <span class={style['label-text']}>{label}</span>
            <div class={style['toggle-wrapper']}>
                <input
                    type="checkbox"
                    name={`toggle-${toggleId}`}
                    class={style['mobile-toggle']}
                    id={`toggle-${toggleId}`}
                    disabled={disabled}
                    onChange={handleClick}
                    checked={isActive}
                />
                <label for={`toggle-${toggleId}`}>
                    <div class={style.handle}>
                        <span class={style['off-text']}>{inactiveText}</span>
                        <span class={style['on-text']}>{activeText}</span>
                    </div>
                </label>
            </div>
        </div>
    );
};

export default Toggle;
