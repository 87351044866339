// from packages
import { nanoid } from 'nanoid';
import { createContext, Fragment, FunctionalComponent, h } from 'preact';
import { useEffect, useReducer, useRef, useState } from 'preact/hooks';

// constants, enums, types
import {
	DESIRED_AUDIO_BITRATE,
	DESIRED_VIDEO_BITRATE,
	NOOP,
	VIDEO_SESSION_ID_LENGTH,
} from './constants';
import {
	CONTENT_LAYOUT,
	PIP_POS,
	PIP_SHAPE,
	PLAYLIST_ACTIONS,
	PLAYLIST_ITEM_FILE_TYPE,
	RECORDED_CHUNK_ACTIONS,
	RECORDING_STATE,
	SOURCE_CONTENT_TYPE,
	STAGE_ORIENTATION,
	WEB_REACTS_EVENTS,
} from './enums';
import {
	AppContext,
	// Coords,
	FileType,
	PlaylistAction,
	PlaylistItemFile,
	RecordedChunkAction,
	ScreenStreamType,
} from './types';

// utils
import {
	acceptedReactFileTypes,
	activeItemIsVideo,
	buildClasses,
	formatTime,
	isAudioFile,
	isHeicFile,
	isGifFile,
	isImageFile,
	isVideoFile,
} from '../../utils';
import AudioEngine from '../../utils/audioEngine';
// import VideoEngine from '../../utils/videoEngine';
import { logWebReactsEvent } from '../../utils/logger';
import {
	getAPIPermissionStatus,
	getUserDevices,
	startCaptureScreen,
	UserDevices,
} from '../../utils/mediaDevices';
import { sendWebhookMessage } from '../../utils/webhooks';

// components
import ActiveItemDisplay from './components/activeItemDisplay';
import AVSettingsPanel from './components/avSettingsPanel';
import ClipsFilmstrip from './components/clipsFilmstrip';
import DeleteRecordingConfirmationModal from './components/deleteRecordingConfirmationModal';
import DevicePermissions from './components/devicePermissions';
import Exporter from './components/exporter';
import FooterControls from './components/footerControls';
import LayoutPicker from './components/layoutPicker';
import Playlist from './components/playlist';
import PreviewVideo from './components/previewVideo';
import Stage from './components/stage';
import UserCamera from './components/userCamera';
import Button from '../common/button';
import Dropzone from '../common/dropzone';

// assets
import SettingsIcon from '../../assets/images/settings-icon.svg';

//styles
import style from './style.scss';

interface Props {
	handleChangeRecordingState: ({ state }: { state: RECORDING_STATE }) => void;
	isLoggedIn: boolean;
	userId: string;
}

const freshAndCleanState = {
	addingFilesFromComputer: false,
	autoNextActive: true,
	clipsPlaying: false,
	contentLayout: CONTENT_LAYOUT.CIRCLE,
	createAudioEngine: NOOP,
	draggingPlaylistItem: null,
	focusFlipActive: true,
	focusFlipAnimationDone: NOOP,
	focusFlipped: false,
	handleDragPlaylistItemEnd: (e: DragEvent) => {},
	handleDragPlaylistItemStart: ({ item }: { item: PlaylistItemFile }) => {},
	handleFitFillButtonClick: NOOP,
	handleGetScreen: NOOP,
	handleItemDrag: (e: DragEvent) => {},
	handleLayoutsButtonClick: NOOP,
	handleNext: NOOP,
	handlePrev: NOOP,
	handleRecordingButtonClick: NOOP,
	handleSetActiveItem: ({ item }: { item: PlaylistItemFile }) => {},
	handleSourceLoaded: NOOP,
	handleToggleAutoNext: NOOP,
	handleToggleFocusFlip: NOOP,
	handleToggleInvert: NOOP,
	handleTogglePlay: ({ pausePlayClick }: { pausePlayClick?: boolean }) => {},
	handleToggleRecordCursor: NOOP,
	handleUserCameraDragEnd: NOOP,
	isCleanState: true,
	isDeletingItem: false,
	isFit: false,
	isInverted: false,
	isPlaying: false,
	isPreviewMode: false,
	orientation: STAGE_ORIENTATION.PORTRAIT,
	pipPos: PIP_POS.BOTTOM_LEFT,
	pipShape: PIP_SHAPE.CIRCLE,
	playlistDispatch: NOOP,
	playlistItems: [],
	recordingCursor: false,
	recordingState: RECORDING_STATE.NOT_STARTED,
	recordingTimeInSeconds: 0,
	sourceLoaded: false,
	stageScale: 1,
	userId: '',
	videoSessionId: '',
};

export const RecordAppContext = createContext<AppContext>(freshAndCleanState);
// const videoEngine = new VideoEngine();

const Record: FunctionalComponent<Props> = ({
	handleChangeRecordingState,
	isLoggedIn,
	userId,
}) => {
	if (!isLoggedIn) {
		return null;
	}
	// refs
	const activeItemDisplayRef = useRef<HTMLVideoElement | HTMLImageElement>();
	const canvasRef = useRef<HTMLCanvasElement>();
	const dragCounter = useRef<number>(0);
	const footerControlsRef = useRef<HTMLElement>();
	const previewVideoRef = useRef<{
		togglePlay: () => void;
		setCurrentTime: (time: number) => void;
	}>(null);
	const userCameraRef = useRef<HTMLVideoElement>();

	// state
	const [activeItem, setActiveItem] = useState<PlaylistItemFile | null>(null);
	const [addingFilesFromComputer, setAddingFilesFromComputer] =
		useState<boolean>(freshAndCleanState.addingFilesFromComputer);
	const [audioEngine, setAudioEngine] = useState<AudioEngine>();
	const [autoNextActive, setAutoNextActive] = useState<boolean>(
		freshAndCleanState.autoNextActive,
	);
	const [avSettingsOpen, setAvSettingsOpen] = useState<boolean>(false);
	const [cameraPermissionState, setCameraPermissionState] = useState<
		'granted' | 'denied' | 'prompt'
	>();
	const [canPlayPreview, setCanPlayPreview] = useState<boolean>(false);
	const [clipsPlaying, setClipsPlaying] = useState<boolean>(
		freshAndCleanState.clipsPlaying,
	);
	const [contentLayout, setContentLayout] = useState<CONTENT_LAYOUT>(
		freshAndCleanState.contentLayout,
	);
	const [currentCameraDevice, setCurrentCameraDevice] =
		useState<InputDeviceInfo>();
	const [currentMicDevice, setCurrentMicDevice] = useState<InputDeviceInfo>();
	const [currentSpeakerDevice, setCurrentSpeakerDevice] =
		useState<MediaDeviceInfo>();
	const [draggingPlaylistItem, setDraggingPlaylistItem] =
		useState<PlaylistItemFile | null>(
			freshAndCleanState.draggingPlaylistItem,
		);
	const [flipFunction, setFlipFunction] = useState<{ fn: () => void }>({
		fn: NOOP,
	});
	const [focusFlipActive, setFocusFlipActive] = useState<boolean>(
		freshAndCleanState.focusFlipActive,
	);
	const [focusFlipped, setFocusFlipped] = useState<boolean>(
		freshAndCleanState.focusFlipped,
	);
	const [isFit, setIsFit] = useState<boolean>(freshAndCleanState.isFit);
	const [isDeletingItem, setIsDeletingItem] = useState<boolean>(
		freshAndCleanState.isDeletingItem,
	);
	const [isInverted, setIsInverted] = useState<boolean>(
		freshAndCleanState.isInverted,
	);
	const [isPlaying, setIsPlaying] = useState<boolean>(
		freshAndCleanState.isPlaying,
	);
	const [isPreviewMode, setIsPreviewMode] = useState<boolean>(
		freshAndCleanState.isPreviewMode,
	);
	const [layoutPickerOpen, setLayoutPickerOpen] = useState<boolean>(false);
	const [maxCanvasSize, setMaxCanvasSize] = useState<{
		maxHeight: number;
		maxWidth: number;
	}>({ maxHeight: 0, maxWidth: 0 }); // does this need to be on state?
	const [microphonePermissionState, setMicrophonePermissionState] = useState<
		'granted' | 'denied' | 'prompt'
	>();
	const [orientation, setOrientation] = useState<STAGE_ORIENTATION>(
		freshAndCleanState.orientation,
	);
	const [pipPos, setPipPos] = useState<PIP_POS>(freshAndCleanState.pipPos);
	const [pipShape, setPipShape] = useState<PIP_SHAPE>(
		freshAndCleanState.pipShape,
	);
	const [previewVideoUrl, setPreviewVideoUrl] = useState<string>('');
	const [previewVideoCurrentTime, setPreviewVideoCurrentTime] =
		useState<number>(0);
	const [previewVideoDuration, setPreviewVideoDuration] = useState<number>(0);
	const [recorder, setRecorder] = useState<MediaRecorder>();
	const [recordingCursor, setRecordingCursor] = useState<boolean>(
		freshAndCleanState.recordingCursor,
	);
	const [recordingState, setRecordingState] = useState<RECORDING_STATE>(
		freshAndCleanState.recordingState,
	);
	const [
		showDeleteRecordingConfirmation,
		setShowDeleteRecordingConfirmation,
	] = useState<boolean>(false);
	const [sourceLoaded, setSourceLoaded] = useState<boolean>(
		freshAndCleanState.sourceLoaded,
	);
	const [userCameraLoaded, setUserCameraLoaded] = useState<boolean>(false);
	const [userDevices, setUserDevices] = useState<UserDevices>();
	const [videoSessionId, setVideoSessionId] = useState<string>('');

	// reducers
	const [playlistItems, playlistDispatch] = useReducer<
		(PlaylistItemFile | null)[],
		PlaylistAction
	>(playlistReducerMethod, []);
	const [recordedChunks, recordedChunksDispatch] = useReducer<
		(Blob | null)[],
		RecordedChunkAction
	>(recordedChunksReducerMethod, []);

	// constants
	const isCleanState =
		recordedChunks.length === 0 && playlistItems.length === 0;
	const stageDimensions = getStageDimensions();
	const stageScale =
		orientation === STAGE_ORIENTATION.PORTRAIT
			? parseInt(stageDimensions.width) / 1080
			: parseInt(stageDimensions.width) / 1920;

	useEffect(() => {
		setStageDimensions();
		setPermissionStates();
		window.addEventListener('resize', setStageDimensions);

		return () => {
			window.removeEventListener('resize', setStageDimensions);
		};
	}, []);

	useEffect(() => {
		const getDevices = async () => {
			const devices = await loadDevices();
			setUserDevices(devices);

			if (devices) {
				const savedMicId = localStorage.getItem(
					btoa('rodeo-savedMicId'),
				);
				const savedSpeakerId = localStorage.getItem(
					btoa('rodeo-savedSpeakerId'),
				);
				const savedCameraId = localStorage.getItem(
					btoa('rodeo-savedCameraId'),
				);

				if (devices.audioinput) {
					let selectedMicDevice = devices.audioinput[0];
					if (savedMicId) {
						selectedMicDevice =
							devices.audioinput
								.filter(
									device => device.deviceId === savedMicId,
								)
								.pop() || devices.audioinput[0];
					}

					setCurrentMicDevice(selectedMicDevice);
				}

				if (devices.audiooutput) {
					let selectedSpeakerDevice = devices.audiooutput[0];
					if (savedSpeakerId) {
						selectedSpeakerDevice =
							devices.audiooutput
								.filter(
									device =>
										device.deviceId === savedSpeakerId,
								)
								.pop() || devices.audiooutput[0];
					}

					setCurrentSpeakerDevice(selectedSpeakerDevice);
				}

				if (devices.videoinput) {
					let selectedCameraDevice = devices.videoinput[0];
					if (savedCameraId) {
						selectedCameraDevice =
							devices.videoinput
								.filter(
									device => device.deviceId === savedCameraId,
								)
								.pop() || devices.videoinput[0];
					}

					setCurrentCameraDevice(selectedCameraDevice);
				}
			}
		};
		if (
			cameraPermissionState === 'granted' &&
			microphonePermissionState === 'granted'
		) {
			getDevices();
		}
	}, [cameraPermissionState, microphonePermissionState]);

	useEffect(() => {
		if (currentMicDevice) {
			localStorage.setItem(
				btoa('rodeo-savedMicId'),
				currentMicDevice.deviceId,
			);
			changeUserAudioSource();
		}
	}, [currentMicDevice]);

	useEffect(() => {
		if (currentSpeakerDevice) {
			localStorage.setItem(
				btoa('rodeo-savedSpeakerId'),
				currentSpeakerDevice.deviceId,
			);
		}
	}, [currentSpeakerDevice]);

	useEffect(() => {
		if (currentCameraDevice) {
			localStorage.setItem(
				btoa('rodeo-savedCameraId'),
				currentCameraDevice.deviceId,
			);
		}
	}, [currentCameraDevice]);

	useEffect(() => {
		setIsDeletingItem(false);
		if (addingFilesFromComputer && playlistItems.length > 0) {
			setAddingFilesFromComputer(false);
		}

		if (!activeItem && playlistItems.at(0)) {
			handleSetActiveItem({ item: playlistItems.at(0)! });
		}
	}, [playlistItems]);

	useEffect(() => {
		handleChangeRecordingState({ state: recordingState });
		setStageDimensions();
		if (recordingState === RECORDING_STATE.PAUSED) {
			const url = URL.createObjectURL(
				new Blob(recordedChunks as BlobPart[], { type: 'video/webm' }),
			);
			setPreviewVideoUrl(url);
			if (isPlaying) {
				handleTogglePlay({ pausePlayClick: false });
			}
			logWebReactsEvent({
				type: WEB_REACTS_EVENTS.PAUSED_RECORDING,
				userId,
				videoSessionId,
			});
		}
		if (recordingState === RECORDING_STATE.RECORDING) {
			sendRecordingScrobble();
			logWebReactsEvent({
				type: WEB_REACTS_EVENTS.STARTED_RECORDING,
				userId,
				videoSessionId,
			});
		}

		if (recordingState === RECORDING_STATE.DONE) {
			logWebReactsEvent({
				type: WEB_REACTS_EVENTS.ENDED_RECORDING,
				userId,
				data: `Total record time: ${formatTime(
					recordedChunks.length / 2,
				)}`,
				videoSessionId,
			});
		}

		if (recordingState === RECORDING_STATE.NOT_STARTED) {
			console.log('setting it');
			setVideoSessionId(nanoid(VIDEO_SESSION_ID_LENGTH));
		}
	}, [recordingState]);

	useEffect(() => {
		if (contentLayout !== CONTENT_LAYOUT.CIRCLE) {
			setIsInverted(false);
		}
	}, [contentLayout]);

	async function sendRecordingScrobble() {
		const user = await window.gapi.auth2
			.getAuthInstance()
			.currentUser.get()
			.getBasicProfile();
		sendWebhookMessage({
			username: user.getName(),
			action: 'started recording',
			userId: user.getId(),
		});
	}

	function shouldHideHeaderBar() {
		return (
			recordingState === RECORDING_STATE.RECORDING ||
			recordingState === RECORDING_STATE.PAUSED ||
			recordingState === RECORDING_STATE.PREVIEW
		);
	}

	function setStageDimensions() {
		const headerBar = shouldHideHeaderBar() ? 10 : 56;
		setMaxCanvasSize({
			maxHeight: window.innerHeight - 194 - headerBar,
			maxWidth: window.innerWidth * 0.8 - 20,
		});
	}

	function getStageDimensions() {
		const { maxHeight, maxWidth } = maxCanvasSize;
		if (orientation === STAGE_ORIENTATION.PORTRAIT) {
			return {
				height: `${maxHeight}px`,
				width: `${maxHeight * (9 / 16)}px`,
			};
		} else {
			if (maxWidth * (9 / 16) > maxHeight) {
				return {
					height: `${maxHeight}px`,
					width: `${maxHeight * (16 / 9)}px`,
				};
			}
			return {
				height: `${maxWidth * (9 / 16)}px`,
				width: `${maxWidth}px`,
			};
		}
	}

	function playlistReducerMethod(
		playlistItems: (PlaylistItemFile | null)[],
		{ payload, type }: PlaylistAction,
	) {
		const lastItemIndex =
			playlistItems.length > 0
				? playlistItems.at(playlistItems.length - 1)!.itemIndex
				: 0;

		switch (type) {
			case PLAYLIST_ACTIONS.ADD_FILES: {
				// console.log('adding playlist items', payload);
				const newItems = Array.from(payload as FileList).map(
					(file: File, i: number) => {
						return {
							itemIndex: lastItemIndex + i + 1,
							itemType: PLAYLIST_ITEM_FILE_TYPE.FILE,
							item: {
								file,
								blob: URL.createObjectURL(file),
							},
						};
					},
				);
				return [...playlistItems, ...newItems];
				break;
			}
			case PLAYLIST_ACTIONS.ADD_SCREEN_STREAM: {
				// console.log('adding screen to the playlist items', payload);
				const stream = payload as MediaStream;
				stream.getVideoTracks()[0].onended = () => {
					playlistDispatch({
						type: PLAYLIST_ACTIONS.REMOVE,
						payload: {
							itemIndex: lastItemIndex + 1,
							itemType: PLAYLIST_ITEM_FILE_TYPE.SCREEN_STREAM,
							item: { stream },
						},
					});
				};
				return [
					...playlistItems,
					{
						itemIndex: lastItemIndex + 1,
						itemType: PLAYLIST_ITEM_FILE_TYPE.SCREEN_STREAM,
						item: { stream },
					},
				];
				break;
			}
			case PLAYLIST_ACTIONS.REMOVE: {
				const itemToRemove = payload as PlaylistItemFile;

				if (
					activeItem &&
					activeItem.itemIndex === itemToRemove.itemIndex
				) {
					// @ts-ignore
					document.startViewTransition(removeActiveItem);
				}
				if (
					itemToRemove.itemType ===
					PLAYLIST_ITEM_FILE_TYPE.SCREEN_STREAM
				) {
					(itemToRemove.item as ScreenStreamType).stream
						.getTracks()
						.forEach(track => {
							track.stop();
						});
				}
				return playlistItems.filter(
					item => item?.itemIndex !== itemToRemove.itemIndex,
				);
				break;
			}
			case PLAYLIST_ACTIONS.CLEAR: {
				return [];
				break;
			}
			case PLAYLIST_ACTIONS.MODIFY: {
				// console.log('modifying a playlist item', payload);
				return playlistItems; // todo
				break;
			}
		}
		return playlistItems; // todo
	}

	function recordedChunksReducerMethod(
		recordedChunks: (Blob | null)[],
		{ payload, type }: RecordedChunkAction,
	) {
		switch (type) {
			case RECORDED_CHUNK_ACTIONS.ADD: {
				// console.log('adding chunks', payload);
				return [...(recordedChunks || []), payload];
				break;
			}
			case RECORDED_CHUNK_ACTIONS.RESET: {
				return [];
				break;
			}
			// case RECORDED_CHUNK_ACTIONS.MODIFY: {
			// 	// console.log('modifying a playlist item', payload);
			// 	return playlistItems; // todo
			// 	break;
			// }
		}
	}

	function removeActiveItem() {
		console.log('removing active item');
		setActiveItem(null);
		setSourceLoaded(false);
		if (contentLayout === CONTENT_LAYOUT.SOURCE) {
			setContentLayout(CONTENT_LAYOUT.CAMERA);
		}
	}

	async function loadDevices() {
		if (
			microphonePermissionState !== 'granted' ||
			cameraPermissionState !== 'granted'
		) {
			return;
		}
		return await getUserDevices();
	}

	async function setPermissionStates() {
		const cameraPermission = await getAPIPermissionStatus({
			name: 'camera' as PermissionName,
		});
		const microphonePermission = await getAPIPermissionStatus({
			name: 'microphone' as PermissionName,
		});
		setCameraPermissionState(cameraPermission);
		setMicrophonePermissionState(microphonePermission);
	}

	function saveMicDevice({ deviceId }: { deviceId: string }) {
		if (!userDevices) {
			return;
		}

		setCurrentMicDevice(
			userDevices.audioinput
				.filter(device => device.deviceId === deviceId)
				.pop(),
		);
	}

	function saveSpeakerDevice({ deviceId }: { deviceId: string }) {
		if (!userDevices) {
			return;
		}
		console.log('saving speaker device');

		setCurrentSpeakerDevice(
			userDevices.audiooutput
				.filter(device => device.deviceId === deviceId)
				.pop(),
		);
	}

	function saveCameraDevice({ deviceId }: { deviceId: string }) {
		if (!userDevices) {
			return;
		}

		setCurrentCameraDevice(
			userDevices.videoinput
				.filter(device => device.deviceId === deviceId)
				.pop(),
		);
	}

	function handleUserDevices({ devices }: { devices: UserDevices }) {
		setPermissionStates();
	}

	function handleToggleOrientation() {
		setOrientation(
			orientation === STAGE_ORIENTATION.PORTRAIT
				? STAGE_ORIENTATION.LANDSCAPE
				: STAGE_ORIENTATION.PORTRAIT,
		);
		if (orientation === STAGE_ORIENTATION.LANDSCAPE) {
			if (contentLayout === CONTENT_LAYOUT.RIGHT) {
				setContentLayout(CONTENT_LAYOUT.BOTTOM);
			} else if (contentLayout === CONTENT_LAYOUT.LEFT) {
				setContentLayout(CONTENT_LAYOUT.TOP);
			}
		} else {
			if (contentLayout === CONTENT_LAYOUT.BOTTOM) {
				setContentLayout(CONTENT_LAYOUT.RIGHT);
			} else if (contentLayout === CONTENT_LAYOUT.TOP) {
				setContentLayout(CONTENT_LAYOUT.LEFT);
			}
		}

		// export enum PIP_SHAPE {
		// CIRCLE = 'CIRCLE', // both
		// PORTRAIT = 'PORTRAIT', // ???
		// LANDSCAPE = 'LANDSCAPE', // only in portrait
		// PORTRAITISH = 'PORTRAITISH', // only in portrait
		// LANDSCAPEISH = 'LANDSCAPEISH', // only in landscape
		// }

		if (pipShape === PIP_SHAPE.LANDSCAPE) {
			setPipShape(PIP_SHAPE.LANDSCAPEISH);
		} else if (pipShape === PIP_SHAPE.LANDSCAPEISH) {
			setPipShape(PIP_SHAPE.LANDSCAPE);
		} else {
			setPipShape(PIP_SHAPE.CIRCLE);
		}
	}

	function handleUserCameraLoaded() {
		setUserCameraLoaded(true);
	}

	function createAudioEngine() {
		const engine = new AudioEngine();
		setAudioEngine(engine);
		return engine;
	}

	async function changeUserAudioSource() {
		const userAudio = await getUserAudioStream();
		const engine = audioEngine || createAudioEngine();
		if (userAudio && engine) {
			addAudioSource({ engine, stream: userAudio, isLocal: true });
		}
	}

	function addAudioSource({
		engine,
		stream,
		isLocal,
	}: {
		engine: AudioEngine;
		stream: MediaStream;
		isLocal: boolean;
	}) {
		if (!engine) {
			return;
		}
		if (isLocal) {
			// console.log({ stream });
			engine.addLocalSource({ stream });
		} else {
			engine.addVideoSource({ stream });
		}
	}

	async function getUserAudioStream() {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: { deviceId: currentMicDevice?.deviceId },
			});
			if (stream) {
				return stream;
			}
		} catch (e) {}
	}

	function handleRecordingDataAvailable(e: { data: Blob }) {
		// console.log('got a chunk', e);
		if (e.data.size > 0) {
			recordedChunksDispatch({
				type: RECORDED_CHUNK_ACTIONS.ADD,
				payload: e.data,
			});
		}
	}

	async function handleRecordingStop() {
		console.log('recording has stopped, do some stuff', recordedChunks);
		setRecordingState(RECORDING_STATE.DONE);
		// setPreviewVideo(null);
		if (isPlaying) {
			handleTogglePlay({ pausePlayClick: false });
		}
	}
	async function setUpMediaRecorder() {
		// console.log('setting up the recorder');
		if (!canvasRef.current) {
			console.log('uhhhh no');
			return;
		}

		const engine = audioEngine || createAudioEngine();
		const userAudio = await getUserAudioStream();
		if (userAudio) {
			addAudioSource({ engine, stream: userAudio, isLocal: true });
		}
		// TODO
		//         // if (recordingState !== 'not started') {
		//             addAudioSource({ stream, isLocal: true });

		// if (userAudioStream) {
		// }

		// if (contentType === 'video') {
		//     // @ts-ignore - this property hasn't been added to typescript yet
		//     addAudioSource({ stream: fileVideoRef.current.captureStream() });
		// }
		// console.log({ engine });
		engine.resume();

		const canvasStream = canvasRef.current.captureStream(60);
		const mediaStream = new MediaStream([
			canvasStream.getVideoTracks()[0],
			engine.getAudioTracks(),
		]);
		const mediaRecorder = new MediaRecorder(mediaStream, {
			mimeType: 'video/webm; codecs=h264',
			videoBitsPerSecond: DESIRED_VIDEO_BITRATE,
			audioBitsPerSecond: DESIRED_AUDIO_BITRATE,
		});
		mediaRecorder.onerror = (e: Event) => {
			console.log('recorder error', e);
			logWebReactsEvent({
				type: WEB_REACTS_EVENTS.ERROR,
				userId,
				data: `Media recorder error: ${JSON.stringify(e)}`,
				videoSessionId: videoSessionId || '',
			});
		};
		mediaRecorder.ondataavailable = handleRecordingDataAvailable;
		mediaRecorder.onstop = handleRecordingStop;
		mediaRecorder.onpause = () => {
			// // const url = URL.createObjectURL(
			// // 	new Blob(recordedChunks, { type: 'video/webm' }),
			// // );
			// const url = new Blob(recordedChunks, { type: 'video/webm' });
			// console.log({ url });
			// setPreviewVideoUrl(url);
			// @ts-ignore
			document.startViewTransition(() => {
				setRecordingState(RECORDING_STATE.PAUSED);
			});
		};
		mediaRecorder.onresume = () => {
			// setPreviewVideo(null);
			// @ts-ignore
			document.startViewTransition(() => {
				setRecordingState(RECORDING_STATE.RECORDING);
			});
		};
		mediaRecorder.onstart = () => {
			// @ts-ignore
			// document.startViewTransition(() => {
			setRecordingState(RECORDING_STATE.RECORDING);
			// });
		};
		mediaRecorder.start(500);
		setRecorder(mediaRecorder);
	}

	function handleRecordingButtonClick() {
		// console.log('hey hey', recordingState);
		switch (recordingState) {
			case RECORDING_STATE.NOT_STARTED:
				setUpMediaRecorder();
				break;
			case RECORDING_STATE.RECORDING:
				if (recorder) {
					recorder.pause();
				}
				break;
			case RECORDING_STATE.PAUSED:
				if (recorder) {
					recorder.resume();
				}
				break;
		}
	}

	function handleExportButtonClick() {
		if (!recorder) {
			return;
		}
		if (previewVideoRef.current && clipsPlaying) {
			previewVideoRef.current.togglePlay();
			setClipsPlaying(false);
		}
		recorder.stop();
	}

	function toggleDeleteRecordingConfirmation() {
		setShowDeleteRecordingConfirmation(!showDeleteRecordingConfirmation);
	}

	function handleDeleteRecording() {
		recordedChunksDispatch({
			type: RECORDED_CHUNK_ACTIONS.RESET,
			payload: null,
		});
		// playlistDispatch({
		// 	type: PLAYLIST_ACTIONS.CLEAR,
		// 	payload: null,
		// });
		setRecordingState(RECORDING_STATE.NOT_STARTED);
		setShowDeleteRecordingConfirmation(false);
		exitToRecordMode();
		removeActiveItem();
	}

	function toggleClipsPlaying() {
		// clipsPlaying.current = !clipsPlaying.current;
		if (!isPreviewMode) {
			setIsPreviewMode(true);
		}
		if (previewVideoRef.current) {
			setClipsPlaying(!clipsPlaying);
			previewVideoRef.current.togglePlay();
			// if (previewVideoRef.current.paused) {
			// 	try {
			// 		// await previewVideoRef.current.play();
			// 	} catch (e) {
			// 		console.log('error playing', e);
			// 	}
			// } else {
			// 	// previewVideoRef.current.pause();
			// }
		}
	}

	function exitToRecordMode() {
		setIsPreviewMode(false);
		if (clipsPlaying) {
			if (previewVideoRef.current) {
				previewVideoRef.current.togglePlay();
			}
			setClipsPlaying(false);
		}
	}

	function onPreviewVideoEnded() {
		setClipsPlaying(false);
	}

	function onPreviewVideoTimeUpdate({
		currentTime,
		duration,
	}: {
		currentTime: number;
		duration: number;
	}) {
		setPreviewVideoCurrentTime(currentTime);
		setPreviewVideoDuration(Math.min(duration, recordedChunks.length / 2));
	}

	function handleFitFillButtonClick() {
		setIsFit(!isFit);
	}

	async function handleGetScreen() {
		try {
			const screenStream = await startCaptureScreen();
			if (screenStream) {
				// handleStopScreenshare;
				playlistDispatch({
					type: PLAYLIST_ACTIONS.ADD_SCREEN_STREAM,
					payload: screenStream,
				});
			}
		} catch (e) {
			console.log('error getting the stream', e);
			logWebReactsEvent({
				type: WEB_REACTS_EVENTS.ERROR,
				userId,
				data: `Screen stream error: ${JSON.stringify(e)}`,
				videoSessionId: videoSessionId || '',
			});
		}
	}

	function getItemType({ item }: { item: PlaylistItemFile }) {
		switch (item.itemType) {
			case PLAYLIST_ITEM_FILE_TYPE.FILE:
				if (isHeicFile((item.item as FileType).file)) {
					return SOURCE_CONTENT_TYPE.HEIC;
				}

				if (isGifFile((item.item as FileType).file)) {
					return SOURCE_CONTENT_TYPE.GIF;
				}

				if (isImageFile((item.item as FileType).file)) {
					return SOURCE_CONTENT_TYPE.IMAGE;
				}

				if (isVideoFile((item.item as FileType).file)) {
					return SOURCE_CONTENT_TYPE.VIDEO;
				}

				if (isAudioFile((item.item as FileType).file)) {
					return SOURCE_CONTENT_TYPE.AUDIO;
				}
				break;
			case PLAYLIST_ITEM_FILE_TYPE.SCREEN_STREAM:
				return SOURCE_CONTENT_TYPE.SCREEN;
		}
	}

	function updateActiveItem({ item }: { item: PlaylistItemFile }) {
		if (activeItem === item) {
			return;
		}
		setActiveItem(item);
		setSourceLoaded(false);
		setIsPlaying(
			item.itemType === PLAYLIST_ITEM_FILE_TYPE.FILE &&
				isImageFile((item.item as FileType).file)
				? false
				: isPlaying,
		);
		logWebReactsEvent({
			type: WEB_REACTS_EVENTS.ADDED_MEDIA,
			userId,
			data: getItemType({ item }),
			videoSessionId,
		});
	}

	function handleSetActiveItem({ item }: { item: PlaylistItemFile }) {
		// @ts-ignore
		document.startViewTransition(() => updateActiveItem({ item }));
	}

	function handleNext() {
		if (!playlistItems.length) {
			return;
		}
		let nextMedia: PlaylistItemFile | undefined | null = undefined;

		if (activeItem) {
			if (
				activeItem.itemIndex ===
				playlistItems.at(playlistItems.length - 1)!.itemIndex
			) {
				nextMedia = undefined;
			} else {
				nextMedia = playlistItems[activeItem.itemIndex];
			}
		} else if (playlistItems.length > 0) {
			nextMedia = playlistItems[0];
		}
		if (nextMedia) {
			handleSetActiveItem({ item: nextMedia });
		} else {
			// @ts-ignore
			document.startViewTransition(removeActiveItem);
		}
	}

	function handlePrev() {
		if (!playlistItems.length) {
			return;
		}

		let nextMedia: PlaylistItemFile | undefined | null = undefined;

		if (activeItem) {
			if (activeItem.itemIndex === playlistItems.at(0)!.itemIndex) {
				nextMedia = undefined;
			} else {
				let nextMediaIndex = 0;
				for (let i = 0; i < playlistItems.length; i++) {
					if (playlistItems[i] === activeItem) {
						nextMediaIndex = i - 1;
						break;
					}
				}
				nextMedia = playlistItems[nextMediaIndex];
			}
		} else {
			nextMedia = playlistItems.at(playlistItems.length - 1);
		}

		if (nextMedia) {
			handleSetActiveItem({ item: nextMedia });
		} else {
			// @ts-ignore
			document.startViewTransition(removeActiveItem);
		}
	}

	function handleTogglePlay({
		pausePlayClick = false,
	}: {
		pausePlayClick?: boolean;
	}) {
		if (!activeItem) {
			return;
		}

		const canFlip =
			focusFlipActive &&
			contentLayout !== CONTENT_LAYOUT.SOURCE &&
			contentLayout !== CONTENT_LAYOUT.CAMERA;
		const isVideo = activeItemIsVideo({
			activeItem,
			activeItemDisplay: activeItemDisplayRef.current,
		});

		if (isVideo) {
			if (isPlaying) {
				(activeItemDisplayRef.current! as HTMLVideoElement).pause();
			} else {
				(activeItemDisplayRef.current! as HTMLVideoElement).play();
			}
			setIsPlaying(!isPlaying);
		}

		if (pausePlayClick && canFlip) {
			// This means if we're starting in a paused state
			// we don't want to flip when they press play
			if (!focusFlipped && !isPlaying && isVideo) {
				return;
			}
			flipFunction.fn();
		}
	}

	function focusFlipAnimationDone() {
		setFocusFlipped(!focusFlipped);
	}

	function resetFocusFlip() {
		setFocusFlipped(false);
	}

	async function handleItemLoaded() {
		if (!activeItem || !activeItemDisplayRef.current) {
			return null;
		}
		let mediaStream;

		if (
			activeItemIsVideo({
				activeItem,
				activeItemDisplay: activeItemDisplayRef.current,
			})
		) {
			(
				activeItemDisplayRef.current as HTMLVideoElement
			).currentTime = 0.01;
			// console.log('the video item has loaded');
			if (isPlaying) {
				// console.log('it is in a playing state so let us play');
				(activeItemDisplayRef.current as HTMLVideoElement).play();
			}
			// setIsPlaying(true);

			// @ts-ignore
			mediaStream = activeItemDisplayRef.current.captureStream();
			if (mediaStream.getAudioTracks().length > 0) {
				addAudioSource({
					engine: audioEngine || createAudioEngine(),
					stream: mediaStream,
					isLocal: false,
				});
			}
		} else if (
			activeItem.itemType === PLAYLIST_ITEM_FILE_TYPE.SCREEN_STREAM &&
			'currentTime' in activeItemDisplayRef.current &&
			!sourceLoaded
		) {
			// @ts-ignore
			mediaStream = activeItemDisplayRef.current.captureStream();
			if (mediaStream.getAudioTracks().length > 0) {
				addAudioSource({
					engine: audioEngine || createAudioEngine(),
					stream: mediaStream,
					isLocal: false,
				});
			}
		}
		setSourceLoaded(true);
		resetFocusFlip();
	}

	function handleDragPlaylistItemStart({ item }: { item: PlaylistItemFile }) {
		console.log('setting this now');
		setDraggingPlaylistItem(item);
	}

	// function handleDragPlaylistItem(e: DragEvent) {
	// 	setPlaylistItemDragPos({ x: e.clientX, y: e.clientY });
	// }

	function handleLayoutsButtonClick() {
		// @ts-ignore
		document.startViewTransition(() => {
			setLayoutPickerOpen(!layoutPickerOpen);
		});
	}

	function handleSelectLayout({ layout }: { layout: CONTENT_LAYOUT }) {
		setContentLayout(layout);
		resetFocusFlip();
		// if (isInverted) {
		// 	setIsInverted(false);
		// }
		// handleLayoutsButtonClick();
	}

	// function handleDragStart(index: number) {
	//     setDragItemIndex(index);
	// }

	// function handleDragEnter(index: number) {
	//     setDragOverItemIndex(index);
	// }

	// function handleDragOverActionArea(e: DragEvent) {
	//     e.preventDefault();
	//     // e.stopPropagation();
	//     handleDragOverActionArea(e);
	// }

	function handleDragOverActionArea(e: DragEvent) {
		dragCounter.current = dragCounter.current + 1;
		e.preventDefault();
		e.stopPropagation();
		setIsDeletingItem(true);
		// if (dragOverItemIndex) {
		//     // @ts-ignore

		//     if (!itemRefs.current[dragOverItemIndex].base) {
		//         return;
		//     }
		//     const { clientY } = e;
		//     const { top, height } =
		//         itemRefs.current[
		//             dragOverItemIndex
		//             // @ts-ignore
		//         ].base.getBoundingClientRect();

		//     if (clientY < top + height / 2) {
		//         setPos('above');
		//     } else {
		//         setPos('below');
		//     }
		// }
	}
	function handleDragLeaveActionArea(e: DragEvent) {
		e.preventDefault();
		dragCounter.current = dragCounter.current - 1;
		// e.preventDefault();
		// e.stopPropagation();
		if (dragCounter.current < 1) {
			handleDragLeaveActionArea(e);
			setIsDeletingItem(false);
		}
	}
	function handleDragPlaylistItemEnd(e: DragEvent) {
		e.preventDefault();
		setDraggingPlaylistItem(null);
		setIsDeletingItem(false);
		// if (dragItemIndex && dragOverItemIndex) {
		//     //duplicate items
		//     const _items = [...items];
		//     //remove and save the dragged item content
		//     const draggedItemContent = _items.splice(dragItemIndex, 1)[0];
		//     //switch the position
		//     _items.splice(dragOverItemIndex, 0, draggedItemContent);
		//     //reset the position ref
		//     setDragItemIndex(null);
		//     setDragOverItemIndex(null);
		//     //update the actual array
		//     // setItems(_items);
		// }
	}

	function handleItemDrag(e: DragEvent) {
		e.preventDefault();
		e.stopPropagation();
		// if (recordingState === RECORDING_STATE.DONE) {
		// 	return;
		// }
		// console.log('in ths eoign');
		// setDragPos({})
		// setDragPos({ top: `${e.clientY}px`, left: `${e.clientX}px` });
		// setDragPos({ y: e.clientY, x: e.clientX });
	}

	function handleSourceLoaded() {
		setSourceLoaded(true);
	}

	function handleFilesDropped(files: FileList) {
		dragCounter.current = 0;
		if (files) {
			// console.log('files are dropped', files);
			playlistDispatch({
				type: PLAYLIST_ACTIONS.ADD_FILES,
				payload: files,
			});
		}
	}
	function handleDragStart() {
		if (recordingState === RECORDING_STATE.DONE) {
			return;
		}

		if (!draggingPlaylistItem) {
			dragCounter.current = dragCounter.current + 1;
			setAddingFilesFromComputer(true);
		}
	}
	function handleDragEnd() {
		if (!draggingPlaylistItem) {
			dragCounter.current = dragCounter.current - 1;
			if (dragCounter.current < 1 && addingFilesFromComputer) {
				// console.log('setting this guy');
				// setAddingFilesFromComputer(false);
			}
		}
	}
	function handleDragCancel() {
		setAddingFilesFromComputer(false);
		dragCounter.current = 0;
	}

	function handleFolderDropped() {
		// console.log('folder is dropped');
		dragCounter.current = 0;
	}

	// function handleUserCameraDragStart(e: DragEvent) {
	// 	console.log('starting to drag the user camera', e);
	// 	setDraggingUserCamera(true);
	// 	setUserCameraDragOrigin({ x: e.clientX, y: e.clientY });
	// 	dragCounter.current = dragCounter.current + 1;
	// }

	function handleUserCameraDragEnd({
		layout,
		pipPos,
	}: {
		layout: CONTENT_LAYOUT;
		pipPos: PIP_POS;
	}) {
		// console.log('done dragging the user camera');
		// e.preventDefault();
		setContentLayout(layout);
		setPipPos(pipPos);
		// dragCounter.current = dragCounter.current - 1;
		// if (dragCounter.current < 1 && draggingUserCamera) {
		// 	if (
		// 		contentLayout === CONTENT_LAYOUT.LEFT ||
		// 		contentLayout === CONTENT_LAYOUT.RIGHT
		// 	) {
		// 		if (userCameraDragPos.x < userCameraDragOrigin.x) {
		// 			console.log('going left');
		// 			// @ts-ignore
		// 			document.startViewTransition(
		// 				setContentLayout(CONTENT_LAYOUT.LEFT),
		// 			);
		// 		}
		// 		if (userCameraDragPos.x > userCameraDragOrigin.x) {
		// 			// @ts-ignore
		// 			document.startViewTransition(
		// 				setContentLayout(CONTENT_LAYOUT.RIGHT),
		// 			);
		// 			console.log('going right');
		// 		}
		// 	}
		// 	if (
		// 		contentLayout === CONTENT_LAYOUT.TOP ||
		// 		contentLayout === CONTENT_LAYOUT.BOTTOM
		// 	) {
		// 		if (userCameraDragPos.y < userCameraDragOrigin.y) {
		// 			console.log('going up');
		// 			// @ts-ignore
		// 			document.startViewTransition(
		// 				setContentLayout(CONTENT_LAYOUT.TOP),
		// 			);
		// 		}
		// 		if (userCameraDragPos.y > userCameraDragOrigin.y) {
		// 			console.log('going down');
		// 			// @ts-ignore
		// 			document.startViewTransition(
		// 				setContentLayout(CONTENT_LAYOUT.BOTTOM),
		// 			);
		// 		}
		// 	}
		// 	setDraggingUserCamera(false);
		// }
	}

	function handleToggleRecordCursor() {
		setRecordingCursor(!recordingCursor);
	}

	function handlePipShapeChange() {
		if (!activeItem || contentLayout !== CONTENT_LAYOUT.CIRCLE) {
			return;
		}

		let nextShape: PIP_SHAPE = PIP_SHAPE.CIRCLE;

		if (orientation === STAGE_ORIENTATION.PORTRAIT) {
			switch (pipShape) {
				case PIP_SHAPE.CIRCLE:
					nextShape = PIP_SHAPE.LANDSCAPE;
					break;
				case PIP_SHAPE.LANDSCAPE:
					nextShape = PIP_SHAPE.PORTRAITISH;
					break;
				case PIP_SHAPE.PORTRAITISH:
					nextShape = PIP_SHAPE.CIRCLE;
					break;
			}
		} else {
			switch (pipShape) {
				case PIP_SHAPE.CIRCLE:
					nextShape = PIP_SHAPE.LANDSCAPEISH;
					break;
				case PIP_SHAPE.LANDSCAPEISH:
					nextShape = PIP_SHAPE.CIRCLE;
					break;
			}
		}

		setPipShape(nextShape);
	}

	function handleToggleInvert() {
		setIsInverted(!isInverted);
	}

	function toggleAVSettingsOpen() {
		setAvSettingsOpen(!avSettingsOpen);
	}

	function handleVideoEnded() {
		// if auto advance
		if (autoNextActive) {
			handleNext();
		}
	}

	function handleToggleAutoNext() {
		setAutoNextActive(!autoNextActive);
	}

	function handleToggleFocusFlip() {
		resetFocusFlip();
		setFocusFlipActive(!focusFlipActive);
	}

	function handleCanPlayPreview() {
		setCanPlayPreview(true);
	}

	function onSetPreviewTime(time: number) {
		if (previewVideoRef.current) {
			previewVideoRef.current.setCurrentTime(time);
		}
	}

	function handleSetFlip(fn: () => void) {
		setFlipFunction({ fn });
	}

	const mainRecordClasses = buildClasses({
		[style.record]: true,
		[style[orientation]]: true,
		[style['active-new-content-drag']]: addingFilesFromComputer,
		[style['default-state']]: playlistItems.length === 0,
	});

	const stageClasses = buildClasses({
		[style['stage-container']]: true,
		[style['preview-mode']]: isPreviewMode,
	});

	const recordAppContextValue = {
		activeItem,
		activeItemDisplay: activeItemDisplayRef.current,
		addingFilesFromComputer,
		audioEngine,
		autoNextActive,
		// clipsPlaying: clipsPlaying.current,
		clipsPlaying,
		contentLayout,
		createAudioEngine,
		currentCameraDevice,
		currentMicDevice,
		currentSpeakerDevice,
		draggingPlaylistItem,
		focusFlipActive,
		focusFlipAnimationDone,
		focusFlipped,
		handleDragPlaylistItemEnd,
		handleDragPlaylistItemStart,
		handleFitFillButtonClick,
		handleGetScreen,
		handleItemDrag,
		handleNext,
		handleLayoutsButtonClick,
		handlePrev,
		handleRecordingButtonClick,
		handleSetActiveItem,
		handleSourceLoaded,
		handleToggleAutoNext,
		handleToggleFocusFlip,
		handleToggleInvert,
		handleTogglePlay,
		handleToggleRecordCursor,
		handleUserCameraDragEnd,
		isCleanState,
		isDeletingItem,
		isFit,
		isInverted,
		isPlaying,
		isPreviewMode,
		orientation,
		pipPos,
		pipShape,
		playlistDispatch,
		playlistItems,
		recordingCursor,
		recordingState,
		recordingTimeInSeconds: recordedChunks.length / 2,
		saveCameraDevice,
		saveMicDevice,
		saveSpeakerDevice,
		sourceLoaded,
		stageScale,
		userCamera: userCameraRef.current,
		userDevices,
		userId,
		// videoEngine,
		videoSessionId,
	};

	return (
		<RecordAppContext.Provider value={recordAppContextValue}>
			{!draggingPlaylistItem && (
				<Dropzone
					acceptedFileTypes={acceptedReactFileTypes}
					handleDragCancel={handleDragCancel}
					handleDragEnd={handleDragEnd}
					handleDragStart={handleDragStart}
					handleFilesDropped={handleFilesDropped}
					handleFolderDropped={handleFolderDropped}
				/>
			)}
			<div class={mainRecordClasses}>
				<div class={style['av-settings-container']}>
					<Button
						handleClick={toggleAVSettingsOpen}
						icon={<SettingsIcon />}
						buttonStyle="icon"
						hideLabel={true}
						iconPosition="full"
						label="Settings"
					/>
					<dialog
						open={avSettingsOpen}
						class={style['av-settings-panel-container']}
					>
						<AVSettingsPanel />
					</dialog>
				</div>
				<div class={stageClasses} style={stageDimensions}>
					<DevicePermissions
						cameraPermission={cameraPermissionState}
						microphonePermission={microphonePermissionState}
						handlePermissionsGranted={handleUserDevices}
						handlePermissionsDenied={setPermissionStates}
					/>
					<DeleteRecordingConfirmationModal
						handleDeleteRecording={handleDeleteRecording}
						show={showDeleteRecordingConfirmation}
						toggleDeleteRecordingConfirmation={
							toggleDeleteRecordingConfirmation
						}
					/>
					<Stage
						toggleClipsPlaying={toggleClipsPlaying}
						handleToggleOrientation={handleToggleOrientation}
						handlePipShapeChange={handlePipShapeChange}
						handleLayoutsButtonClick={handleLayoutsButtonClick}
						userCamera={userCameraRef.current}
						userCameraLoaded={userCameraLoaded}
						ref={canvasRef}
						setFlip={handleSetFlip}
					/>
					{recordingState === RECORDING_STATE.PAUSED && (
						<Fragment>
							<div
								class={`${style['clips-filmstrip-container']} ${style['preview-mode-el']}`}
							>
								<ClipsFilmstrip
									handleExportButtonClick={
										handleExportButtonClick
									}
									onSetPreviewTime={onSetPreviewTime}
									videoCurrentTime={previewVideoCurrentTime}
									videoDuration={previewVideoDuration}
									toggleClipsPlaying={toggleClipsPlaying}
									canPlayPreview={canPlayPreview}
								/>
								{!showDeleteRecordingConfirmation && (
									<Button
										handleClick={
											toggleDeleteRecordingConfirmation
										}
										label="DELETE AND START OVER"
										buttonStyle="text"
									/>
								)}
							</div>
							{isPreviewMode && (
								<Button
									className={`${style['return-to-record-btn']} ${style['preview-mode-el']}`}
									handleClick={exitToRecordMode}
									label="Return to Record mode"
									buttonStyle="text"
								/>
							)}
						</Fragment>
					)}
					<div class={style['preview-video-container']}>
						<PreviewVideo
							ref={previewVideoRef}
							src={previewVideoUrl}
							onCanPlay={handleCanPlayPreview}
							onTimeUpdate={onPreviewVideoTimeUpdate}
							onVideoEnded={onPreviewVideoEnded}
						/>
					</div>
					{recordingState === RECORDING_STATE.DONE && (
						<div class={style['exporter-container']}>
							<Exporter
								handleRestartVideo={handleDeleteRecording}
								recordedChunks={recordedChunks}
							/>
						</div>
					)}
					<LayoutPicker
						isOpen={layoutPickerOpen}
						handleSelectLayout={handleSelectLayout}
					/>
				</div>
				<div class={style['footer-container']}>
					{/*{recordingState === RECORDING_STATE.DONE && (
						<Button
							label="Cancel Export"
							handleClick={NOOP}
							buttonStyle="pill"
						/>
					)}*/}
					{playlistItems.length > 0 && !addingFilesFromComputer && (
						<Playlist />
					)}
					<FooterControls
						ref={footerControlsRef}
						hasPermissions={
							cameraPermissionState === 'granted' &&
							microphonePermissionState === 'granted'
						}
						handleDragOverActionArea={handleDragOverActionArea}
						handleDragLeaveActionArea={handleDragLeaveActionArea}
					/>
				</div>
				<UserCamera
					ref={userCameraRef}
					audioSourceId={currentMicDevice?.deviceId}
					onUserCameraLoaded={handleUserCameraLoaded}
					videoSourceId={currentCameraDevice?.deviceId}
					hasPermissions={
						cameraPermissionState === 'granted' &&
						microphonePermissionState === 'granted'
					}
				/>

				{activeItem && (
					<ActiveItemDisplay
						item={activeItem}
						handleVideoEnded={handleVideoEnded}
						onItemLoaded={handleItemLoaded}
						ref={activeItemDisplayRef}
					/>
				)}
			</div>
		</RecordAppContext.Provider>
	);
};

export default Record;
