// from packages
import { FunctionalComponent, h } from 'preact';
// import { useContext } from 'preact/hooks';

// constants, enums, types

// utils
import { getUserDevices, UserDevices } from '../../../../utils/mediaDevices';

// components
import DevicePermission from '../devicePermission';
import Button from '../../../common/button';

// assets
import BigMicrophoneIcon from '../../../../assets/images/big-microphone-icon.svg';
import BigCameraIcon from '../../../../assets/images/big-camera-icon.svg';

//styles
import style from './style.scss';

interface Props {
	cameraPermission?: 'granted' | 'denied' | 'prompt';
	microphonePermission?: 'granted' | 'denied' | 'prompt';
	handlePermissionsGranted: ({ devices }: { devices: UserDevices }) => void;
	handlePermissionsDenied: () => void;
	// dispatch: any;
}

const DevicePermissions: FunctionalComponent<Props> = ({
	cameraPermission,
	microphonePermission,
	handlePermissionsGranted,
	handlePermissionsDenied,
}: // dispatch,
Props) => {
	// const { appName, members, dispatch } = useContext(AppContext);
	// console.log({ dispatch });
	async function getMediaDevices() {
		try {
			const res = await getUserDevices();
			if (
				res &&
				res.videoinput[0].deviceId !== '' &&
				res.audioinput[0].deviceId !== ''
			) {
				handlePermissionsGranted({ devices: res });
				return;
			}
			throw Error();
		} catch (e) {
			handlePermissionsDenied();
		}
	}
	if (cameraPermission === 'granted' && microphonePermission === 'granted') {
		return null;
	}

	if (cameraPermission === 'denied' || microphonePermission === 'denied') {
		return (
			<div class={style['permissions-overlay']}>
				<div class={style.permissions}>
					<DevicePermission
						icon={<BigMicrophoneIcon />}
						permission={microphonePermission}
					/>
					<DevicePermission
						icon={<BigCameraIcon />}
						permission={cameraPermission}
					/>
				</div>
				<span>Reset your permissions for this site and refresh.</span>
			</div>
		);
	}
	return (
		<div class={style['permissions-overlay']}>
			<div class={style.permissions}>
				<DevicePermission
					icon={<BigMicrophoneIcon />}
					permission={microphonePermission}
				/>
				<DevicePermission
					icon={<BigCameraIcon />}
					permission={cameraPermission}
				/>
			</div>
			<span>Microphone and camera access neded to use Rodeo</span>
			<Button
				handleClick={getMediaDevices}
				buttonStyle="cta"
				label="Allow microphone and camera access"
			/>
		</div>
	);
};

export default DevicePermissions;
