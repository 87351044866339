// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

// constants, enums, types

// utils

// components
// import RowGroup from '../../../common/rowGroup';

// assets
import { RecordAppContext } from '../../index';
import PauseIcon from '../../../../assets/images/pause-icon.svg';
import PlayIcon from '../../../../assets/images/play-icon.svg';

//styles
import style from './style.scss';

interface Props {}

const VideoStateIndicator: FunctionalComponent<Props> = (
    {}: Props,
    ref: any,
) => {
    const { isPlaying } = useContext(RecordAppContext);

    return (
        <div class={style['video-state-wrapper']} key={isPlaying}>
            {isPlaying ? <PlayIcon /> : <PauseIcon />}
        </div>
    );
};

export default VideoStateIndicator;
