export async function signIn({ googleAuth }: { googleAuth: any }) {
    console.log('calling sign in', { googleAuth });
    try {
        const res = await googleAuth.signIn({
            scope: 'https://www.googleapis.com/auth/youtube',
            prompt: 'select_account',
        });
        console.log({ res });
        return googleAuth.currentUser;
    } catch (e) {
        console.log('error signing in', e);
        return e;
    }
}
const cookieName = btoa('Rodeogid');

export function storeCookie({ userId }: { userId: string }) {
    // document.cookie = `${cookieName}=${btoa(userId)}; max-age=${
    // 60 * 60 * 24 * 365
    // }`;
}

export function clearCookie({ userId }: { userId: string }) {
    // document.cookie = `${cookieName}=${btoa(userId)}; max-age=0`;
}

export function getIdFromCookie() {
    const cookie = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${cookieName}=`));
    if (cookie) {
        return cookie.substr(cookieName.length + 1, cookie.length);
    }

    return null;
}
