// from packages
import { Fragment, FunctionalComponent, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Link } from 'preact-router/match';

// constants, enums, types

// utils
import { buildClasses } from '../../utils';

// components
import RodeoLogo from '../rodeoLogo';
import UserMenu from '../userMenu';
// import Button from '../button';

// assets

//styles
import style from './style.scss';

interface Props {
    googleAuth?: any;
    inRecordingState: boolean;
    onLoggedOut: () => void;
}

const Header: FunctionalComponent<Props> = ({
    googleAuth,
    inRecordingState,
    onLoggedOut,
}: Props) => {
    const [isSignedIn, setSignedIn] = useState<boolean>(false);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const closeMenu = (event: MouseEvent) => {
        const targetEl = event.target as HTMLElement;
        const targetClasses = targetEl.classList.value;
        if (
            menuOpen &&
            !targetClasses.includes(style.avatar) &&
            !targetClasses.includes(style['nav-menu-item-content'])
        ) {
            setMenuOpen(!menuOpen);
        }
    };

    useEffect(() => {
        if (
            document.location.pathname === '/login' ||
            document.location.pathname === '/'
        ) {
            onLoggedOut();
            setSignedIn(false);
        } else {
            if (googleAuth?.currentUser?.get().getBasicProfile) {
                setSignedIn(true);
            }
        }
    }, [document.location.pathname, googleAuth]);

    useEffect(() => {
        if (menuOpen) {
            window.addEventListener('click', closeMenu, true);
        } else {
            window.removeEventListener('click', closeMenu, true);
        }
    }, [menuOpen]);

    async function signOut() {
        try {
            await googleAuth?.signOut();
            setSignedIn(false);
            setMenuOpen(false);
            onLoggedOut();
        } catch (e) {
            console.log('error signing out');
        }
    }

    const headerClasses = buildClasses({
        [style.header]: true,
        [style['signed-in-header']]: isSignedIn,
        [style.recording]: inRecordingState,
    });

    return (
        <header class={headerClasses}>
            <h1>
                <RodeoLogo withLink={true} />
            </h1>
            {isSignedIn && (
                <Fragment>
                    <nav class={style['navigation-menu']}>
                        <ul class={style['navigation-links']}>
                            <li>
                                <Link
                                    class={style.link}
                                    activeClassName={style.active}
                                    href="/home"
                                >
                                    MY MEDIA
                                </Link>
                            </li>
                                <li>
                                    <Link
                                        class={style.link}
                                        activeClassName={style.active}
                                        href="/record"
                                    >
                                        REACT
                                    </Link>
                                </li>
                        </ul>
                    </nav>
                    <UserMenu handleSignOut={signOut} googleAuth={googleAuth} />
                </Fragment>
            )}
        </header>
    );
};

export default Header;
