interface FreezeSample {
    fwd: AudioBuffer;
    rev: AudioBuffer;
}

let volumeAnimationFrame = 0;

export default class AudioEngine {
    audioCtx: AudioContext = new AudioContext();
    audioDest: MediaStreamAudioDestinationNode =
        this.audioCtx.createMediaStreamDestination();
    freezeSampleBuffers: FreezeSample[] = [];
    playedFreezeSample: FreezeSample | null = null;
    localSource: MediaStreamAudioSourceNode | null = null;
    videoSource: MediaStreamAudioSourceNode | null = null;
    screenSource: MediaStreamAudioSourceNode | null = null;
    audioChannels: ChannelMergerNode = new ChannelMergerNode(this.audioCtx, {
        numberOfInputs: 3,
    });
    monitoredStreamId: string = '';
    constructor() {
        // this.loadAudioBuffers();
    }

    public resume() {
        this.audioCtx.resume();
        this.audioChannels.connect(this.audioDest);
    }

    public addLocalSource({ stream }: { stream: MediaStream }) {
        try {
            if (this.localSource) {
                this.localSource.disconnect(this.audioDest);
            }
        } catch {}
        const channel = this.audioCtx.createMediaStreamSource(stream);
        this.localSource = channel;
        this.localSource.connect(this.audioDest);
    }

    public addVideoSource({ stream }: { stream: MediaStream }) {
        this.removeVideoSource();
        const channel = this.audioCtx.createMediaStreamSource(stream);
        this.videoSource = channel;
        this.videoSource.connect(this.audioDest);
    }

    public removeVideoSource() {
        try {
            if (this.videoSource) {
                this.videoSource.disconnect(this.audioDest);
            }
        } catch {}
    }

    public getAudioTracks() {
        return this.audioDest.stream.getAudioTracks()[0];
    }

    private adjustSampleVolume(
        source: AudioBufferSourceNode | MediaStreamAudioSourceNode,
        amnt: number,
    ) {
        const gain = new GainNode(this.audioCtx, { gain: amnt });

        source.connect(gain);
        gain.connect(this.audioCtx.destination);
    }

    public playRandomFreezeSample() {
        const freezeSample =
            this.freezeSampleBuffers[
                Math.floor(
                    Math.random() * (this.freezeSampleBuffers.length - 1),
                )
            ];
        const audioBufferSource = new AudioBufferSourceNode(this.audioCtx, {
            buffer: freezeSample.fwd,
        });
        this.adjustSampleVolume(audioBufferSource, -0.25);
        // audioBufferSource.connect(audioCtx.current.destination);
        audioBufferSource.connect(this.audioDest);
        audioBufferSource.start();
        this.playedFreezeSample = freezeSample;
    }

    public playRandomFreezeSampleInReverse() {
        if (!this.playedFreezeSample) {
            return;
        }
        const audioBufferSource = new AudioBufferSourceNode(this.audioCtx, {
            buffer: this.playedFreezeSample.rev,
        });
        this.adjustSampleVolume(audioBufferSource, -0.25);
        // audioBufferSource.connect(audioCtx.current.destination);
        audioBufferSource.connect(this.audioDest);
        audioBufferSource.start();
        this.playedFreezeSample = null;
    }

    // public async setAudioOutputDevice({ deviceId }: { deviceId: string }) {
    //     await this.audioCtx.setSinkId(deviceId);
    // }

    // private async getFreezeSample(id: string) {
    //     try {
    //         const res = await fetch(
    //             `../../assets/audio/audio-freeze-${id}.wav`
    //         );
    //         const arrayBuffer = await res.arrayBuffer();
    //         const buffer = await this.audioCtx.decodeAudioData(arrayBuffer);
    //         return buffer;
    //     } catch (e) {
    //         console.log('error getting freeze sample', e);
    //     }
    // }

    // private async createBufferSet(id: number) {
    //     try {
    //         const fwd = await this.getFreezeSample(`${id}`);
    //         const rev = await this.getFreezeSample(`${id}-rev`);
    //         if (fwd && rev) {
    //             return {
    //                 fwd,
    //                 rev,
    //             };
    //         }
    //         return null;
    //     } catch (e) {
    //         console.log('error creating buffer set', e);
    //     }
    // }

    // private async loadAudioBuffers() {
    //     try {
    //         const buffers: FreezeSample[] = [];

    //         for (let i = 1; i <= 4; i++) {
    //             let buffer: FreezeSample | null | undefined =
    //                 await this.createBufferSet(i);
    //             if (buffer) {
    //                 buffers.push(buffer);
    //             }
    //         }

    //         this.freezeSampleBuffers = buffers;
    //     } catch (e) {
    //         console.log('error loading the buffers', e);
    //     }
    // }

    // private volumeAudioProcess(event) {
    //     var buf = event.inputBuffer.getChannelData(0);
    //     var bufLength = buf.length;
    //     var sum = 0;
    //     var x;

    //     // Do a root-mean-square on the samples: sum up the squares...
    //     for (var i = 0; i < bufLength; i++) {
    //         x = buf[i];
    //         if (Math.abs(x) >= this.clipLevel) {
    //             this.clipping = true;
    //             this.lastClip = window.performance.now();
    //         }
    //         sum += x * x;
    //     }

    //     // ... then take the square root of the sum.
    //     var rms = Math.sqrt(sum / bufLength);

    //     // Now smooth this out with the averaging factor applied
    //     // to the previous sample - take the max here because we
    //     // want "fast attack, slow release."
    //     this.volume = Math.max(rms, this.volume * this.averaging);
    // }

    // public createAudioMeter(
    //     // audioContext: AudioContext,
    //     clipLevel = 0.98,
    //     averaging = 0.95,
    //     clipLag = 150
    // ) {
    //     var processor = this.audioCtx.createScriptProcessor(512);
    //     processor.onaudioprocess = this.volumeAudioProcess;
    //     processor.clipping = false;
    //     processor.lastClip = 0;
    //     processor.volume = 0;
    //     processor.clipLevel = clipLevel;
    //     processor.averaging = averaging;
    //     processor.clipLag = clipLag;

    //     // this will have no effect, since we don't copy the input to the output,
    //     // but works around a current Chrome bug.
    //     processor.connect(this.audioCtx.destination);

    //     processor.checkClipping = function () {
    //         if (!this.clipping) return false;
    //         if (this.lastClip + this.clipLag < window.performance.now())
    //             this.clipping = false;
    //         return this.clipping;
    //     };

    //     processor.shutdown = function () {
    //         this.disconnect();
    //         this.onaudioprocess = null;
    //     };

    //     return processor;
    // }

    public getMicrophoneVolume({
        stream,
        onUpdate,
    }: {
        stream?: MediaStream;
        onUpdate: (volume: number) => void;
    }) {
        if (stream) {
            const sourceNode = this.audioCtx.createMediaStreamSource(stream);
            const analyserNode = this.audioCtx.createAnalyser();

            sourceNode.connect(analyserNode);

            analyserNode.fftSize = 2048;

            const data = new Uint8Array(analyserNode.frequencyBinCount);

            // @ts-ignore
            function update() {
                analyserNode.getByteFrequencyData(data);
                const volume =
                    data.reduce((acc, val) => acc + val) / data.length;

                onUpdate(volume);

                volumeAnimationFrame = requestAnimationFrame(update);
            }
            if (stream.id !== this.monitoredStreamId) {
                cancelAnimationFrame(volumeAnimationFrame);
                this.monitoredStreamId = stream.id;
            }
            update();
        }
    }
}
