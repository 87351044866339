// from packages
import { FunctionalComponent, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';

// constants, enums, types
// import { ActiveMedia } from '../../types';

// utils

// components
import { loadGiflerScripts } from '../giflerClient';

// assets

//styles
// import style from './style.scss';

interface Props {
    blob: string;
    className: string;
    onItemLoaded: () => void;
}

const AnimatedGif: FunctionalComponent<Props> = (
    { blob, className, onItemLoaded }: Props,
    ref: any,
) => {
    const [isAnimating, setIsAnimating] = useState<boolean>(false);
    useEffect(() => {
        if (blob) {
            setIsAnimating(false);
            if (!window.gifler) {
                loadGiflerScripts(addMediaToCanvas);
            } else {
                addMediaToCanvas();
            }
        }
    }, [blob]);

    useEffect(() => {
        if (isAnimating) {
            onItemLoaded();
        }
    }, [isAnimating]);

    function addMediaToCanvas() {
        if (isAnimating) {
            return;
        }
        window.gifler(blob).get((animator: any) => {
            ref.current.width = animator.width;
            ref.current.height = animator.height;
            animator.animateInCanvas(ref.current);
            setIsAnimating(true);
        });
    }

    return <canvas class={className} ref={ref} width="0" height="0" />;
};

export default forwardRef(AnimatedGif);
