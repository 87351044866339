// from packages
import { FunctionalComponent, h } from 'preact';
import { TargetedEvent } from 'preact/compat';
import { useEffect, useState } from 'preact/hooks';

// constants, enums, types

// utils
import { formatTime } from '../../../../utils';

// components
// import { RecordAppContext } from '../../index';

// assets

//styles
import style from './style.scss';

interface Props {
    isPlaying: boolean;
    onSeekTo: (time: number) => void;
    activeItemDisplay: HTMLVideoElement;
    itemDuration?: number;
}

const Timeline: FunctionalComponent<Props> = (
    { activeItemDisplay, isPlaying, onSeekTo, itemDuration }: Props,
    ref: any,
) => {
    // const { activeItemDisplay } = useContext(RecordAppContext);
    const duration = getDuration();
    const [timeRemaining, setTimeRemaining] = useState<number>(0);
    const currentTime = duration - timeRemaining;

    useEffect(() => {
        if (activeItemDisplay) {
            activeItemDisplay.addEventListener('timeupdate', updateCurrentTime);
        }
        return () => {
            activeItemDisplay?.removeEventListener(
                'timeupdate',
                updateCurrentTime,
            );
        };
    }, [activeItemDisplay]);

    function getDuration() {
        if (
            activeItemDisplay &&
            !isNaN((activeItemDisplay as HTMLVideoElement).duration)
        ) {
            return (activeItemDisplay as HTMLVideoElement).duration;
        }
        if (itemDuration) {
            return itemDuration;
        }

        return 0;
    }

    function updateCurrentTime() {
        const { currentTime } = activeItemDisplay as HTMLVideoElement;
        if (duration && currentTime) {
            setTimeRemaining(Math.max(duration - currentTime, 0));
        }

        //             if (currentTime > 0) {
        //                 setCurrentTime(currentTime);
        //                 if (currentTime === duration) {
        //                     onMediaEnded();
        //                 }
        //             } else {
        //                 setCurrentTime(0);
        //             }
        //         });
    }
    function handleChange(event: TargetedEvent<HTMLInputElement, Event>) {
        event.preventDefault();
        event.stopPropagation();
        const target = event.target as HTMLInputElement;
        if (
            duration &&
            target &&
            activeItemDisplay &&
            'currentTime' in activeItemDisplay
        ) {
            activeItemDisplay.currentTime =
                duration * (parseInt(target.value) / 100);
        }
        return;
    }

    function getProgress() {
        if (!duration || duration === 0) {
            return 0;
        }
        return (currentTime / duration) * 100;
    }

    if (!duration) {
        // return null;
    }

    return (
        <div class={style['timeline-container']}>
            <input
                type="range"
                class={style.timeline}
                value={getProgress()}
                onChange={handleChange}
                step="any"
                disabled={!duration || duration < 1}
            />
            <span
                class={style.progress}
                style={{
                    width: `${duration ? (currentTime / duration) * 100 : 0}%`,
                }}
            ></span>
            <span class={style.time}>
                {formatTime(Math.max(timeRemaining, 0))}
            </span>
        </div>
    );
};

export default Timeline;
