// from packages
import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

// constants, enums, types
import { RECORDING_STATE } from '../../enums';

// utils
import { buildClasses } from '../../../../utils';

// components
// import AddCloudMediaButton from '../addCloudMediaButton';
import AddComputerMediaButton from '../addComputerMediaButton';
import DeleteDropZone from '../deleteDropZone';
import RecordButton from '../recordButton';
import { RecordAppContext } from '../../index';
import RowGroup from '../../../common/rowGroup';

// assets

//styles
import style from './style.scss';

interface Props {
    handleDragOverActionArea: (e: DragEvent) => void;
    handleDragLeaveActionArea: (e: DragEvent) => void;
}

const ActionArea: FunctionalComponent<Props> = ({
    handleDragOverActionArea,
    handleDragLeaveActionArea,
}: Props) => {
    const {
        addingFilesFromComputer,
        isCleanState,
        isDeletingItem,
        playlistItems,
        recordingState,
    } = useContext(RecordAppContext);

    const actionContent = () => {
        if (addingFilesFromComputer) {
            return (
                <div class={style['drop-files-placeholder']}>
                    <img
                        src="../../../../assets/images/drop-files-image.png"
                        height="66"
                    />
                    Drop files here to react
                </div>
            );
        }

        if (isDeletingItem) {
            return <DeleteDropZone />;
        }

        const placeholderClasses = buildClasses({
            [style['drop-media-placeholder']]: true,
            [style['drop-media-placeholder-faded']]:
                !isCleanState && recordingState === RECORDING_STATE.RECORDING,
        });

        if (playlistItems.length === 0) {
            return (
                <div class={style['default-state-actions']}>
                    <div class={placeholderClasses}>
                        Drop media here to react
                    </div>

                    {isCleanState && (
                        <RowGroup fullWidth={true}>
                            <AddComputerMediaButton inline={true} />
                        </RowGroup>
                    )}
                    {!isCleanState && <RecordButton />}
                </div>
            );
        } else {
            return <RecordButton />;
        }
    };

    return (
        <div
            class={style['action-area-wrapper']}
            onDragOver={handleDragOverActionArea}
            onDragLeave={handleDragLeaveActionArea}
        >
            {actionContent()}
        </div>
    );
};

export default ActionArea;
