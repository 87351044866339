import { FunctionalComponent, h } from 'preact';
import style from './style.scss';

interface Props {
    barStyle: 'holo' | 'basic';
    percentComplete: number;
    // TODO: do we need more flexible styling?
}

const ProgressBar: FunctionalComponent<Props> = ({
    barStyle,
    percentComplete,
}: Props) => {
    return (
        <div class={`${style.bar} ${style[barStyle]}`}>
            <span
                class={style.progress}
                style={{
                    width: `${percentComplete > 100 ? 100 : percentComplete}%`,
                }}
            ></span>
        </div>
    );
};

export default ProgressBar;
