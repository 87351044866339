// from packages
import { Fragment, FunctionalComponent, h } from 'preact';
import { forwardRef } from 'preact/compat';
import { useContext, useEffect, useRef, useState } from 'preact/hooks';

// constants, enums, types
import { RECORDING_STATE } from '../../enums';

// utils
import { buildClasses } from '../../../../utils';

// components
import ActionArea from '../actionArea';
import AddContentPanel from '../addContentPanel';
import KeyboardShortcuts from '../keyboardShortcuts';
import ReactOptionsPanel from '../reactOptionsPanel';
import { RecordAppContext } from '../../index';
import Button from '../../../common/button';
import RowGroup from '../../../common/rowGroup';

// assets
import AddContentIcon from '../../../../assets/images/add-content-icon.svg';
import AddContentIconBlack from '../../../../assets/images/add-content-icon-black.svg';
import ShareScreenIcon from '../../../../assets/images/share-screen-icon.svg';
import OptionsIcon from '../../../../assets/images/options-icon.svg';
import OptionsIconBlack from '../../../../assets/images/options-icon-black.svg';

//styles
import style from './style.scss';

interface Props {
	hasPermissions: boolean;
	handleDragOverActionArea: (e: DragEvent) => void;
	handleDragLeaveActionArea: (e: DragEvent) => void;
}

const FooterControls: FunctionalComponent<Props> = (
	{
		handleDragLeaveActionArea,
		handleDragOverActionArea,
		hasPermissions,
	}: Props,
	ref: any,
) => {
	const {
		addingFilesFromComputer,
		handleGetScreen,
		isDeletingItem,
		playlistItems,
		recordingState,
	} = useContext(RecordAppContext);
	const addContentButtonRef = useRef<HTMLButtonElement>(null);
	const optionsButtonRef = useRef<HTMLButtonElement>(null);
	const [addContentOpen, setAddContentOpen] = useState<boolean>(false);
	const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
	const isDefaultState =
		playlistItems.length === 0 &&
		recordingState === RECORDING_STATE.NOT_STARTED;
	const addContentRightPos = addContentButtonRef.current
		? addContentButtonRef.current.offsetLeft +
		  addContentButtonRef.current.getBoundingClientRect().width * 1.5
		: 0;
	const optionsLeftPos = optionsButtonRef.current
		? optionsButtonRef.current.offsetLeft +
		  optionsButtonRef.current.getBoundingClientRect().width * 1.5
		: 0;
	const footerActionButtonDisabled =
		!hasPermissions ||
		isDeletingItem ||
		(playlistItems.length === 0 &&
			recordingState === RECORDING_STATE.RECORDING);

	useEffect(() => {
		if (optionsOpen) {
			window.addEventListener('click', closeOptionsPanel);
		}

		return () => {
			window.removeEventListener('click', closeOptionsPanel);
		};
	}, [optionsOpen]);

	useEffect(() => {
		if (addContentOpen) {
			window.addEventListener('click', closeAddContentPanel);
		}

		return () => {
			window.removeEventListener('click', closeAddContentPanel);
		};
	}, [addContentOpen]);

	useEffect(() => {
		setAddContentOpen(false);
	}, [playlistItems]);

	function closeAddContentPanel(e: MouseEvent) {
		const { target } = e;

		if (
			target &&
			(target as HTMLElement).closest(
				`.${style['add-content-panel-container']}`,
			)
		) {
			return null;
		} else {
			setAddContentOpen(false);
		}
	}

	function toggleAddContentPanel() {
		setAddContentOpen(!addContentOpen);
	}

	function closeOptionsPanel(e: MouseEvent) {
		const { target } = e;

		if (
			target &&
			(target as HTMLElement).closest(
				`.${style['react-options-panel-container']}`,
			)
		) {
			return null;
		} else {
			setOptionsOpen(false);
		}
	}

	function toggleOptionsPanel() {
		setOptionsOpen(!optionsOpen);
	}

	const footerClasses = buildClasses({
		[style['controls-container']]: true,
		[style['default-state']]: isDefaultState,
		[style['adding-files-from-computer']]: addingFilesFromComputer,
		[style.disabled]: recordingState === RECORDING_STATE.DONE,
	});

	return (
		<footer class={footerClasses} ref={ref}>
			<div class={style['footer-left']}>
				{!addingFilesFromComputer && !isDeletingItem && (
					<div class={style['shortcuts-container']}>
						<KeyboardShortcuts />
					</div>
				)}
				{!isDefaultState &&
					!addingFilesFromComputer &&
					!isDeletingItem && (
						<Button
							buttonStyle="icon"
							handleClick={toggleOptionsPanel}
							label="React options"
							icon={
								optionsOpen ? (
									<OptionsIconBlack />
								) : (
									<OptionsIcon />
								)
							}
							iconPosition="top"
							disabled={footerActionButtonDisabled}
							ref={optionsButtonRef}
						/>
					)}
			</div>
			<ActionArea
				handleDragOverActionArea={handleDragOverActionArea}
				handleDragLeaveActionArea={handleDragLeaveActionArea}
			/>
			<RowGroup align={isDefaultState ? 'right' : 'left'} withGap={true}>
				{!addingFilesFromComputer && !isDeletingItem && (
					<Fragment>
						{!isDefaultState && (
							<Button
								buttonStyle="icon"
								handleClick={toggleAddContentPanel}
								label="Add Content"
								icon={
									addContentOpen ? (
										<AddContentIconBlack />
									) : (
										<AddContentIcon />
									)
								}
								iconPosition="top"
								disabled={footerActionButtonDisabled}
								ref={addContentButtonRef}
							/>
						)}
						{isDefaultState && (
							<Button
								label="Share screen"
								handleClick={handleGetScreen}
								icon={<ShareScreenIcon />}
								iconPosition="top"
								buttonStyle="icon"
							/>
						)}
					</Fragment>
				)}
			</RowGroup>
			{optionsOpen && (
				<div
					class={style['react-options-panel-container']}
					style={{ left: `${optionsLeftPos}px` }}
				>
					<ReactOptionsPanel />
				</div>
			)}
			{addContentOpen && (
				<div
					class={style['add-content-panel-container']}
					style={{ right: `${addContentRightPos}px` }}
				>
					<AddContentPanel />
				</div>
			)}
		</footer>
	);
};

export default forwardRef(FooterControls);
