/* 	THIS NEEDS TO BE SERIOUSLY REFACTORED.
	It was copied over from /cloud in favor of development speed.
	Please refactor!
*/

// from packages
import { ref, onValue, get, child, update } from 'firebase/database';
import { FunctionalComponent, h, Fragment, JSX } from 'preact';
import { useEffect, useState, useRef, useMemo } from 'preact/hooks';
import YouTube from 'react-youtube';
import { route } from 'preact-router';

// constants, enums, types

// utils
import { buildClasses, deviceCanUseMouse, getBrowserInfo } from '../../utils';
import { db } from '../../utils/firebase';
import { getScheduledBroadcastsById } from '../../utils/youtube';

// components
import ActionMenu from './components/actionMenu';
import { deleteFile } from './components/delete';
import DeleteConfirmation from './components/deleteConfirmation';
import Dropzone from './components/dropzone';
import Footer from './components/footer';
import { LightboxKind } from './components/lightbox';
import Loading from './components/loading';
import ScheduledBroadcast, {
	ScheduledBroadcastDetails,
} from './components/scheduledBroadcast';
import SmartFolder from './components/smartFolder';
import StreamNow from './components/streamNow';
import { ToastMessage } from './components/toastNotification';
import { uploadFiles, UploadFile } from './components/upload';
import VideoPreview from './components/videoPreview';

// assets

//styles
import style from './style.scss';

interface Props {
	isLoggedIn: boolean;
	userId: string;
	type?: 'video' | 'audio' | 'image';
	id?: string;
	setToastMessage: (msg: ToastMessage | null) => void;
	onLightboxOpen: (contents?: JSX.Element, kind?: LightboxKind) => void;
	lightboxOpen: boolean;
	lightboxKind?: LightboxKind;
}

export interface YouTubeChannelDetails {
	title: string;
	url: string;
	thumbnailUrl: string;
}

interface DeleteStage {
	blobName: string;
	displayName: string;
	fileType: FileType;
	onDeleteSuccess: () => void;
}

let userVideoRef;
const videoObserver = new IntersectionObserver(
	(entries, observer) => {
		entries.forEach(entry => {
			const vid = entry.target.getElementsByTagName('video')[0];
			if (vid) {
				if (entry.isIntersecting) {
					vid.play();
				} else {
					vid.pause();
				}
			}
		});
	},
	{ root: null, threshold: 0.5 },
);

const Home: FunctionalComponent<Props> = ({
	isLoggedIn,
	userId,
	type,
	id,
	setToastMessage,
	onLightboxOpen,
	lightboxOpen,
	lightboxKind,
}: Props) => {
	if (!isLoggedIn) {
		route('/login');
		return null;
	}
	const [actionMenuFocus, setActionMenuFocus] = useState<
		(DBFile & { fileType: FileType }) | null
	>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [videoFilesLoading, setVideoFilesLoading] = useState<boolean>(false);
	const [audioFilesLoading, setAudioFilesLoading] = useState<boolean>(false);
	const [imageFilesLoading, setImageFilesLoading] = useState<boolean>(false);
	const [dragging, setDragging] = useState<boolean>(false);
	const [scheduledBroadcastDetails, setScheduledBroadcastDetails] = useState<
		ScheduledBroadcastDetails | {}
	>({});
	const [youTubeChannelDetails, setYouTubeChannelDetails] =
		useState<YouTubeChannelDetails | null>(null);
	const [files, setFiles] = useState<DBFile[]>([]);
	const [audFiles, setAudFiles] = useState<DBFile[]>([]);
	const [imgFiles, setImgFiles] = useState<DBFile[]>([]);
	const [gifFiles, setGifFiles] = useState<DBFile[]>([]);
	const [deleteStage, setDeleteStage] = useState<DeleteStage | null>(null);
	const [broadcastId, setBroadcastId] = useState<string | null>(null);
	const [tempFiles, setTempFiles] = useState<UploadFile[]>([]);
	const [showDeleteConfirmation, setShowDeleteConfirmation] =
		useState<boolean>(false);
	const [showYouTubePlayer, setShowYouTubePlayer] = useState<boolean>(false);
	const [videoPlaying, setVideoPlaying] = useState<boolean>(false);
	const [videoTarget, setVideoTarget] = useState<any>(null);
	const [showStreamNow, setShowStreamNow] = useState<boolean>(false);
	const [selectedVideo, setSelectedVideo] = useState<DBFile | null>(null);
	const [downloadInProgress, setDownloadInProgress] =
		useState<boolean>(false);
	const [scheduledBroadcasts, setScheduledBroadcasts] = useState<
		YouTubeBroadcast[]
	>([]);

	// const [rendering, setRendering] = useState<boolean>(false);
	// const [activeVideo, setActiveVideo] = useState<DBFile | null>(null);
	const didMount = useRef<boolean>(false);

	const isMobileDevice =
		!deviceCanUseMouse &&
		['iPhone', 'Android'].indexOf(getBrowserInfo().os.name) > -1;

	useEffect(() => {
		if (userId) {
			getDBFiles();
			getRodeoUserInfo();
		}
		window.addEventListener(
			'beforeunload',
			event => {
				if (tempFiles.length > 0) {
					event.preventDefault();
				}
			},
			{ capture: true },
		);
	}, [userId]);

	// useEffect(() => {
	//     if (tempFiles.length > 1) {
	//         tempFiles.forEach(file => {
	//             if (file.uploadTask) {
	//                 file.uploadTask.on(
	//                     'state_changed',
	//                     () => {},
	//                     e => {
	//                         console.log('hey here');
	//                         delete file;
	//                     }
	//                 ),
	//                     () => {};
	//             }
	//         });
	//     }
	// }, [tempFiles]);

	useEffect(() => {
		if (didMount.current) {
			// setLoading(false);
			setTempFiles(
				tempFiles.filter(
					tempFile =>
						![...files, ...audFiles, ...imgFiles, ...gifFiles].find(
							file => file.blobName === tempFile.blobName,
						),
				),
			);
			setLoading(determineLoadingState());
			if (
				audFiles.length === 0 &&
				lightboxOpen &&
				lightboxKind === 'audio folder'
			) {
				onLightboxOpen();
			}
		} else {
			didMount.current = true;
		}
	}, [files, audFiles, imgFiles, gifFiles]);

	useEffect(() => {
		if (selectedVideo) {
			setShowStreamNow(true);
		}
	}, [selectedVideo]);

	function determineLoadingState() {
		let isLoading =
			// @ts-ignore
			audioFilesLoading + videoFilesLoading + imageFilesLoading;

		if (videoFilesLoading && files.length > 0) {
			setVideoFilesLoading(false);
			isLoading--;
		}

		if (audioFilesLoading && audFiles.length > 0) {
			setAudioFilesLoading(false);
			isLoading--;
		}

		if (imageFilesLoading && imgFiles.length > 0) {
			setImageFilesLoading(false);
			isLoading--;
		}

		return isLoading !== 0;
	}

	function getDBFiles() {
		// const userId = '116196458824979689043'; // Ankur
		// const userId = '114102849018461875780'; // Hannah
		// const userId = '109816020577158467057'; // Ryan
		// const userId = '109525750485536112560'; // Jason
		// const userId = '114425139406539034445'; // Tyler
		const filePath = `files/${userId}`;
		userVideoRef = ref(db, filePath);
		onValue(userVideoRef, buildFiles);
	}

	function buildFiles(snapshot: any) {
		// console.log({ snapshot: snapshot.val() });
		const filesJson = snapshot.val();
		if (filesJson) {
			const { video, audio, image } = filesJson;
			// console.log({ audio, video, image });
			if (video) {
				setVideoFilesLoading(true);
				const dbFiles = Object.keys(video).reduce(
					(a: DBFile[], b: string) => {
						if (video[b].thumbnailUrl) {
							a.push(video[b]);
						}
						return a;
					},
					[],
				);
				if (dbFiles.length > 0) {
					setFiles(dbFiles);
				} else {
					setVideoFilesLoading(false);
				}
			} else {
				setFiles([]);
			}

			if (audio) {
				setAudioFilesLoading(true);
				const audFiles = Object.keys(audio).reduce(
					(a: DBFile[], b: string) => {
						if (audio[b].thumbnailUrl && audio[b].feature != 'voicemail') {
							a.push(audio[b]);
						}
						return a;
					},
					[],
				);
				if (audFiles.length > 0) {
					setAudFiles(audFiles);
				} else {
					setAudioFilesLoading(false);
				}
			} else {
				setAudFiles([]);
			}

			if (image) {
				setImageFilesLoading(true);
				const gifFiles: DBFile[] = [];
				const imgFiles = Object.keys(image).reduce(
					(a: DBFile[], b: string) => {
						if (image[b].thumbnailUrl) {
							const parts = image[b].displayName.split('.');
							if (parts[parts.length - 1] === 'gif') {
								gifFiles.push(image[b]);
							} else {
								a.push(image[b]);
							}
						}
						return a;
					},
					[],
				);
				if (imgFiles.length > 0 || gifFiles.length > 0) {
					setImgFiles(imgFiles);
					setGifFiles(gifFiles);
				} else {
					setImageFilesLoading(false);
				}
			} else {
				setImageFilesLoading(false);
				setImgFiles([]);
				setGifFiles([]);
			}
		} else {
			setFiles([]);
			setAudFiles([]);
			setImgFiles([]);
			setGifFiles([]);
		}
	}

	async function loadApi() {
		window.gapi.client.setApiKey(APP_CREDS.api_key);
		return await window.gapi.client.load('youtube', 'v3');
	}

	async function loadYouTubeClient() {
		await loadApi();
		if (!window.gapi.client.youtube) {
			await loadApi();
		}

		try {
			const youtubeChannel =
				await window.gapi.client.youtube.channels.list({
					part: 'snippet,contentDetails',
					mine: true,
				});
			if (youtubeChannel.result?.items) {
				const {
					title,
					thumbnails: {
						default: { url },
					},
				} = youtubeChannel.result.items[0].snippet;
				setYouTubeChannelDetails({ title, thumbnailUrl: url, url: '' });
			}

			// return youtubeClient;
		} catch (e) {
			console.log('error loading the youtube client', e);
		}
	}

	function handleDownloadStarted() {
		setDownloadInProgress(true);
		setToastMessage({ message: 'Download in progress', type: 'info' });
	}

	function handleDownloadCompleted() {
		setDownloadInProgress(false);
		setToastMessage(null);
	}

	function handleDownloadError() {
		setDownloadInProgress(false);
		setToastMessage({
			message: 'Something went wrong. Please try again',
			type: 'error',
		});
	}

	function handleLiveStreamingNotEnabledError() {
		setToastMessage({
			message: 'Please enable live streaming on your YouTube account',
			type: 'error',
		});
	}

	function handleTryAgainError() {
		setToastMessage({
			message: 'Something went wrong. Please try again',
			type: 'error',
		});
	}

	async function loadBroadcasts(id: string) {
		return await getScheduledBroadcastsById(id);
	}

	function clearScheduledBroadcasts() {
		setScheduledBroadcasts([]);
		setScheduledBroadcastDetails({});
		update(child(ref(db), `users/${userId}`), {
			scheduledBroadcastId: null,
			scheduledBroadcastDate: null,
			scheduledBroadcastLiveChatId: null,
		});
	}

	async function getRodeoUserInfo() {
		get(child(ref(db), `users/${userId}`)).then(setRodeoUserInfo);
	}

	async function setRodeoUserInfo(snapshot: any) {
		const info = snapshot.val();
		await loadYouTubeClient();
		if (info?.scheduledBroadcastId) {
			const [scheduledBroadcast] = await loadBroadcasts(
				info.scheduledBroadcastId,
			);
			if (scheduledBroadcast) {
				setScheduledBroadcasts([scheduledBroadcast]);
				setScheduledBroadcastDetails({
					channelThumbnailUrl:
						scheduledBroadcast.snippet.thumbnails.maxres.url,
					date: scheduledBroadcast.snippet.scheduledStartTime,
					description: scheduledBroadcast.snippet.description,
					id: scheduledBroadcast.id,
					privacyStatus: scheduledBroadcast.status.privacyStatus,
					title: scheduledBroadcast.snippet.title,
					contentDetails: scheduledBroadcast.contentDetails,
					username: info.username,
				});
			} else {
				clearScheduledBroadcasts();
			}
		} else {
			clearScheduledBroadcasts();
		}
	}

	function handleSelectVideo(file: DBFile) {
		setSelectedVideo({ ...file });
	}

	function handleScheduledBroadcastAction() {
		setShowStreamNow(true);
	}

	function handleToggleMobileActionMenu(
		file: DBFile,
		fileType: FileType,
		topPos: number,
	) {
		setActionMenuFocus({ ...file, fileType });
		// window.scroll({
		//     top: window.scrollY + topPos - 61, // position of element target minus header height
		//     left: window.scrollX,
		//     behavior: 'smooth',
		// });
	}

	function clearActionMenuFocus() {
		setActionMenuFocus(null);
	}

	function renderFile(file: DBFile) {
		return (
			<li class={style['file-item']}>
				<VideoPreview
					file={file}
					onDeleteConfirm={setUpDeleteConfirm}
					downloadInProgress={downloadInProgress}
					onDownloadStarted={handleDownloadStarted}
					onDownloadCompleted={handleDownloadCompleted}
					onDownloadError={handleDownloadError}
					onSelectVideo={handleSelectVideo}
					observer={videoObserver}
					isMobileDevice={isMobileDevice}
					onToggleMobileAction={handleToggleMobileActionMenu}
					handleUploadError={(error: Error) => {}}
				/>
			</li>
		);
	}

	function handleUploadError(error: any, tempFile: any) {
		switch (error.code) {
			case 'storage/unauthorized':
				setToastMessage({
					message: 'Action is aunauthorized.',
					type: 'error',
				});
				break;
			case 'storage/canceled':
				setToastMessage({
					message: 'Upload canceled',
					type: 'error',
				});
				break;

			case 'storage/unknown':
				setToastMessage({
					message: 'Something went wrong. Please try again',
					type: 'error',
				});
				break;
		}

		setTempFiles(tempFiles.filter(file => file !== tempFile));
	}

	function renderFiles(
		files: DBFile[],
		tempFiles: UploadFile[],
		audFiles: DBFile[],
		imgFiles: DBFile[],
		gifFiles: DBFile[],
	) {
		const sortedFiles = files.sort(
			(a, b) =>
				new Date(b.creationDate).getTime() -
				new Date(a.creationDate).getTime(),
		);

		const sortedImageFiles = imgFiles.sort(
			(a, b) =>
				new Date(b.creationDate).getTime() -
				new Date(a.creationDate).getTime(),
		);

		const sortedGifFiles = gifFiles.sort(
			(a, b) =>
				new Date(b.creationDate).getTime() -
				new Date(a.creationDate).getTime(),
		);

		if (lightboxOpen) {
			switch (lightboxKind) {
				case 'audio folder':
					if (audFiles.length === 0) {
						onLightboxOpen();
					}
					break;
				case 'images folder':
					if (imgFiles.length === 0) {
						onLightboxOpen();
					}
					break;
				case 'gif folder':
				case 'GIF folder':
					if (gifFiles.length === 0) {
						onLightboxOpen();
					}
					break;
			}
		}

		return (
			<ul class={style['file-list']}>
				{tempFiles.map((file: UploadFile) => {
					return (
						<li class={style['file-item']}>
							<VideoPreview
								isTempFile={true}
								isMobileDevice={isMobileDevice}
								tempFile={file}
								handleUploadError={error => {
									handleUploadError(error, file);
								}}
								onToggleMobileAction={
									handleToggleMobileActionMenu
								}
							/>
						</li>
					);
				})}
				{audFiles.length > 0 && (
					<li class={style['file-item']}>
						<SmartFolder
							type="audio"
							files={audFiles}
							onOpenFolder={onLightboxOpen}
							onDeleteConfirm={setUpDeleteConfirm}
							downloadInProgress={downloadInProgress}
							onDownloadStarted={handleDownloadStarted}
							onDownloadCompleted={handleDownloadCompleted}
							onDownloadError={handleDownloadError}
							lightboxOpen={lightboxOpen}
							lightboxKind={lightboxKind}
							isMobileDevice={isMobileDevice}
							onToggleMobileAction={handleToggleMobileActionMenu}
						/>
					</li>
				)}
				{imgFiles.length > 0 && (
					<li class={style['file-item']}>
						<SmartFolder
							type="images"
							files={sortedImageFiles}
							onOpenFolder={onLightboxOpen}
							onDeleteConfirm={setUpDeleteConfirm}
							downloadInProgress={downloadInProgress}
							onDownloadStarted={handleDownloadStarted}
							onDownloadCompleted={handleDownloadCompleted}
							onDownloadError={handleDownloadError}
							lightboxOpen={lightboxOpen}
							lightboxKind={lightboxKind}
							isMobileDevice={isMobileDevice}
							onToggleMobileAction={handleToggleMobileActionMenu}
						/>
					</li>
				)}
				{gifFiles.length > 0 && (
					<li class={style['file-item']}>
						<SmartFolder
							type="GIF"
							files={sortedGifFiles}
							onOpenFolder={onLightboxOpen}
							onDeleteConfirm={setUpDeleteConfirm}
							downloadInProgress={downloadInProgress}
							onDownloadStarted={handleDownloadStarted}
							onDownloadCompleted={handleDownloadCompleted}
							onDownloadError={handleDownloadError}
							lightboxOpen={lightboxOpen}
							lightboxKind={lightboxKind}
							isMobileDevice={isMobileDevice}
							onToggleMobileAction={handleToggleMobileActionMenu}
						/>
					</li>
				)}
				{sortedFiles.map(renderFile)}
			</ul>
		);
	}

	function handleFilesDropped(files: File[]) {
		setTempFiles(uploadFiles(files, userId));
	}

	function handleFilesChosen(event: Event) {
		const target = event.target as HTMLInputElement;
		if (target) {
			const files = target.files;
			files && setTempFiles(uploadFiles(files, userId));
			target.value = '';
		}
	}

	function handleFolderDropped() {
		setToastMessage({
			message: (
				<Fragment>
					This file type is not currently supported.{' '}
					<a href="mailto:rodeo-feedback@google.com">
						Ask us to support it
					</a>
					.
				</Fragment>
			),
			type: 'error',
		});
	}

	function clearYouTubePlayer() {
		setShowYouTubePlayer(false);
		setVideoPlaying(false);
		setBroadcastId(null);
		setVideoTarget(null);
	}

	async function handleSuccessfulPublish(broadcastId: string) {
		try {
			if (
				scheduledBroadcastDetails &&
				broadcastId ===
					(scheduledBroadcastDetails as ScheduledBroadcastDetails).id
			) {
				clearScheduledBroadcasts();
			}
			getRodeoUserInfo();
		} catch (e) {
			console.log('error updating the db', e);
		}
		setBroadcastId(broadcastId);
		setShowYouTubePlayer(true);
	}

	function renderYouTubePlayer() {
		if (!broadcastId) {
			clearYouTubePlayer();
			return null;
		}

		const windowWidth = window.innerWidth;

		const opts = {
			height: windowWidth < 700 ? windowWidth - 20 * 0.61 : '390',
			width: windowWidth < 700 ? windowWidth - 20 : '640',
			playerVars: {
				// https://developers.google.com/youtube/player_parameters
				autoplay: 1,
				controls: 0,
			},
		};

		return (
			<div class={style['youtube-player']}>
				<div class={style['youtube-player-header']}>
					<button
						type="button"
						class={style['close-icon']}
						onClick={clearYouTubePlayer}
					>
						<svg
							width="23"
							height="23"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M21.753 1.247a1.5 1.5 0 0 1 0 2.121L13.621 11.5l8.132 8.132a1.5 1.5 0 1 1-2.121 2.121L11.5 13.621l-8.132 8.132a1.5 1.5 0 1 1-2.121-2.121L9.379 11.5 1.247 3.368a1.5 1.5 0 1 1 2.121-2.121L11.5 9.379l8.132-8.132a1.5 1.5 0 0 1 2.121 0Z"
								fill="#FFF"
								fill-rule="evenodd"
							/>
						</svg>
					</button>
				</div>
				<YouTube
					videoId={broadcastId}
					opts={opts}
					onReady={event => {
						event.target.mute();
					}} // defaults -> noop
					onPlay={event => {
						setVideoPlaying(true);
						// unmuteVideo(event.target);
						setVideoTarget(event.target);
					}} // defaults -> noop
					// onPause={event => {
					//     console.log('Pause', event);
					// }} // defaults -> noop
					onEnd={clearYouTubePlayer} // defaults -> noop
					onError={event => {
						// Most likely error is the video is private,
						//this will tell the user to watch the video on youtube
						setVideoPlaying(true);
						setShowYouTubePlayer(false);
						setBroadcastId(null);
						setVideoTarget(null);
					}} // defaults -> noop
					// onStateChange={event => {
					//     console.log('player StateChange', event);
					// }} // defaults -> noop
					// onPlaybackRateChange={event => {
					//     console.log('PlaybackRateChange', event);
					// }} // defaults -> noop
					// onPlaybackQualityChange={event => {
					//     console.log('PlaybackQualityChange', event);
					// }} // defaults -> noop
				/>
			</div>
		);
	}

	function handleDragStart() {
		setDragging(true);
	}

	function handleDragEnd() {
		setDragging(false);
	}

	function setUpDeleteConfirm(
		blobName: string,
		displayName: string,
		fileType: FileType,
		onDeleteSuccess: () => void,
	) {
		setDeleteStage({ blobName, displayName, fileType, onDeleteSuccess });
		setShowDeleteConfirmation(true);
	}

	function clearDeleteStage() {
		setDeleteStage(null);
		setShowDeleteConfirmation(false);
	}

	function renderDeleteConfirmation() {
		if (!showDeleteConfirmation || !deleteStage) {
			return null;
		}

		return (
			<DeleteConfirmation
				itemType={deleteStage.fileType}
				itemName={deleteStage.displayName}
				onCancel={clearDeleteStage}
				onConfirm={() => {
					deleteFile(
						deleteStage.blobName,
						deleteStage.fileType,
						() => {
							deleteStage.onDeleteSuccess(), clearDeleteStage();
						},
					);
				}}
			/>
		);
	}

	const dashClasses = buildClasses({
		[style.dashboard]: true,
		[style.dragging]: dragging,
		[style.placeholder]: files.length + tempFiles.length === 0,
	});

	if (loading) {
		return <Loading />;
	}

	let content;

	if (
		files.length +
			audFiles.length +
			imgFiles.length +
			gifFiles.length +
			tempFiles.length ===
		0
	) {
		content = (
			<div class={style['placeholder-content']}>
				<img
					src="../../assets/images/upload-files-cloud.png"
					alt="Cloud with audio, video, and image icons behind it"
				/>
				<h1>
					{deviceCanUseMouse ? 'Drag and drop here' : 'Upload files'}
				</h1>
				<p>
					Upload videos, images, or audio to use them with Rodeo on
					any device.
				</p>
				<Footer userId={userId} onFilesChosen={handleFilesChosen} />
			</div>
		);
	} else {
		content = (
			<Fragment>
				{useMemo(
					() =>
						renderFiles(
							files,
							tempFiles,
							audFiles,
							imgFiles,
							gifFiles,
						),
					[files, tempFiles, audFiles],
				)}
				<Footer userId={userId} onFilesChosen={handleFilesChosen} />
			</Fragment>
		);
	}

	return (
		<div class={dashClasses}>
			<Dropzone
				onFilesDropped={handleFilesDropped}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
				onFolderDropped={handleFolderDropped}
			/>
			{content}
			{(scheduledBroadcastDetails as ScheduledBroadcastDetails)?.id &&
				!showYouTubePlayer && (
					<ScheduledBroadcast
						scheduledBroadcastDetails={
							scheduledBroadcastDetails as ScheduledBroadcastDetails
						}
						onAction={handleScheduledBroadcastAction}
					/>
				)}
			{showYouTubePlayer && renderYouTubePlayer()}

			{renderDeleteConfirmation()}
			{showStreamNow && youTubeChannelDetails && (
				<StreamNow
					onClose={() => {
						setSelectedVideo(null);
						setShowStreamNow(false);
						if (showYouTubePlayer) {
							videoTarget.unMute();
						}
					}}
					videos={files}
					scheduledBroadcastDetails={
						scheduledBroadcastDetails as ScheduledBroadcastDetails
					}
					youTubeChannelDetails={youTubeChannelDetails}
					onSuccessfulPublish={handleSuccessfulPublish}
					videoToStream={selectedVideo}
					videoPlaying={videoPlaying}
					scheduledBroadcasts={scheduledBroadcasts}
					showLiveStreamingNotEnabledError={
						handleLiveStreamingNotEnabledError
					}
					showTryAgainError={handleTryAgainError}
					isMobileDevice={isMobileDevice}
				/>
			)}
			{isMobileDevice && actionMenuFocus && (
				<ActionMenu
					displayName={actionMenuFocus.displayName}
					size={actionMenuFocus.size}
					blobName={actionMenuFocus.blobName}
					thumbnailUrl={actionMenuFocus.thumbnailUrl}
					fileType={actionMenuFocus.fileType}
					onClose={clearActionMenuFocus}
					onDeleteConfirm={setUpDeleteConfirm}
					onDownloadStarted={handleDownloadStarted}
					onDownloadCompleted={handleDownloadCompleted}
					onDownloadError={handleDownloadError}
					onSelectVideo={() => {
						const file = files
							.filter(
								file =>
									file.blobName === actionMenuFocus.blobName,
							)
							.pop();
						if (file) {
							handleSelectVideo(file);
						}
						clearActionMenuFocus();
					}}
				/>
			)}
		</div>
	);
};

export default Home;
